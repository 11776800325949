define("apollo/services/ember-data/simple-model-mapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service for mapping properties between models or objects.
   * Handles both Ember objects and plain JavaScript objects.
   */
  var _default = Ember.Service.extend({
    /**
     * Maps properties from source to target using a property map.
     * Handles both Ember objects and plain JavaScript objects.
     *
     * @param {Object} source - Source object or Ember object
     * @param {Object} target - Target object or Ember object
     * @param {Object} propertyMap - Mapping of target properties to source properties
     * @returns {Object} The updated target object
     *
     * @example
     * // Map properties from route to transport
     * this.simpleModelMapper.mapProperties(route, transport, {
     *   'name': 'name',
     *   'description': 'description',
     *   'targetStatus': 'sourceStatus'
     * });
     */
    mapProperties(source, target, propertyMap) {
      if (!source || !target || !propertyMap) {
        return target;
      }

      Object.entries(propertyMap).forEach(([targetProp, sourceProp]) => {
        const value = this.getPropertyValue(source, sourceProp);

        if (value !== undefined) {
          if (typeof target.set === 'function') {
            // Ember object
            target.set(targetProp, value);
          } else {
            // Plain JavaScript object
            target[targetProp] = value;
          }
        }
      });
      return target;
    },

    /**
     * Gets a property value.
     * Handles both Ember objects and plain JavaScript objects.
     * Does not work with nested properties (e.g. step.idx)
     * @param {Object} obj - The object to get property from
     * @param {String} prop - Property name
     * @returns {*} The property value or undefined
     *
     * @example
     * // Get a property step of obj Object
     * const value = this.simpleModelMapper.getPropertyValue(obj, 'step');
     */
    getPropertyValue(obj, prop) {
      if (!obj || !prop) return undefined;
      return obj[prop];
    }

  });

  _exports.default = _default;
});