define("apollo/pods/delivery-setting/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    transportType: belongsTo('transportType', {
      async: true
    }),
    weightThresholdUpper: attr('number'),
    weightThresholdLower: attr('number'),
    weightAlertColorUpper: attr('string'),
    weightAlertColorLower: attr('string'),
    requireAtLeastOnePackaging: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    autoGenerateDeliveryNumber: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requireNetWeight: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requireGrossWeight: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requireFreightCost: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requireStackingFactor: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    showSimplePackagingSection: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    availableStackingFactors: attr(),
    checkCompatibilityOfLoadingCity: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    sendIfsMessageAfterTimeWindowAction: attr('boolean'),
    copyContactDetailsForTransportation: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    validations: {
      requireAtLeastOnePackaging: {
        presence: true
      }
    }
  });

  _exports.default = _default;
});