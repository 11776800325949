define("apollo/pods/port/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    portType: attr('string'),
    country: attr('string'),
    city: attr('string'),
    name: attr('string'),
    code: attr('string'),
    seaPort: Ember.computed('portType', function () {
      return this.get('portType') === 'SEA';
    }),
    airPort: Ember.computed('portType', function () {
      return this.get('portType') === 'AIR';
    }),
    portSearchField: Ember.computed('country.ibanSymbol,city,name', function () {
      return `${this.country} ${this.city} ${this.name}`.toLowerCase();
    })
  });

  _exports.default = _default;
});