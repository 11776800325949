define("apollo/pods/columns-access-command/model", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    transportType: belongsTo('transportType'),
    tableColumn: belongsTo('tableColumn', {
      async: true,
      inverse: null
    }),
    roleGroup: belongsTo('roleGroup'),
    customFieldDefinition: belongsTo('custom-field-definition', {
      async: true,
      inverse: null
    }),
    visibility: attr(),
    isHighlighted: attr('boolean')
  });

  _exports.default = _default;
});