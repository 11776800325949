define("apollo/pods/route/adapter", ["exports", "apollo/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query) {
      if (query.transportTypeId) {
        return `${this.urlPrefix()}/routes/transport-type`;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});