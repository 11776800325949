define("apollo/pods/price-list/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    access: Ember.A(['ROLE_PRICE_LIST_TAB']),
    transportTypeRole: 'ROLE_ACCESS_PRICE_LIST',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    transportTypes: Ember.A(),

    model(params) {
      params.max = localStorage.getItem('MAX_ROWS_IN_PRICE_LIST_TABLE') || 10;
      params.page = params.page || 1;
      const transportTypeMode = params.type;
      console.log(`Wczytujemy zakładkę Cenników dla typu ${transportTypeMode}...`);
      const routes = transportTypeMode === 'UNAVAILABLE' ? [] : this.store.query('route', {
        transportTypeMode,
        max: params.max,
        page: params.page
      });
      const routeAddresses = this.store.findAll('route-address');
      return Ember.RSVP.hash({
        availableTransportTypes: this.transportTypes,
        transportTypeMode,
        routes,
        routeAddresses
      });
    },

    beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }

      this.transportTypes = this.get('sessionAccount.currentUser.transportTypes').filter(tt => tt.allowsPriceList).filter(tt => {
        return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType(this.transportTypeRole, tt);
      });
      const enteredTypeName = transition.params['price-list.index'].type;

      if (enteredTypeName !== 'UNAVAILABLE' && !this.transportTypes.getEach('alias').includes(enteredTypeName)) {
        console.error(`User entered ${enteredTypeName} in params, but doesn't have access to it (or the type doesn't exist).`);
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    setupController: function (controller, model) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.get('controller').setProperties({
        model,
        totalPages: model.routes.get('meta.totalPages')
      });
    },

    activate() {
      this.activateCurrentTab('PRICE_LIST_TAB');
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName === 'price-list.index') {
          return;
        }

        console.log(`transition.targetName: ${transition.targetName}`); // eslint-disable-next-line ember/no-controller-access-in-routes

        this.get('controller').setProperties({
          page: 1,
          totalPages: 0
        });
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});