define("apollo/services/delivery-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    showTransportCreatedToast(delivery) {
      const weight = this.sessionAccount.getSettingValue('MIN_WEIGHT_TO_CREATE_TRANSPORT');

      if (!weight || isNaN(weight) || Number(delivery.get('grossWeight') || 0) < weight) {
        return;
      }

      this.actionProgressToast.showSuccessToast(this.intl.t('delivery.weightAutoCreated', {
        deliveryNumber: delivery.get('deliveryNumber'),
        weight: weight + ' kg'
      }));
    },

    async createNewBasicTransport(transport) {
      const values = transport.get('customFields').map(a => {
        return a.serialize();
      });
      const response = await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.CREATE_BASIC_TRANSPORT, null, {
        body: JSON.stringify({
          values,
          transportTypeId: transport.get('transportType.id'),
          routeTemplate: transport.get('routeTemplate.id')
        })
      });
      const json = await response.json();

      if (response.ok && json.id) {
        this.store.pushPayload(json.transport);
      }
    },

    async handleUpdatedDeliveryCustomFields(delivery) {
      if (!delivery.get('deliveryCustomFieldValues.length')) {
        return;
      }

      const cfCopy = await delivery.get('deliveryCustomFieldValues').slice();
      cfCopy.forEach(cf => {
        if (cf !== undefined && !cf.get('id')) {
          console.debug(`Unloading redundant delivery cf ${delivery.get('id')}..`);
          delivery.get('deliveryCustomFieldValues').removeObject(cf);
          cf.unloadRecord();
        } else {
          cf.reload();
        }
      });
    },

    handleUpdatedDeliveryPackaging(delivery) {
      if (!delivery.get('packaging.length')) {
        return;
      }

      delivery.get('packaging').then(pck => {
        const cpCopy = pck.slice(0);
        cpCopy.forEach(cp => {
          if (cp !== undefined && !cp.get('id')) {
            console.debug(`Unloading redundant delivery packaging for delivery ${delivery.get('id')}..`);
            delivery.get('packaging').removeObject(cp);
            cp.unloadRecord();
          } else {
            cp.reload();
          }
        });
      });
    },

    autoGenerateDeliveryNumber: async function (delivery) {
      if (await delivery.get('deliverySetting.autoGenerateDeliveryNumber')) {
        let randomDeliveryNumber;
        const response = await this.get('apolloApiService').callApolloApi(this.get('apolloApiService').APOLLO_API.PLANNING.GENERATE_RANDOM_DELIVERY_NUMBER, null, null);

        if (response.ok) {
          const json = await response.json();
          randomDeliveryNumber = json.deliveryNumber;
        } else {
          randomDeliveryNumber = window.crypto.randomUUID();
        }

        if (!randomDeliveryNumber) {
          randomDeliveryNumber = '0000000-' + (Math.random() + 1).toString(36).substring(9) + '-' + (Math.random() + 1).toString(36).substring(9) + '-' + (Math.random() + 1).toString(36).substring(9) + '-' + delivery.get('transportType.id');
        }

        delivery.set('deliveryNumber', randomDeliveryNumber);
      }
    },
    createSimplePackaging: function (delivery) {
      if (delivery.get('id') || !delivery.get('deliverySetting.showSimplePackagingSection')) {
        return;
      }

      this.get('store').peekAll('packageTypeTransportType').filter(i => i.get('transportType.id') === delivery.get('transportType.id')).forEach(type => {
        const deliveryPackaging = this.get('store').createRecord('deliveryPackaging', {
          loadStep: this.get('delivery.loadStep'),
          unloadStep: this.get('delivery.unloadStep'),
          packageType: type.get('packageType'),
          enabled: true,
          required: type.get('required'),
          quantity: 0
        });
        delivery.get('packaging').addObject(deliveryPackaging);
      });
    },
    findCustomFieldPairs: function (definitions) {
      const pairs = [];

      for (let i = 0; i < definitions.get('length'); i++) {
        const definition = definitions.objectAt(i);
        const value = this.store.createRecord('deliveryCustomFieldValue', {
          definition
        });
        pairs[i] = {
          value,
          definition
        };
      }

      return pairs;
    },

    handleDeliveryCustomFields(delivery, customFieldsPairs) {
      const deliveryCustomFields = delivery.get('deliveryCustomFieldValues');
      customFieldsPairs.forEach(pair => {
        let deliveryCf = deliveryCustomFields.filterBy('definition.id', pair.definition.get('id')).get('firstObject');

        if (!deliveryCf) {
          deliveryCf = this.store.createRecord('delivery-custom-field-value', {
            definition: pair.definition,
            delivery
          });
          deliveryCustomFields.pushObject(deliveryCf);
        }

        if (pair.definition.isSelectType) {
          deliveryCf.set('option', pair.value.get('option'));
        } else {
          deliveryCf.set('value', pair.value.value);
        }
      });
    },

    reloadDeliveries: async function (transport) {
      return transport.get('deliveries').then(deliveries => {
        deliveries.forEach(d => {
          d.get('packaging').then(packaging => {
            packaging.forEach(p => {
              p.reload();
            });
          });
        });
      });
    }
  });

  _exports.default = _default;
});