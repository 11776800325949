define("apollo/pods/transport-packaging/model", ["exports", "ember-data", "ember-data-copyable", "apollo/pods/ldm-packaging/model"], function (_exports, _emberData, _emberDataCopyable, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo
  } = _emberData.default;

  var _default = _model.LdmPackaging.extend(_emberDataCopyable.default, {
    transport: belongsTo('transport'),
    validations: {
      packageType: {
        custom: [{
          validation: function (key, value) {
            return value.get('id');
          },
          message: 'blankSelect'
        }]
      }
    }
  });

  _exports.default = _default;
});