define("apollo/services/websocket", ["exports", "apollo/config/environment", "apollo/services/websocket-api"], function (_exports, _environment, _websocketApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WEBSOCKET_STATES = {
    DISCONNECTED: 'DISCONNECTED',
    CONNECTED: 'CONNECTED',
    FAILED: 'FAILED'
  };

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    webstompConnection: null,
    websocketApi: null,
    connectionState: WEBSOCKET_STATES.DISCONNECTED,
    isWebSocketsEnabled: Ember.computed('sessionAccount.settings.USE_WEBSOCKETS', function () {
      return this.sessionAccount.getSettingValue('USE_WEBSOCKETS') === 'true';
    }),

    /**
     * Should be called as soon as user logs in
     * @param user
     * @returns {null}
     */
    async initializeSocketFor(user) {
      if (!this._validateConnectionPrerequisites(user)) {
        console.debug('Websocket connection could not be established!');
        return;
      }

      const webstompClient = this._createWebstompClient();

      const [ok, err] = await this._establishConnection(webstompClient, user);

      if (ok) {
        console.log('Websocket success');
        this.set('webstompConnection', webstompClient);
      }

      if (err) {
        console.log('Websocket error!', err);
      }
    },

    /**
     * @returns {WebsocketApi|null}
     */
    getSocketApi() {
      if (this._shouldReturnExistingSocket()) {
        return this.websocketApi;
      }

      const webstompClient = this.get('webstompConnection');

      if (!webstompClient) {
        return null;
      }

      if (this.websocketApi) {
        this.websocketApi.set('client', webstompClient);
        return this.websocketApi;
      }

      if (webstompClient.connected) {
        return this._createNewSocketApi(webstompClient);
      } else {
        return null;
      }
    },

    _createNewSocketApi(webstompClient) {
      const websocketApi = _websocketApi.default.create({
        client: webstompClient
      });

      this.set('websocketApi', websocketApi);
      return websocketApi;
    },

    _createWebstompClient() {
      const sockInstance = this._createSockJSInstance();

      return webstomp.over(sockInstance, {
        debug: _environment.default.stompDebug,
        protocols: webstomp.VERSIONS.supportedProtocols(),
        heartbeat: false
      });
    },

    _shouldReturnExistingSocket() {
      return this.websocketApi && this.connectionState === WEBSOCKET_STATES.CONNECTED;
    },

    _validateConnectionPrerequisites(currentUser) {
      if (!currentUser) {
        console.error('Websocket connection failed, user is not logged in');
        return false;
      }

      if (!this.isWebSocketsEnabled) {
        console.error('Websocket connection failed, websockets are disabled');
        return false;
      }

      return true;
    },

    _createSockJSInstance() {
      return new SockJS(_environment.default.stompURL, null, {
        transports: ['websocket'] // W naszej wersji Springa(5.2.21) jest bug, który uniemożliwia korzystanie z transportu typu `xhr-streaming`
        // oraz `xhr-polling` dla SockJS. Omijamy tego bug'a konfigurując SockJS w taki sposób, aby korzystał wyłącznie
        // z protokołu websocket.
        // https://github.com/sockjs/sockjs-client/issues/308
        // https://github.com/spring-projects/spring-framework/commit/f004bb1b64cd9f39d58adc181607a1d5f3034b29

      });
    },

    _establishConnection(webstompInstance, user) {
      const credentials = {
        username: user.username,
        token: user.sessionAccount.token
      };
      return new Promise((resolve, reject) => {
        webstompInstance.connect(credentials, () => {
          this._handleSuccessfulConnection();

          resolve([true, null]);
        }, err => {
          console.error('Websocket connection could not be established', err);
          this.set('connectionState', WEBSOCKET_STATES.FAILED);
          resolve([false, err]);
        });
      });
    },

    _handleSuccessfulConnection() {
      this.setProperties({
        connectionState: WEBSOCKET_STATES.CONNECTED
      });
    }

  });

  _exports.default = _default;
});