define("apollo/pods/truck-type-meta/model", ["exports", "ember-data/model", "ember-data", "apollo/mixins/model-validator"], function (_exports, _model, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    validationService: Ember.inject.service('validation-service'),
    intl: Ember.inject.service(),
    maximumLoadCapacity: attr('number'),
    internalWidth: attr('number'),
    internalHeight: attr('number'),
    internalLength: attr('number'),
    timeWindowSizeIncrease: attr('number'),
    validations: {
      timeWindowSizeIncrease: {
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            return value % 15 === 0;
          },
          message: function (key, value, model) {
            return model.get('intl').t('errors.divisable15');
          }
        }, {
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            return model.get('validationService').isPositiveInteger(value);
          },
          message: 'positiveInteger'
        }]
      }
    }
  });

  _exports.default = _default;
});