define("apollo/services/address-unload-time-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    DEFAULT_ARRIVAL_TIME_ARRAY: Ember.A(['18', '00']),

    _defaultArrivalDate(transportType) {
      const numberOfDaysForFirstStep = transportType.get('numberOfDaysAddedToFirstStep') || 0;
      const defaultArrivalDateHour = transportType.get('defaultArrivalDateHour') || '18:00'; // Split the time string into hours and minutes

      const [hours, minutes] = (defaultArrivalDateHour || '').split(':').map(Number);
      return moment().startOf('day').add(numberOfDaysForFirstStep, 'day').add(hours || 18, 'hours').add(minutes || 0, 'minutes').toDate();
    },

    async findArrivalDate(address, transportType) {
      const addressUnloadingTime = await address.get('addressUnloadingTime');

      if (addressUnloadingTime == null || !addressUnloadingTime.get('id')) {
        return this._defaultArrivalDate(transportType);
      }

      const newArrivalDate = moment().startOf('day').add(addressUnloadingTime.get('daysToUnload') || 0, 'day');
      const arrivalDayName = addressUnloadingTime.get('dayName')[newArrivalDate.day()];
      const arrivalDayHourAndMinute = addressUnloadingTime.get(`${arrivalDayName}`) ? addressUnloadingTime.get(`${arrivalDayName}`).split(':') : this.DEFAULT_ARRIVAL_TIME_ARRAY;
      const arrivalDayHour = arrivalDayHourAndMinute[0];
      const arrivalDayMinute = arrivalDayHourAndMinute[1];
      newArrivalDate.add(arrivalDayHour, 'hours').add(arrivalDayMinute, 'minutes');
      return newArrivalDate.toDate();
    }

  });

  _exports.default = _default;
});