define("apollo/pods/transports/index/controller", ["exports", "apollo/mixins/transports-mixin"], function (_exports, _transportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    relationsTrackerService: Ember.inject.service('transport-relations.relations-tracker'),
    relationsCombiner: Ember.inject.service('transport-relations.relations-combiner'),
    queryParams: Ember.A(['query']),
    canCombineTransports: Ember.computed('canAddCombiningTransportsRelation', 'canAddHasToWaitForRelation', function () {
      return this.get('canAddCombiningTransportsRelation') || this.get('canAddHasToWaitForRelation');
    }),
    canAddCombiningTransportsRelation: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION');
    }),
    canAddHasToWaitForRelation: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION');
    }),
    searchParams: Ember.computed('model', 'query', 'filterMode', 'daysLimitEnabled', 'daysLimitEnabledFromButton', function () {
      const query = this.get('query') || '';
      const filterMode = this.get('filterMode') || 'user';
      const queryOpts = this.get('queryOpts');
      const params = {
        query,
        queryOpts,
        filters: Ember.A(),
        daysLimitEnabled: this._getDaysLimitEnabled()
      };

      if (this.get('sessionAccount').isGroupFilterEnabled()) {
        params.filterMode = filterMode;
        return params;
      }

      return params;
    }),
    userAndGroupFilterEnabled: Ember.computed('', function () {
      return this.get('sessionAccount').isGroupFilterEnabled();
    }),
    availableTransportTypes: Ember.computed('model.availableTransportTypes', function () {
      return this.get('model.availableTransportTypes').sortBy('idx');
    }).readOnly(),

    /**
     *  - ALL - jeśli trzeba wyświetlić wszystkie typy,
     *  - alias typu (TRANSPORT_ORDER, CONTAINER, itp) - w przypadku wyświetlania wybranego typu.
     */
    transportTypeMode: Ember.computed('model.transportTypeMode', function () {
      return this.get('model.transportTypeMode');
    }).readOnly(),
    transportTypesToRender: Ember.computed('availableTransportTypes', 'query', function () {
      if (this.get('transportTypeMode') === 'ALL' || this.get('query')) {
        return this.get('availableTransportTypes');
      }

      return this.get('availableTransportTypes').filterBy('alias', this.get('transportTypeMode'));
    }).readOnly(),
    transportTypesReadyToLoad: Ember.computed('transportTypesToRender.@each.loadingCompleted', function () {
      if (!this.get('transportTypesToRender').filterBy('loadingCompleted').get('length')) {
        return [this.get('transportTypesToRender').get('firstObject')];
      }

      const loadedTypes = this.get('transportTypesToRender').filterBy('loadingCompleted');
      const firstUnloadedType = this.get('transportTypesToRender').filterBy('loadingCompleted', false).get('firstObject');

      if (firstUnloadedType) {
        return [...loadedTypes, firstUnloadedType];
      }

      return loadedTypes;
    }),
    // eslint-disable-next-line ember/no-observers
    notifyAboutTransportTypeModeSelection: Ember.observer('model.transportTypeMode', function () {
      this.send('setTransportTypeModeSelection', this.get('model.transportTypeMode'));
    }),
    hasFiltersEnabled: Ember.computed('daysLimitSettingEnabled', 'userAndGroupFilterEnabled', function () {
      return this.get('daysLimitSettingEnabled') || this.get('userAndGroupFilterEnabled');
    }),
    _getDaysLimitEnabled: function () {
      if (this.get('daysLimitEnabledFromButton') === false) {
        return false;
      }

      const tt = this.get('model.availableTransportTypes').find(tt => {
        return tt.get('alias') === this.get('model.transportTypeMode');
      });

      if (!tt) {
        return this.get('daysLimitEnabled');
      } else {
        return tt.get('enableTransportTableDaysLimit');
      }
    },
    actions: {
      setTransportTypeMode(mode) {
        this.transitionToRoute('transports.index', mode);
      },

      setQueryParams(query, queryOpts) {
        this.setProperties({
          query,
          queryOpts
        });
      },

      setDaysLimitEnabled(daysLimitEnabled) {
        this.setProperties({
          daysLimitEnabledFromButton: daysLimitEnabled
        });
      },

      clearQueryParams() {
        this.setProperties({
          query: undefined,
          queryOpts: undefined
        });
        this.get('availableTransportTypes').forEach(tt => tt.set('transportsTabVisited', false));
      },

      // TODO [A-12940] Refaktor!!
      openRelationsModal() {
        const transports = this.get('relationsTrackerService.currentTransports');
        const allRelations = this.get('availableRelations');
        const firstTransport = this.get('relationsTrackerService.currentTransports.firstObject');
        const firstTransportType = firstTransport.get('transportType');
        const firstTypeTransports = transports.filter(transport => {
          return transport.get('transportType.id') === firstTransportType.get('id');
        });
        const secondTypeTransports = transports.filter(transport => {
          return transport.get('transportType.id') !== firstTransportType.get('id');
        });
        const currentPossibleRelations = Ember.A([]);
        const chosenTransportsAreTheSameTransportTypes = !secondTypeTransports || secondTypeTransports.length < 1; // Ustalamy dostepne relacje

        if (chosenTransportsAreTheSameTransportTypes) {
          const allTransports = firstTypeTransports.concat(secondTypeTransports);
          allRelations.forEach(relation => {
            if (!(relation.get('primaryTransportType.id') === firstTransportType.get('id') && relation.get('subordinateTransportType.id') === firstTransportType.get('id'))) {
              // Wszystkie transporty mają ten sam typ, wiec relacje musza miec te same typy w obu parametrach
              return;
            } // TODO walidacja na istnienie super typu transportu


            if (relation.get('alias') === 'TRANSPORT_COMBINING') {
              if (relation.get('requiresTheSameWarehouseInTheSameStepType')) {
                const loadWhId = allTransports.get('firstObject.loadWarehouses.firstObject.id');
                const unloadWhId = allTransports.get('firstObject.unloadWarehouses.firstObject.id');
                const isTheSameWarehouseInTheSameStepType = allTransports.every(transport => {
                  const loadWhs = transport.get('loadWarehouses');
                  const unloadWhs = transport.get('unloadWarehouses');
                  return loadWhs.some(lwh => lwh.get('id') === loadWhId) || unloadWhs.some(uwh => uwh.get('id') === unloadWhId);
                });

                if (!isTheSameWarehouseInTheSameStepType) {
                  return;
                }
              }
            }

            const possibleRelation = {
              masters: [allTransports.shift()],
              slaves: allTransports,
              type: relation.get('alias'),
              relation
            };
            currentPossibleRelations.pushObject(possibleRelation);
          });
        } else {
          // Różne typy transportów, mamy je podzielone na 2 grupy i sprawdzamy, czy są dostępne jakieś relacje w każdej konfiguracji
          allRelations.forEach(relation => {
            let masters = Ember.A([]);
            let slaves = Ember.A([]);

            if (firstTypeTransports.every(t => {
              return relation.get('primaryTransportType.id') === t.get('transportType.id');
            }) && secondTypeTransports.every(t => {
              return relation.get('subordinateTransportType.id') === t.get('transportType.id');
            })) {
              masters = firstTypeTransports;
              slaves = secondTypeTransports;
            } else if (secondTypeTransports.every(t => {
              return relation.get('primaryTransportType.id') === t.get('transportType.id');
            }) && firstTypeTransports.every(t => {
              return relation.get('subordinateTransportType.id') === t.get('transportType.id');
            })) {
              slaves = firstTypeTransports;
              masters = secondTypeTransports;
            } else {
              return;
            }

            if (relation.get('alias') === 'TRANSPORT_COMBINING') {
              if (relation.get('requiresTheSameWarehouseInTheSameStepType')) {
                if (!masters.every(master => {
                  const masterLoadWhIds = master.get('loadWarehouses').map(wh => wh.get('id'));
                  const masterUnloadWhIds = master.get('unloadWarehouses').map(wh => wh.get('id'));
                  return slaves.every(slave => {
                    const slaveLoadWhIds = slave.get('loadWarehouses').map(wh => wh.get('id'));
                    const slaveUnloadWhIds = slave.get('unloadWarehouses').map(wh => wh.get('id'));
                    return slaveLoadWhIds.some(swh => masterLoadWhIds.includes(swh)) || slaveUnloadWhIds.some(swh => masterUnloadWhIds.includes(swh));
                  });
                })) {
                  return;
                }
              }
            }

            if (relation.get('alias') === 'HAS_TO_WAIT_FOR') {
              if (relation.get('requiresTheSameWarehouseInDifferentStepType')) {
                if (!masters.every(master => {
                  const masterLoadWhIds = master.get('loadWarehouses').map(wh => wh.get('id'));
                  const masterUnloadWhIds = master.get('unloadWarehouses').map(wh => wh.get('id'));
                  return slaves.every(slave => {
                    const slaveLoadWhIds = slave.get('loadWarehouses').map(wh => wh.get('id'));
                    const slaveUnloadWhIds = slave.get('unloadWarehouses').map(wh => wh.get('id'));
                    return slaveLoadWhIds.some(swh => masterUnloadWhIds.includes(swh)) || slaveUnloadWhIds.some(swh => masterLoadWhIds.includes(swh));
                  });
                })) {
                  return;
                }
              }
            }

            const possibleRelation = {
              masters,
              slaves,
              type: relation.get('alias'),
              relation
            };
            currentPossibleRelations.pushObject(possibleRelation);
          });
        }

        if (!currentPossibleRelations) {
          return;
        }

        if (currentPossibleRelations.length === 1 && this.get('sessionAccount').getSettingValue('SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION') !== 'true') {
          console.debug('Jest tylko jedna możliwość łączenia oraz ' + 'SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION jest false, ' + 'nie otwieramy modalu łączenia transportów');
          this.get('relationsCombiner').combine(currentPossibleRelations.get('firstObject'));
          return;
        }

        const model = {
          currentPossibleRelations
        };
        console.debug('Otwieramy modal łączenia transportów');
        this.send('showModal', 'transports.modals.transport-relations', model);
      }

    }
  });

  _exports.default = _default;
});