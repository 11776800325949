define("apollo/pods/components/trucks/truck-type-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedTruck: Ember.computed('model.truckType', '_truckTypes.{length,@each.id}', function () {
      if (this.get('_truckTypes.length') === 0) {
        console.debug('Brak dostępnych typów pojazdów dla tego zlecenia');
        return null;
      }

      if (!this.get('_truckTypes').getEach('id').includes(this.get('model.truckType.id'))) {
        console.debug('Typ pojazdu :: ' + this.get('model.truckType.id') + ' :: nie występuje w liście dostępnych pojazdów :: ' + this.get('_truckTypes').getEach('id'));
        return null;
      }

      return this.get('model.truckType');
    }),
    showWarning: Ember.computed('model.showLdmWarning', function () {
      return this.get('model.showLdmWarning');
    }),
    _truckTypes: Ember.computed('model.truckTypes', function () {
      return this.get('truckTypes') ? this.get('truckTypes') : this.get('model.truckTypes');
    }),
    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    })
  });

  _exports.default = _default;
});