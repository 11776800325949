define("apollo/pods/components/recurring-transport-form/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    async init() {
      this._super(...arguments); // Tymczasowym fix do [A-14679], sprawia że opcja `Co Tydzień` będzie automatycznie wybrana
      // podczas tworzenia transportów seryjnych


      const model = await this.get('model');

      if (this.get('type') === 'serialTransport') {
        model.set('frequency', 'WEEKLY');
      }
    },

    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    frequencyOptions: ['DAILY', 'WEEKLY', 'MONTHLY'],
    // Zgodnie z [A-14679] zlecenia seryjne na razie mają mieć tylko opcję 'Co Tydzień'
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    serialFrequencyOptions: ['WEEKLY'],
    getFrequencyOptions: Ember.computed('isSerialTransport', function () {
      return this.get('isSerialTransport') ? this.get('serialFrequencyOptions') : this.get('frequencyOptions');
    }),
    // eslint-disable-next-line ember/no-on-calls-in-components
    setCheckedDays: Ember.on('init', function () {
      if (!this.get('transport.id')) {
        return;
      }

      const currentDaysString = this.get('model.dayNumbers') ? this.get('model.dayNumbers') : '';
      currentDaysString.split(',').filter(idx => idx).forEach(idx => Ember.set(this.findDay(idx), 'checked', true));
    }),
    daysOfWeek: Ember.computed('firstStepDate', function () {
      const days = [];
      const checkedDay = this.get('isSerialTransport') ? moment(this.get('firstStepDate')).day() : null;

      for (let i = 1; i <= 7; i++) {
        days.push({
          idx: i,
          checked: i === checkedDay,
          disabled: i === checkedDay || this.get('editionDisabled')
        });
      }

      if (checkedDay) {
        this.clearCheckedDays();
        this.updateDayNumbers(checkedDay, true);
      }

      return days;
    }),
    firstStepDate: Ember.computed('transport.firstStep.startDate', function () {
      return this.get('transport.firstStep.startDate');
    }),
    fromDate: Ember.computed('isSerialTransport', 'model.startDate', 'firstStepDate', function () {
      if (this.get('isSerialTransport')) {
        return this.get('firstStepDate');
      }

      return this.get('model.startDate') || null;
    }),
    isSerialTransport: Ember.computed('type', function () {
      return this.get('type') === 'serialTransport';
    }),
    fromDisabled: Ember.computed('editionDisabled', function () {
      return this.get('editionDisabled');
    }),
    editionDisabled: Ember.computed('transport.id', function () {
      return this.get('transport.id');
    }),

    clearCheckedDays() {
      this.set('model.dayNumbers', '');
    },

    updateDayNumbers(dayIdx, newChecked) {
      const currentDaysString = this.get('model.dayNumbers') ? this.get('model.dayNumbers') : '';
      const currentDays = new Set(currentDaysString.split(',').filter(x => x));

      if (newChecked) {
        currentDays.add(`${dayIdx}`);
      } else {
        currentDays.delete(`${dayIdx}`);
      }

      this.set('model.dayNumbers', [...currentDays].sort().join(','));
    },

    findDay: function (idx) {
      return this.get('daysOfWeek').find(d => Number(d.idx) === Number(idx));
    },
    actions: {
      checkDay(idx) {
        const day = this.findDay(idx);
        const newChecked = !day.checked;
        Ember.set(day, 'checked', newChecked);
        this.updateDayNumbers(day.idx, newChecked);
      },

      setDate(propertyName, date) {
        if (propertyName === 'transport.serialTransport.startDate') {
          // A-14722, zrefaktoruje to przy okazji refaktoru dat
          const inputDate = date[0];
          const startDate = this.get('fromDate'); // Calculate the initial days difference between startDate and inputDate
          // Create date objects with time component removed to ignore hours/minutes/seconds

          const normalizedInputDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
          const normalizedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
          const daysDifference = Math.floor((normalizedInputDate - normalizedStartDate) / (24 * 60 * 60 * 1000)); // Update each subsequent step with daysDifference

          this.get('transport.sortedSteps').forEach(step => {
            const newArrivalDate = new Date(step.get('arrivalDate')).setDate(step.get('arrivalDate').getDate() + daysDifference);
            step.set('arrivalDate', new Date(newArrivalDate));
          });
        }

        this.set(propertyName, date[0]);
      }

    }
  });

  _exports.default = _default;
});