define("apollo/pods/planning/index/route", ["exports", "apollo/mixins/menu-mixin"], function (_exports, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    websocketService: Ember.inject.service('websocket'),
    planningPollingService: Ember.inject.service('polling.planning-service'),
    access: Ember.A(['ROLE_PLANNING_TAB']),
    transports: Ember.A([]),
    model: async function (params) {
      const transportTypeMode = params.type;
      const availableTransportTypes = await this.get('store').peekAll('transportType').filter(transportType => {
        return transportType.canBeSeenByRoleGroup && transportType.usedForDeliveries;
      });
      const transportType = availableTransportTypes.find(tt => tt.alias === transportTypeMode);
      const transportTypeId = transportType.get('id');

      if (!transportTypeId) {
        console.error('Brak typu transportu z flagą usedForDeliveries');
        this.transitionTo('forbidden');
      }

      const transports = await this.store.query('transport', {
        planning: true
      });
      const deliveries = await this.store.query('delivery', {
        transportTypeId
      });
      const packageTypes = await this.get('store').peekAll('packageType');
      return Ember.RSVP.hash({
        availableTransportTypes,
        transportType,
        deliveries,
        transports,
        customFields: this.store.query('customFieldDefinition', {
          planning: true,
          transportTypeId
        }),
        packageTypes
      });
    },
    beforeModel: function (transition) {
      const allowWs = this.get('sessionAccount').getSettingValue('USE_WEBSOCKETS') === 'true';
      const access = this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'));

      if (!allowWs || !access) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    afterModel: function (model) {
      const socketApi = this.websocketService.getSocketApi();

      if (socketApi) {
        this.planningPollingService.useSockets(socketApi, model);
      }
    },

    activate() {
      this.activateCurrentTab('PLANNING_TAB');
    },

    deactivate() {
      const socketApi = this.websocketService.getSocketApi();

      if (socketApi) {
        socketApi.unsubscribeAny('topic/planning/update');
      }
    },

    refreshModel() {
      this.refresh();
    },

    actions: {
      setTransportTypeMode(mode) {
        console.log(`Planowanie: Przechodzimy do typu ${mode}...`);
        this.transitionToRoute('planning.index', mode);
      }

    }
  });

  _exports.default = _default;
});