define("apollo/services/polling/planning-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    websocket: Ember.inject.service('websocket'),

    useSockets(socketApi, model) {
      const transportTypeId = model.transportType.id;
      socketApi.subscribe('/topic/planning/update', () => {
        this.store.query('transport', {
          planning: true
        }).then(ts => {
          Ember.set(model, 'transports', ts);
        });
        this.store.query('delivery', {
          transportTypeId
        }).then(ds => {
          Ember.set(model, 'deliveries', ds);
        });
      });
    }

  });

  _exports.default = _default;
});