define("apollo/mixins/transports-mixin", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    dateUtils: Ember.inject.service('date-utils'),
    timeWindowService: Ember.inject.service('time-window-service'),
    checkpointService: Ember.inject.service(),
    weightService: Ember.inject.service('weight-service'),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING: Ember.A(['WINDOWS_OVERLAP_EXCEPTION']),
    freeSubsequentSpots: Ember.A(),
    interval: Ember.computed('', function () {
      return 10000; // Time between polls (in ms)
    }).readOnly(),
    openCreateAdviceModal: function (transport) {
      return this.send('openCreateAdviceModal', transport);
    },
    actions: {
      searchForFreeSubsequentSpots(transport) {
        const self = this;
        const warehouseId = transport.get('exceptionInformation.whId') || transport.get('warehouse.id');
        self.set('freeSubsequentSpots', Ember.A());
        const data = {
          windowStart: transport.get('exceptionInformation.initialStart'),
          windowSizeInMinutes: transport.get('exceptionInformation.windowSizeInMinutes'),
          warehouseId,
          chosenRampName: transport.get('exceptionInformation.rampName')
        };
        self.get('sessionAccount.currentUser.accessibleRamps').filterBy('warehouse.id', warehouseId).forEach(ramp => {
          self.get('ajaxService').send(`/ramps/${ramp.get('id')}/findFreeSubsequentSpot`, 'POST', data, true).then(response => {
            if (response.timeWindowCandidate) {
              self.get('freeSubsequentSpots').pushObject(response.timeWindowCandidate);
            }
          }).catch(response => {
            (0, _jquery.default)('.transport-modal').scrollTop(0);
            self.set('errors', response.responseJSON.errors);
            (0, _jquery.default)('.show-search-spots-btn').button('reset');
          });
        });
        (0, _jquery.default)('.show-search-spots-btn').button('reset');
      },

      setFilterMode(mode) {
        this.set('filterMode', mode);
      },

      confirm: function (checkpoint) {
        // TODO: Refaktor - do wywalenia z mixina
        if (checkpoint.get('content') ? !checkpoint.get('content').validate() : !checkpoint.validate()) {
          console.log('Status nie przeszedł walidacji..');
          return;
        }

        if (checkpoint.get('task.weighingIncluded') && checkpoint.get('step.warehouse.weighingIncluded') && checkpoint.get('transport.hasAutoWeight')) {
          this.send('weigh', checkpoint, true);
        } else {
          this.send('sendCheckpoint', checkpoint);
        }
      },
      sendCheckpoint: function (checkpoint) {
        // TODO: Refaktor - do wywalenia z mixina
        this.get('checkpointService').confirmAndRefresh(checkpoint).catch(console.error);
      },

      revoke(checkpoint) {
        // TODO: Refaktor - do wywalenia z mixina
        const checkpointId = checkpoint.get('id');
        const token = this.get('sessionAccount').get('token');
        const self = this; // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/checkpoints/' + checkpointId + '/revoke',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(async function () {
          self.store.findRecord('checkpoint', checkpointId).then(function (data) {
            data.reload();
            data.get('transport').reload();
          });
          const transport = checkpoint.get('transport');

          if (transport.get('isCombinedTransport')) {
            const combinedTransports = await transport.get('allCombinedTransports');
            const combinedTransportsTransportTypes = combinedTransports.map(t => t.get('transportType'));
            combinedTransportsTransportTypes.forEach(tt => {
              if (tt) {
                tt.set('forcedPollRequestTime', new Date().getTime());
              }
            });
          }
        });
      },

      reject(checkpoint) {
        // TODO: Refaktor - do wywalenia z mixina
        this.checkpointService.reject(checkpoint);
      },

      weigh: async function (checkpoint, confirmCheckpoint = false) {
        // TODO: Refaktor - do wywalenia z mixina
        const self = this;
        const weightService = this.get('weightService');
        const parsedReadOut = await weightService.fetchAndParseWeight();
        const savedNewWeight = await weightService.saveNewWeight(checkpoint, parsedReadOut);

        if (confirmCheckpoint) {
          // Status potwierdzimy nawet jeśli nie uda się zapisać wagi, tak działało to przed refaktorem
          this.send('sendCheckpoint', checkpoint);
        }

        if (!savedNewWeight || !parsedReadOut) {
          if (!confirmCheckpoint) {
            alert(self.get('intl').t('transport.weightSection.errors.cannotGetWeight'));
          } else {
            if (confirm(self.get('intl').t('transport.weightSection.errors.trySaveCheckpointWithoutWeight'))) {
              await weightService.saveNewWeight(checkpoint, 0);
              this.send('sendCheckpoint', checkpoint);
            }
          }
        }
      },

      generateWeightReceipt(transportId) {
        // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
        Ember.run.once(this, function () {
          window.open(_environment.default.serverURL + '/transports/' + transportId + '/weightReceipt?token=' + this.get('sessionAccount').get('token'), '_blank');
        });
      },

      addNote: function (noteContent, transportId) {
        console.log('addNote: ' + noteContent + ', transportId: ' + transportId);
        const self = this;
        const transport = this.store.peekRecord('transport', transportId);
        const note = self.store.createRecord('note', {
          transport,
          note: noteContent
        });
        note.save().then(n => {
          transport.get('notes').pushObject(n);
        });
      },

      /**
       * Metoda przenosi użytkownika do magazynu.
       *
       * Dzień, do którego trzeba przenieść użytkownika, to
       * <ul>
       *  <li>dzień okna czasowego - jeśli zostało już dodane,
       *  <li>dzień początku zakresu czasu na awizację - jeśli nie znajduje się on w przeszłości,
       *  <li>aktualny dzień - w pozostałych przypadkach.
       * </ul>
       *
       * @param step
       */
      goToWarehouse(step) {
        const self = this;
        const adviceButton = (0, _jquery.default)('.advice-button-' + step.get('transport.id'));
        adviceButton.button('loading');

        if (!step.get('isInWarehouse')) {
          this.openCreateAdviceModal(step.get('transport'));
          adviceButton.button('reset');
          return;
        }

        const warehouseId = step.get('warehouse.id');
        const editedWindow = step.get('timeWindow');

        if (editedWindow.get('id')) {
          editedWindow.setProperties({
            oldStart: editedWindow.get('start'),
            oldStop: editedWindow.get('stop'),
            oldRamp: editedWindow.get('ramp')
          });
        }

        if (editedWindow.get('start')) {
          this.get('scheduleController').setProperties({
            warehouseStepToHandle: step
          });
          const dateString = moment(editedWindow.get('start')).format('YYYY-MM-DD');
          this.transitionToRoute('warehouses.schedule', warehouseId, dateString, '1');
          adviceButton.button('reset');
          return;
        }

        const transport = this.get('store').peekRecord('transport', step.get('transport.id'));
        this.getFirstPossibleWindowStart(step, transport).then(response => {
          const firstPossibleStart = response.firstPossibleStart;

          if (!firstPossibleStart) {
            alert(response.error);
            adviceButton.button('reset');
            return;
          }

          self.get('scheduleController').setProperties({
            warehouseStepToHandle: step,
            info: null
          });
          const dateString = moment(firstPossibleStart).format('YYYY-MM-DD');
          self.transitionToRoute('warehouses.schedule', warehouseId, dateString, '1');
          adviceButton.button('reset');
        });
      },

      openCreateAdviceModal(transport) {
        transport.set('hasCustomFieldsInitialized', false);
        const scheduleController = this.get('scheduleController');
        scheduleController.setProperties({
          warehouseStepToHandle: transport.get('firstWarehouseStepWithoutWindow')
        });
        scheduleController.send('openCreateAdviceModal', [], transport);
      },

      scrollPage(transportTypeId) {
        (0, _jquery.default)(window).scrollTop((0, _jquery.default)('#' + transportTypeId).offset().top - 125);
      },

      fileUploadSuccess: function (transportId) {
        console.log('fileUploadSuccess: ' + transportId);
        this.store.findRecord('transport', transportId).then(function (data) {
          data.reload();
        });
      },

      deleteFile(file) {
        console.log('deleteFile: ' + file.id);
        const transport = file.get('transport');
        file.destroyRecord().then(() => {
          transport.then(t => t.reload());
        });
      },

      fileUploadFail: function (transportId) {
        console.log('fileUploadFail: ' + transportId);
      }
    },

    /** @namespace response.firstPossibleStart */
    getFirstPossibleWindowStart(step, transport, selectedDate) {
      const self = this;
      return new Promise(function (resolve) {
        self.timeWindowService.getFirstPossibleWindowStart(step, transport, selectedDate).then(function (response) {
          resolve(response);
        });
      });
    },

    getStatuses(transport) {
      if (!transport) {
        console.error('Próba pobrania statusów nie powiodła się - brak transportu!');
        return;
      }

      const self = this;
      const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

      _jquery.default.ajax({
        url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/checkpoints',
        type: 'GET',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function (json) {
        self.get('store').pushPayload(json);
        json.checkpoints.forEach(function (checkpoint) {
          const c = self.get('store').peekRecord('checkpoint', checkpoint.id);

          if (self.get('transport.checkpoints') && !self.get('transport.checkpoints').includes(c)) {
            console.debug(`Pushing checkpoint ${c.get('id')} into transport ${self.get('transport.id')}..`);
            self.get('transport.checkpoints').pushObject(c);
          }
        });
      });
    },

    printErrors(transport) {
      try {
        console.log(`Transport ${transport.get('logString')} zawiera błędy...`);
        transport.get('errors').forEach(error => console.log(error));
        transport.get('steps').filter(s => s.get('numberOfErrors') > 0).forEach(s => {
          s.get('errors').forEach(se => console.log(se));
        });
        transport.get('customFields').filter(cf => cf.get('numberOfErrors') > 0).forEach(cf => {
          cf.get('errors').forEach(cfe => console.log(`${cf.get('definition.name')} - ${cfe.message}`));
        });
        transport.get('indexes').filter(cf => cf.get('hasErrors')).forEach(index => {
          index.get('errors').forEach(error => console.log(error));
        });
      } catch (e) {
        console.error(e);
      }
    }

  });

  _exports.default = _default;
});