define("apollo/pods/components/route/route-template-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onSelect(route) {
        this.onSelect(route);
      }

    }
  });

  _exports.default = _default;
});