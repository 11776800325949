define("apollo/services/transport-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    unloadTransports: function (transportType) {
      this.get('store').peekAll('transport').filterBy('transportType.id', transportType.get('id')).forEach(t => {
        t.unloadRecord();
      });
    }
  });

  _exports.default = _default;
});