define("apollo/pods/components/delivery-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
    },
    deliveryTitle: Ember.computed('delivery.{deliverySetting.autoGenerateDeliveryNumber,deliveryNumber,' + 'loadStep.isInWarehouse,loadStep.warehouse.nameInAppropriateLanguage,loadStep.address.recipient,' + 'unloadStep.isInWarehouse,unloadStep.warehouse.nameInAppropriateLanguage,unloadStep.address.recipient}', function () {
      const delivery = this.get('delivery');
      const deliveryLabel = this.get('intl').t('delivery.label');
      const isAutoGenerateDeliveryNumberEnabled = delivery.get('deliverySetting.autoGenerateDeliveryNumber');
      const loadStep = delivery.get('loadStep');
      const unloadStep = delivery.get('unloadStep');
      const loadStepName = loadStep.get('isInWarehouse') ? loadStep.get('warehouse.nameInAppropriateLanguage') : loadStep.get('address.recipient');
      const unloadStepName = unloadStep.get('isInWarehouse') ? unloadStep.get('warehouse.nameInAppropriateLanguage') : unloadStep.get('address.recipient');

      if (!isAutoGenerateDeliveryNumberEnabled) {
        return `${deliveryLabel} ${delivery.deliveryNumber} - ${loadStepName} - ${unloadStepName}`;
      } else {
        return `${deliveryLabel} - ${loadStepName} - ${unloadStepName}`;
      }
    })
  });

  _exports.default = _default;
});