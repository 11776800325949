define("apollo/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  // Refaktor
  function formatDate(rawData) {
    const date = rawData[0];
    const dateFormat = rawData[1] || 'DD.MM.YYYY HH:mm';
    const dateIsDateType = Object.prototype.toString.call(date) === '[object Date]';
    const dateIsStringType = Object.prototype.toString.call(date) === '[object String]';
    const dateIsValidDate = dateIsDateType || dateIsStringType;

    if (!dateIsValidDate) {
      return '';
    }

    if (dateIsStringType) {
      return date;
    } // Źle obsługuje daty w formie stringa np "03.05.2025" przekształci na "05.03.2025" gdy dateFormat=DD.MM.YYYY
    // dlatego wcześniejszy return w przypadku dateIsStringType


    return moment(date).format(dateFormat ? dateFormat : "DD.MM.YYYY HH:mm");
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});