define("apollo/pods/transports/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_TRANSPORT_TABLE']),
    queryParams: Object.freeze({
      query: {
        refreshModel: true
      },
      start: {
        refreshModel: true
      },
      stop: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    }),
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }

      const userTransportTypes = this.get('sessionAccount.currentUser.transportTypes');
      const enteredTypeName = transition.params['transports.index'].type;

      if (enteredTypeName !== 'ALL' && !userTransportTypes.getEach('alias').includes(enteredTypeName)) {
        console.error(`User entered ${enteredTypeName} in params, but doesn't have access to it (or the type doesn't exist).`);
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    // eslint-disable-next-method ember/no-controller-access-in-routes
    model: function (params) {
      const transportTypeMode = params.type; // eslint-disable-next-line ember/no-controller-access-in-routes

      const previousTypeName = this.controller ? this.controller.model.transportTypeMode : null; // if the controller has content already, just return that
      // eslint-disable-next-line ember/no-controller-access-in-routes

      if (this.controller && !this.controller.archive && this.controller.get('content') && transportTypeMode === previousTypeName) {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        return this.controller.get('content');
      }

      return Ember.RSVP.hash({
        availableTransportTypes: this.get('store').peekAll('transportType').filter(transportType => {
          return transportType.canBeSeenByRoleGroup;
        }),
        transportTypeMode
      });
    },
    setupController: function (controller, model) {
      model.availableTransportTypes.some(function (tt) {
        return tt.get('enableTransportTableDaysLimit');
      });
      const daysLimitSettingEnabled = model.transportTypeMode === 'ALL' ? model.availableTransportTypes.some(function (tt) {
        return tt.get('enableTransportTableDaysLimit');
      }) : model.availableTransportTypes.find(function (tt) {
        return tt.get('alias') === model.transportTypeMode;
      }).enableTransportTableDaysLimit;
      const availableRelations = this.get('store').peekAll('transportRelation');
      controller.setProperties({
        model,
        archive: false,
        daysLimitSettingEnabled,
        daysLimitEnabled: daysLimitSettingEnabled,
        availableRelations,
        page: controller.page,
        max: controller.max
      });
    },

    activate() {
      this.activateCurrentTab('TRANSPORT_TAB');
    },

    actions: {
      refreshRoute: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});