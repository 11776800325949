define("apollo/transforms/pldate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      if (serialized) {
        return moment(moment.parseZone(serialized).format('YYYY/MM/DD HH:mm:ss')).toDate();
      }

      return serialized;
    },
    serialize: function (deserialized) {
      if (deserialized) {
        return moment(deserialized).format('DD.MM.YYYY HH:mm');
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});