define("apollo/pods/components/serial-transports-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iEkHV8IN",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"recurring-transport-form\",null,[[\"type\",\"isActive\",\"model\",\"transport\",\"labelTranslationKey\"],[\"serialTransport\",[23,[\"transport\",\"serialTransportActive\"]],[23,[\"transport\",\"serialTransport\"]],[23,[\"transport\"]],\"serialTransport.label\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/serial-transports-form/template.hbs"
    }
  });

  _exports.default = _default;
});