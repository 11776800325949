define("apollo/pods/components/transport/transport-packages-section/component", ["exports", "apollo/models/packages-table-column-model"], function (_exports, _packagesTableColumnModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.send('loadPackages');
    },

    intl: Ember.inject.service(),
    transportPackagingService: Ember.inject.service(),
    store: Ember.inject.service(),
    showPackageFormModal: false,
    currentPackage: null,
    packages: Ember.computed('transport', function () {
      return Ember.A([]);
    }),
    canDeletePackages: Ember.computed('packages.length', 'transport.truckType.requireTransportPackaging', function () {
      const truckTypeRequiresPackages = this.get('transport.truckType.requireTransportPackaging');

      if (!truckTypeRequiresPackages) {
        return true;
      }

      return this.get('packages.length') > 1;
    }),
    isNewTransport: Ember.computed('transport', function () {
      return this.get('transport.id') === null;
    }),
    showActionColumn: Ember.computed(function () {
      return this.get('canAddPackage') || this.get('canEditPackage');
    }),
    canAddPackage: Ember.computed(function () {
      return this.get('transport').actionCanBePerformed('ADD_PACKAGES');
    }),
    canEditPackage: Ember.computed(function () {
      return this.get('transport').actionCanBePerformed('EDIT_PACKAGES');
    }),
    showLdm: Ember.computed('packages.@each.ldm', function () {
      return this.get('packages').some(p => {
        return p.get('ldm');
      });
    }),
    columns: Ember.computed('showLdm', function () {
      const columns = Ember.A([_packagesTableColumnModel.default.create({
        key: 'id',
        label: 'ID',
        sortable: true
      }), _packagesTableColumnModel.default.create({
        key: 'length',
        label: this.intl.t('transport.packagesSection.package.length'),
        sortable: true
      }), _packagesTableColumnModel.default.create({
        key: 'width',
        label: this.intl.t('transport.packagesSection.package.width'),
        sortable: true
      }), _packagesTableColumnModel.default.create({
        key: 'height',
        label: this.intl.t('transport.packagesSection.package.height'),
        sortable: true
      }), _packagesTableColumnModel.default.create({
        key: 'weight',
        label: this.intl.t('transport.packagesSection.package.weight'),
        sortable: true,
        summable: true,
        sumFunction: (row, columns) => {
          const weight = parseFloat(row.weight) || 0;
          const quantity = parseFloat(row.quantity) || 0;
          return weight * quantity;
        }
      }), _packagesTableColumnModel.default.create({
        key: 'quantity',
        label: this.intl.t('transport.packagesSection.package.quantity'),
        sortable: true,
        summable: true
      }), _packagesTableColumnModel.default.create({
        key: 'packageType',
        label: this.intl.t('transport.packagesSection.package.packageType'),
        template: 'packages-table/templates/package-type-row'
      }), _packagesTableColumnModel.default.create({
        key: 'stackingFactor',
        label: this.intl.t('transport.packagesSection.package.stackingFactor'),
        template: 'packages-table/templates/package-stacking-factor-row'
      }), _packagesTableColumnModel.default.create({
        key: 'description',
        label: this.intl.t('transport.packagesSection.package.description')
      })]);

      if (this.get('showLdm')) {
        columns.pushObject(_packagesTableColumnModel.default.create({
          key: 'roundedLdm',
          label: this.intl.t('deliveryPackaging.ldmLabel'),
          summable: true
        }));
      }

      return columns;
    }),
    rows: Ember.computed('packages.length', function () {
      return this.get('packages');
    }),
    actions: {
      loadPackages: async function () {
        try {
          const transportId = this.transport.id;

          if (!transportId) {
            if (this.transport.originalTransportId) {
              this.set('packages', this.transport.get('transportPackages').toArray());
            }

            return;
          }

          this.set('isLoading', true);
          const records = await this.store.query('transport-packaging', {
            transportId
          });
          this.set('packages', records.toArray());
        } catch (error) {
          console.error('Failed to load packages', error);
        } finally {
          this.set('isLoading', false);
        }
      },
      openModalForCreatingNew: function () {
        // TODO: manipulacja DOM do zmiany
        // stary modal nie działa dobrze z nowym gdy ten nowy jest nad nim
        document.querySelector('.modal-open .modal').style.overflowY = 'hidden';
        document.querySelector('.modal-open .modal').removeAttribute('tabindex');
        this.set('currentPackage', this.store.createRecord('transport-packaging', {
          transport: this.get('transport'),
          enabled: true
        }));
        this.set('showPackageFormModal', true);
      },
      openModalForEdition: function (transportPackage) {
        // TODO: manipulacja DOM do zmiany
        document.querySelector('.modal-open .modal').style.overflowY = 'hidden';
        document.querySelector('.modal-open .modal').removeAttribute('tabindex');
        this.set('currentPackage', transportPackage);
        this.set('isEditing', true);
        this.set('showPackageFormModal', true);
      },
      closeModal: function () {
        // TODO: manipulacja DOM do zmiany
        document.querySelector('.modal-open .modal').style.overflowY = 'auto';
        document.querySelector('.modal-open .modal').setAttribute('tabindex', -1);
        this.get('currentPackage').rollbackAttributes();
        this.set('currentPackage', null);
        this.set('showPackageFormModal', false);
      },
      onSave: function () {
        // TODO: do zmiany
        const transportModalElement = document.querySelector('.modal-open .modal');

        if (transportModalElement) {
          transportModalElement.style.overflowY = 'auto';
          transportModalElement.setAttribute('tabindex', -1);
        }

        const currentPackage = this.get('currentPackage');
        const isNewTransport = this.get('isNewTransport');
        const isAddingNewPackage = !this.packages.findBy('id', `${currentPackage.id}`) && !this.get('isEditing');

        if (isNewTransport && !this.get('isEditing')) {
          this.get('transport.transportPackages').pushObject(currentPackage);
        }

        if (isAddingNewPackage) {
          this.packages.pushObject(currentPackage);
        }

        this.setProperties({
          isEditing: false,
          currentPackage: null,
          showPackageFormModal: false
        });
      },
      deleteTransportPackage: function (transportPackage) {
        if (this.transportPackagingService.delete(transportPackage)) {
          this.packages.removeObject(transportPackage);
        }
      }
    }
  });

  _exports.default = _default;
});