define("apollo/pods/transport-types/price-list-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    priceListSetting: Ember.computed('model.priceListSetting', function () {
      return this.get('model.priceListSetting');
    }),
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    actions: {
      save() {
        this.set('errors', []);
        const priceListSetting = this.get('priceListSetting');
        priceListSetting.set('transportType', this.get('transportType'));

        if (!priceListSetting.validate()) {
          return;
        }

        priceListSetting.save().then(() => {
          this.send('refreshModel');
          const msg = this.get('intl').t('common.saveSuccessMessage');
          this.set('successMessage', msg);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }).catch(response => {
          this.set('errors', response.errors);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
      },

      create() {
        if (this.get('priceListSetting')) {
          return;
        }

        const transportType = this.get('transportType');
        this.set('priceListSetting', this.get('store').createRecord('priceListSetting', {
          transportType
        }));
      },

      delete() {
        this.set('errors', []);
        this.get('priceListSetting').destroyRecord().then(() => {
          this.set('priceListSetting', null);
          this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
        }).catch(response => this.handleErrorResponse(this, response));
      },

      cancel() {
        this.get('priceListSetting').rollbackAttributes();
        this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
      }

    }
  });

  _exports.default = _default;
});