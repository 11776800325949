define("apollo/pods/components/drivers/drivers-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'driverName',
        title: this.get('intl').t('driver.name'),
        className: 'column-driver-name'
      }, {
        propertyName: 'phoneNumber',
        title: this.get('intl').t('driver.phoneNumber'),
        className: 'column-phone-number'
      }, {
        propertyName: 'ibanSymbol',
        title: this.get('intl').t('driver.country'),
        className: 'column-country'
      }, {
        propertyName: 'documentType',
        title: this.get('intl').t('driver.documentType.label'),
        className: 'column-document-type',
        filterWithSelect: true,
        predefinedFilterOptions: [{
          value: 'ID_CARD',
          label: this.get('intl').t('driver.documentType.ID_CARD')
        }, {
          value: 'PASSPORT',
          label: this.get('intl').t('driver.documentType.PASSPORT')
        }]
      }, {
        propertyName: 'documentNumber',
        title: this.get('intl').t('driver.documentNumber'),
        className: 'column-document-number'
      }, {
        propertyName: 'lastUpdated',
        title: this.get('intl').t('driver.lastUpdated'),
        className: 'column-last-updated',
        componentForFilterCell: 'server-table/filters/date-filter'
      }];
    }),
    actions: {
      openAddDriverModal(company, driver) {
        if (!driver) {
          driver = this.get('store').createRecord('driver', {
            documentType: 'ID_CARD',
            enabled: true,
            owner: company,
            country: this.get('store').peekAll('country').filterBy('ibanSymbol', 'PL').get('firstObject')
          });
        }

        this.set('action', 'showModal');
        this.sendAction('action', 'companies.modals.add-driver', driver);
      }

    }
  });

  _exports.default = _default;
});