define("apollo/pods/components/transport/step-header/component", ["exports", "apollo/mixins/step-mixin"], function (_exports, _stepMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepMixin.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    canAddLoadSteps: Ember.computed('transport.{getCouriersQuotes,transportType.customFieldDefinitions.[]}', function () {
      const stepType = this.get('step.stepTypeName');
      let hasAuthorityToAddStep = false;

      if (this.get('step.createdFromRoute')) {
        return false;
      }

      if (this.get('transport.getCouriersQuotes')) {
        return false;
      }

      if (stepType === this.get('step.STEP_TYPES').LOAD) {
        hasAuthorityToAddStep = this.get('transport').actionCanBePerformed('ADD_LOAD_STEP_FROM_LOAD');
      } else if (stepType === this.get('step.STEP_TYPES').UNLOAD) {
        hasAuthorityToAddStep = this.get('transport').actionCanBePerformed('ADD_LOAD_STEP_FROM_UNLOAD');
      }

      return hasAuthorityToAddStep;
    }),
    canAddUnloadSteps: Ember.computed('transport.{getCouriersQuotes,transportType.customFieldDefinitions.[]}', function () {
      const stepType = this.get('step.stepTypeName');
      let hasAuthorityToAddStep = false;

      if (this.get('step.createdFromRoute')) {
        return false;
      }

      if (this.get('transport.getCouriersQuotes')) {
        return false;
      }

      if (stepType === this.get('step.STEP_TYPES').LOAD) {
        hasAuthorityToAddStep = this.get('transport').actionCanBePerformed('ADD_UNLOAD_STEP_FROM_LOAD');
      } else if (stepType === this.get('step.STEP_TYPES').UNLOAD) {
        hasAuthorityToAddStep = this.get('transport').actionCanBePerformed('ADD_UNLOAD_STEP_FROM_UNLOAD');
      }

      return hasAuthorityToAddStep;
    }),
    canBeDeleted: Ember.computed('canNotEditAfterProcessStarts', 'step.transport.loadSteps.length', function () {
      const stepType = this.get('step.stepTypeName');
      let hasAuthorityToDelete = false;

      if (stepType === this.get('step.STEP_TYPES').LOAD) {
        hasAuthorityToDelete = this.get('transport').actionCanBePerformed('DELETE_LOAD_STEPS');
      } else if (stepType === this.get('step.STEP_TYPES').UNLOAD) {
        hasAuthorityToDelete = this.get('transport').actionCanBePerformed('DELETE_UNLOAD_STEPS');
      }

      if (this.get('canNotEditAfterProcessStarts') || !hasAuthorityToDelete) {
        return false;
      }

      return this.get('step.transport.' + stepType + 'Steps.length') > 1;
    }),
    actions: {
      addStep(baseStep, stepType) {
        const store = this.get('store');
        const transport = this.get('transport');
        const orderInTransport = baseStep.get('orderInTransport') + 1;
        const isLoadStepInWarehouseByDefault = transport.get('transportType.isLoadStepInWarehouseByDefault');
        const isUnloadStepInWarehouseByDefault = transport.get('transportType.isUnloadStepInWarehouseByDefault');
        const ownedByPrincipal = stepType === baseStep.get('STEP_TYPES').LOAD ? isLoadStepInWarehouseByDefault : isUnloadStepInWarehouseByDefault; // Jeśli `shouldCountryFieldBeAutocompleted = true` to pole 'Kraj' w nowych stepach uzupelniamy wartością z pierwszego stepu

        const shouldCountryFieldBeAutocompleted = transport.get('transportType.duplicateStepCountryFieldToNewSteps');
        const step = this.get('store').createRecord('step', {
          ownedByPrincipal,
          shouldHaveContractor: !ownedByPrincipal,
          stepTypeName: stepType,
          orderInTransport,
          minAdviceDate: null,
          maxAdviceDate: null
        });

        if (!step.get('ownedByPrincipal')) {
          this.send('setCompanyInStep', step);
          const address = store.createRecord('address', {});
          step.set('address', address);
          step.setArrivalDate();
        }

        if (shouldCountryFieldBeAutocompleted) {
          const baseStepCountry = this.get('transport').get('steps.firstObject.address.country');
          const address = store.createRecord('address', {
            country: baseStepCountry
          });
          step.set('address', address);
        } // Musimy przesunąć niektóre stepy (np. po dodaniu załadunku, wszystkie rozładunki muszą zmienić kolejność)


        transport.get('steps').filter(s => {
          return s.get('orderInTransport') >= orderInTransport;
        }).forEach(s => {
          const currentOrderInTransport = s.get('orderInTransport');
          s.set('orderInTransport', currentOrderInTransport + 1);
        });
        console.log(`Dodajemy step do transportu ${transport.id} na miejscu ${orderInTransport}..`);
        transport.get('steps').pushObject(step);
        this.recalculateStepTypeIdx();
        this.handleStepPackageTypesForStep(step);
      },

      deleteStep(idx) {
        const self = this;
        const transport = this.get('step.transport');
        const stepArray = transport.get('steps').filterBy('orderInTransport', idx);

        if (!stepArray) {
          return;
        }

        let msg = this.get('intl').t('step.delete.confirmMessage');

        if (this.get('step.indexes.length') > 0) {
          msg = this.get('intl').t('step.delete.confirmMessageWithIndexes');
        }

        if (!confirm(msg)) {
          return;
        }

        const removedCompany = this.get('step.company');

        if (transport.get('content').checkIfEditionViolatesPeriodicTransportRules(removedCompany)) {
          alert(this.get('intl').t('periodicTransport.cannotDeleteStep'));
          return;
        }

        const step = stepArray.get('firstObject');
        step.get('indexes').forEach(index => {
          transport.get('indexes').removeObject(index);
        });
        transport.get('steps').removeObject(step).then(() => {
          // Usuwamy ewentualne błędy dot. nieunikalnych dostawców
          const contractorNames = transport.get('contractorNames');

          if (contractorNames.length === contractorNames.uniq().length) {
            transport.get('errors').remove('contractors');
          } // Przeliczamy kolejność stepów w transporcie


          transport.get('steps').sortBy('orderInTransport').forEach((s, idx) => {
            s.set('orderInTransport', idx);
          });
          self.recalculateStepTypeIdx();
        });
      },

      changeOwnedByPrincipal: function (step) {
        const transport = this.get('transport');
        const orderInTransport = step.get('orderInTransport');
        const stepTypeName = step.get('stepTypeName');
        const ownedByPrincipal = step.get('ownedByPrincipal');
        step.get('stepPackageTypes').forEach(spt => {
          if (!spt) {
            return;
          }

          spt.set('step', null);
          spt.unloadRecord();
        });
        step.set('transport', null);
        const company = step.get('company');
        step.unloadRecord();
        const newStep = this.get('store').createRecord('step', {
          orderInTransport,
          stepTypeName,
          company,
          ownedByPrincipal: !ownedByPrincipal,
          shouldHaveContractor: ownedByPrincipal
        });
        transport.get('steps').pushObject(newStep);
        this.handleStepPackageTypes([newStep], transport.get('transportType.id'));

        if (!newStep.get('isInWarehouse')) {
          this.send('setCompanyInStep', newStep);
          newStep.setArrivalDate();
        } else if (transport.get('transportType.requiresAdviceDateRestrictions')) {
          const defaultMinAdviceDate = moment().startOf('day').toDate();
          const defaultMaxAdviceDate = moment().startOf('day').add(1, 'day').toDate();
          newStep.setProperties({
            minAdviceDate: defaultMinAdviceDate,
            maxAdviceDate: defaultMaxAdviceDate
          });
        }
      },
      setCompanyInStep: function (step) {
        const company = this.get('sessionAccount.currentUser.company');
        const transport = this.get('step.transport');
        const isUnload = step.get('isUnload');
        const isLoad = step.get('isLoad');
        const defaultLoadCompanyId = transport.get('transportType.defaultLoadCompanyId') ? transport.get('transportType.defaultLoadCompanyId') : step.get('transport.transportType.defaultLoadCompanyId');
        const defaultUnloadCompanyId = transport.get('transportType.defaultUnloadCompanyId') ? transport.get('transportType.defaultUnloadCompanyId') : step.get('transport.transportType.defaultLoadCompanyId');
        const companyIdToSet = isLoad && defaultLoadCompanyId ? defaultLoadCompanyId : isUnload && defaultUnloadCompanyId ? defaultUnloadCompanyId : null;

        if (companyIdToSet != null) {
          this.store.findRecord('company', companyIdToSet).then(c => {
            step.set('company', c);
          }).catch(err => console.log(err.message));
        } else {
          if (company.get('isSupplier')) {
            step.set('company', company);
          }
        }
      },
      beforeDrag: function (step, event) {
        console.log(`Próbujemy przesunąć step ${step.get('orderInTransport')}...`);

        if (!step.get('canBeMoved')) {
          event.preventDefault();
        }
      },

      /**
       *  potrzebne do nadpisania domyślnego stylowania draggable-object
       */
      dragStartHook: function () {}
    }
  });

  _exports.default = _default;
});