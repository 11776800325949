define("apollo/pods/components/transports/transports-table/transport-row/transaction-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    transactionMethod: Ember.computed('transaction.transactionMethod', function () {
      return this.get('transaction.transactionMethod');
    }),
    transactionIcon: Ember.computed('transactionMethod', function () {
      switch (this.get('transactionMethod')) {
        case 'AUCTION':
          return 'fa fa-gavel';

        case 'ROUTE_OFFER':
          return 'fa-solid fa-file-contract';

        case 'FORWARDED':
          return 'fa fa-handshake';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});