define("apollo/pods/components/amount-of-cover-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cmf0cOCe",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group small-margin-form-group\"],[9],[0,\"\\n    \"],[7,\"label\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"asPlainTemplate\"]],\"col-xs-5\",\"col-md-5 col-xs-12\"],null],\" control-label\"]]],[9],[0,\"\\n        \"],[1,[21,\"label\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"asPlainTemplate\"]],\"col-xs-3\",\"col-md-7 col-xs-12\"],null]]]],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"col-xs-9 no-padding padding-right-small \",[27,\"if\",[[23,[\"amountOfCover\",\"errors\",\"amount\"]],\"has-error\"],null]]]],[11,\"data-field-name\",\"amount-of-cover-amount\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"pattern\",\"min\",\"class\",\"value\",\"disabled\",\"placeholder\"],[\"number\",\"^[0-9]+\\\\.[0-9][0-9]$\",\"0\",\"form-control\",[23,[\"aocAmount\"]],[23,[\"disabled\"]],[27,\"t\",[\"common.min\"],null]]]],false],[0,\"\\n            \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"amountOfCover\"]],\"amount\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-xs-3 no-padding\"],[11,\"data-field-name\",\"amount-of-cover-currency\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"selected\",\"options\",\"renderInPlace\",\"searchField\",\"disabled\",\"placeholder\",\"onchange\"],[\"form-control\",[23,[\"aocCurrency\"]],[23,[\"currencies\"]],true,\"symbol\",[23,[\"disabled\"]],[27,\"t\",[\"common.currency\"],null],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"aocCurrency\"]]],null]],null]]],{\"statements\":[[0,\"                \"],[1,[22,1,[\"symbol\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/amount-of-cover-section/template.hbs"
    }
  });

  _exports.default = _default;
});