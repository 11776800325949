define("apollo/pods/components/route/route-template-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uxgtNcmL",
    "block": "{\"symbols\":[\"r\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"label\"],[12,\"class\",[28,[\"col-md-5 col-xs-12 control-label\\n                \",[27,\"if\",[[23,[\"transport\",\"errors\",\"routeTemplate\"]],\"has-error\"],null]]]],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"transport.routeTemplate\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"col-md-7 col-xs-12 \",[27,\"if\",[[23,[\"transport\",\"errors\",\"routeTemplate\"]],\"has-error\"],null]]]],[11,\"data-field-name\",\"route-template\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"disabled\",\"selected\",\"options\",\"renderInPlace\",\"searchField\",\"placeholder\",\"onchange\"],[\"form-control\",[23,[\"transport\",\"id\"]],[23,[\"transport\",\"routeTemplate\"]],[23,[\"availableRoutes\"]],true,\"name\",[27,\"t\",[\"transport.routeTemplate\"],null],[27,\"action\",[[22,0,[]],\"onSelect\"],null]]],{\"statements\":[[0,\"            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"transport\"]],\"routeTemplate\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/route/route-template-select/template.hbs"
    }
  });

  _exports.default = _default;
});