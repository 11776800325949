define("apollo/pods/components/periodic-transport-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YwlsBhGa",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"recurring-transport-form\",null,[[\"type\",\"isActive\",\"model\",\"transport\",\"labelTranslationKey\"],[\"periodicTransport\",[23,[\"transport\",\"periodicTransportActive\"]],[23,[\"transport\",\"periodicTransport\"]],[23,[\"transport\"]],\"periodicTransport.label\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/periodic-transport-form/template.hbs"
    }
  });

  _exports.default = _default;
});