define("apollo/pods/delivery/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator", "apollo/models/custom-field-pair", "apollo/utils/validators/step-dates-validator"], function (_exports, _emberData, _model, _modelValidator, _customFieldPair, _stepDatesValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    rounding: Ember.inject.service('rounding-service'),
    deliveryNumber: attr('string'),
    netWeight: attr('number'),
    grossWeight: attr('number'),
    incoterms: attr('string'),
    parentDeliveryNumber: attr('string'),
    description: attr('string'),
    documentType: attr('string'),
    archived: attr('boolean'),
    modeOfTransport: attr('string'),
    freightCost: attr('number'),
    loadStep: belongsTo('step', {
      async: true,
      deepEmbedded: ['warehouse', 'address']
    }),
    unloadStep: belongsTo('step', {
      async: true,
      deepEmbedded: ['warehouse', 'address']
    }),
    freightCostCurrency: belongsTo('currency', {
      async: true
    }),
    invoiceNumber: attr('string'),
    deliveryCustomFieldValues: hasMany('deliveryCustomFieldValue', {
      async: false
    }),
    packaging: hasMany('deliveryPackaging', {
      async: true,
      deepEmbedded: ['packageType']
    }),
    realLoadDate: attr('date'),
    realUnloadDate: attr('date'),
    unloadContactPersonDetails: attr('string'),
    isFromSap: attr('boolean'),
    transportType: belongsTo('transportType', {
      async: true
    }),
    deliverySetting: belongsTo('delivery-setting', {
      async: true
    }),
    color: attr('string'),
    requireFreightCostDuringTransaction: attr('boolean', {
      defaultValue: function () {
        return false;
      }
    }),
    validations: {
      deliveryNumber: {
        presence: {
          message: 'blank'
        }
      },
      incoterms: {
        presence: {
          message: 'blankSelect'
        }
      },
      packaging: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireAtLeastOnePackaging')) {
              return model.get('packaging.length') > 0;
            }

            return true;
          },
          message: 'minimumOnePackage'
        }, {
          validation: function (key, value, model) {
            if (model.get('deliverySetting.showSimplePackagingSection')) {
              return model.get('packaging').filter(p => p.get('required')).every(p => p.validate({
                only: ['quantity', 'packageType']
              }));
            }

            if (model.get('packaging.length') === 0) {
              return true;
            }

            if (model.get('deliverySetting.requireStackingFactor')) {
              model.get('packaging').forEach(p => p.validate());
            } else {
              model.get('packaging').forEach(p => p.validate({
                except: ['stackingFactor']
              }));
            }

            return model.get('packaging').every(p => p.get('errors.length') === 0);
          },
          message: ''
        }]
      },
      grossWeight: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireGrossWeight')) {
              return Number(value) > 0;
            }

            return true;
          },
          message: 'positiveNumber'
        }]
      },
      netWeight: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireNetWeight')) {
              return Number(value) > 0;
            }

            return true;
          },
          message: 'positiveNumber'
        }]
      },
      loadStep: {
        custom: [{
          validation: (key, value, model) => {
            const loadStep = model.get('loadStep.content');
            const unloadStep = model.get('unloadStep.content');
            return loadStep.get('orderInTransport') < unloadStep.get('orderInTransport');
          },
          message: 'orderInTransport'
        }, {
          validation: function (key, value, model) {
            if (!model.get('loadStep.isInWarehouse')) {
              if (model.get('loadStep.address.id')) {
                return true;
              } else {
                return !!model.get('loadStep.address.content') && model.get('loadStep.address.content').validate();
              }
            } else {
              return model.get('loadStep.warehouse.id');
            }
          },
          message: 'blankSelect'
        }]
      },
      loadStepArrivalDate: {
        custom: [{
          validation: (key, value, model) => {
            return _stepDatesValidator.default.arrivalDateBlank(model.get('loadStep'), model.get('transportType'), model.get('loadStep.arrivalDate'));
          },
          message: 'blankSelect'
        }, {
          validation: (key, value, model) => {
            return _stepDatesValidator.default.arrivalDateInPast(model.get('loadStep'), model.get('transportType'), model.get('loadStep.arrivalDate'));
          },
          message: 'isInPast'
        }]
      },
      loadStepMinAdviceDate: {
        custom: [{
          validation: (key, value, model) => {
            return _stepDatesValidator.default.minAdviceDateBlank(model.get('loadStep'), model.get('transportType'), model.get('loadStep.minAdviceDate'));
          },
          message: 'blankSelect'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.minAdviceDateAfterWindowStart(model.get('loadStep'), model.get('loadStep.minAdviceDate'));
          },
          message: 'afterWindowStart'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.minAdviceDateOutsideOfInterval(model.get('loadStep'), model.get('loadStep.minAdviceDate'));
          },
          message: 'outsideOfInterval'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.minAdviceDateWorkingHours(model.get('loadStep'), model.get('loadStep.minAdviceDate'));
          },
          message: 'outsideWarehouseWorkHours'
        }]
      },
      loadStepMaxAdviceDate: {
        custom: [{
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateBlank(model.get('loadStep'), model.get('transportType'), model.get('loadStep.maxAdviceDate'));
          },
          message: 'blankSelect'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateEarly(model.get('loadStep'), model.get('loadStep.maxAdviceDate'));
          },
          message: 'earlierThanMinAdviceDate'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateInPast(model.get('loadStep'), model.get('transportType'), model.get('loadStep.maxAdviceDate'));
          },
          message: 'inThePast'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateWorkHours(model.get('loadStep'), model.get('loadStep.maxAdviceDate'));
          },
          message: 'outsideWarehouseWorkHours'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateEarly(model.get('loadStep'), model.get('loadStep.maxAdviceDate'));
          },
          message: 'beforeWindowStop'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateOutsideInterval(model.get('loadStep'), model.get('loadStep.maxAdviceDate'));
          },
          message: 'outsideOfInterval'
        }]
      },
      unloadStep: {
        custom: [{
          validation: (key, value, model) => {
            const loadStep = model.get('loadStep.content');
            const unloadStep = model.get('unloadStep.content');
            return loadStep.get('orderInTransport') < unloadStep.get('orderInTransport');
          },
          message: 'orderInTransport'
        }, {
          validation: function (key, value, model) {
            if (!model.get('unloadStep.isInWarehouse')) {
              if (model.get('unloadStep.address.id')) {
                return true;
              } else {
                return !!model.get('unloadStep.address.content') && model.get('unloadStep.address.content').validate();
              }
            } else {
              return model.get('unloadStep.warehouse.id');
            }
          },
          message: 'blankSelect'
        }]
      },
      unloadStepArrivalDate: {
        custom: [{
          validation: (key, value, model) => {
            return _stepDatesValidator.default.arrivalDateBlank(model.get('unloadStep'), model.get('transportType'), model.get('unloadStep.arrivalDate'));
          },
          message: 'blankSelect'
        }, {
          validation: (key, value, model) => {
            return _stepDatesValidator.default.arrivalDateInPast(model.get('unloadStep'), model.get('transportType'), model.get('unloadStep.arrivalDate'));
          },
          message: 'isInPast'
        }]
      },
      unloadStepMinAdviceDate: {
        custom: [{
          validation: (key, value, model) => {
            return _stepDatesValidator.default.minAdviceDateBlank(model.get('unloadStep'), model.get('transportType'), model.get('unloadStep.minAdviceDate'));
          },
          message: 'blankSelect'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.minAdviceDateAfterWindowStart(model.get('unloadStep'), model.get('unloadStep.minAdviceDate'));
          },
          message: 'afterWindowStart'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.minAdviceDateOutsideOfInterval(model.get('unloadStep'), model.get('unloadStep.minAdviceDate'));
          },
          message: 'outsideOfInterval'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.minAdviceDateWorkingHours(model.get('unloadStep'), model.get('unloadStep.minAdviceDate'));
          },
          message: 'outsideWarehouseWorkHours'
        }]
      },
      unloadStepMaxAdviceDate: {
        custom: [{
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateBlank(model.get('unloadStep'), model.get('transportType'), model.get('unloadStep.maxAdviceDate'));
          },
          message: 'blankSelect'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateEarly(model.get('unloadStep'), model.get('unloadStep.maxAdviceDate'));
          },
          message: 'earlierThanMinAdviceDate'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateInPast(model.get('unloadStep'), model.get('transportType'), model.get('unloadStep.maxAdviceDate'));
          },
          message: 'inThePast'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateWorkHours(model.get('unloadStep'), model.get('unloadStep.maxAdviceDate'));
          },
          message: 'outsideWarehouseWorkHours'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateEarly(model.get('unloadStep'), model.get('unloadStep.maxAdviceDate'));
          },
          message: 'beforeWindowStop'
        }, {
          validation: function (key, value, model) {
            return _stepDatesValidator.default.maxAdviceDateOutsideInterval(model.get('unloadStep'), model.get('unloadStep.maxAdviceDate'));
          },
          message: 'outsideOfInterval'
        }]
      },
      freightCost: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireFreightCost') || model.get('requireFreightCostDuringTransaction')) {
              return /^\d+$/.test(value);
            }

            return true;
          },
          message: 'blank'
        }]
      },
      freightCostCurrency: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireFreightCost')) {
              return !!value;
            }

            return true;
          },
          message: 'blankSelect'
        }]
      }
    },
    customFieldPairs: Ember.computed('deliveryCustomFieldValues', function () {
      const dcfv = this.get('deliveryCustomFieldValues');
      return dcfv.map(cf => {
        const definition = cf.get('definition'); // cfy wyświetlamy z pomocą komponentu `custom-field-value-formatted`, który jako value
        // oczekuje całego customfielda, zeby zmapować wartość za pomocą value lub option

        const value = cf;
        return _customFieldPair.default.create({
          definition,
          value
        });
      });
    }),
    validateStep: function (step) {
      const warehouseValid = !!step.get('warehouse.id');
      const addressValid = !!step.get('address.id') || step.get('address.content') && step.get('address.content').validate();
      return warehouseValid || addressValid;
    },
    enabledPackaging: Ember.computed('packaging.@each.enabled', function () {
      return this.get('packaging').filterBy('enabled');
    }),
    enabledPackagingWeight: Ember.computed('enabledPackaging.{@each.weight,@each.quantity}', function () {
      const pckgs = this.get('enabledPackaging');
      let count = 0;
      pckgs.forEach(it => {
        count += Number(it.weight) * Number(it.quantity);
      });

      if (count === 0) {
        return '-';
      } else {
        return this.rounding.roundToTwoDecimals(count);
      }
    }),
    enabledPackagingQuantity: Ember.computed('enabledPackaging.@each.quantity', function () {
      const pckgs = this.get('enabledPackaging');
      let count = 0;
      pckgs.forEach(it => {
        count += Number(it.quantity);
      });

      if (count === 0) {
        return '-';
      } else {
        return this.rounding.roundToTwoDecimals(count);
      }
    }),
    enabledPackagingLdm: Ember.computed('enabledPackaging.@each.ldm', function () {
      const pckgs = this.get('enabledPackaging');
      let count = 0;
      pckgs.forEach(it => {
        count += Number(it.ldm);
      });

      if (count === 0) {
        return '-';
      } else {
        return this.rounding.roundToTwoDecimals(count);
      }
    })
  });

  _exports.default = _default;
});