define("apollo/services/transport-copy-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    addressUnloadTimeService: Ember.inject.service('address-unload-time-service'),

    copyTransport(originalTransport) {
      const self = this;
      const transportType = originalTransport.get('transportType');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!originalTransport || !originalTransport.copy) {
          reject();
        }

        originalTransport.copy(true, {
          ignoreAttributes: ['advice', 'carrierCompany', 'steps', 'checkpoints', 'internalIndex', 'erpIdentifier', 'altIdentifier', 'auctions', 'timeWindows', 'indexes', 'transaction', 'files', 'periodicTransport', 'customFields', 'carrierCompanyChooser', 'discrepancyReports', 'originalTransportId', 'notes', 'courierPricings', 'courierOrder', 'courierInvoice', 'courierOrderError', 'emailAdvices', 'goodsValue', 'goodsValueCurrency', 'incoterms', 'weights', 'relatedTo', 'relatedToTransports', 'masterTransports', 'hasToWaitRelations', 'relatedHasToWaitForTransports', 'relatedHasToWaitForNotCreatedTransports', 'masterHasToWaitForTransports', 'combinedMasterTransport', 'isRelatedTransportInTransportCombining', 'isMasterTransportInTransportCombining', 'isRelatedTransportInHasToWaitFor', 'isMasterTransportInHasToWaitFor', 'allCombinedTransports', 'transportRelationIdentifier', 'amountOfCover', 'transportPackages', 'serialTransportActive', 'serialTransport']
        }).then(transportCopy => {
          transportCopy.set('showCreateAdviceButton', false);
          transportCopy.set('isCopy', true);
          transportCopy.set('archived', false);
          transportCopy.set('dateOfArchiving', null);
          transportCopy.set('deleted', false);
          transportCopy.set('dateOfDeletion', null);
          transportCopy.set('originalTransportId', originalTransport.get('internalIndex'));

          transportCopy._createAmountOfCover();

          transportCopy.createSerialTransport();

          self._handleAdviceCopy(originalTransport, transportCopy, transportType);

          self.copySteps(originalTransport, transportCopy);
          self.copyCustomFields(originalTransport, transportCopy);
          self.copyTransportPackages(originalTransport, transportCopy);
          transportCopy.set('creator', self.get('sessionAccount').get('currentUser'));
          transportCopy.set('periodicTransport', self.get('store').createRecord('periodicTransport', {
            frequency: 'DAILY',
            startDate: moment().startOf('day').toDate()
          }));
          const comp = self.get('sessionAccount.currentUser.company');

          if (comp.get('partnershipType.name') === 'CARRIER' || comp.get('partnershipType.name') === 'OPERATOR') {
            transportCopy.set('carrierCompany', comp);
          }

          resolve(transportCopy);
        });
      });
    },

    _createNewAdvice(originalTransport, transportCopy) {
      const advice = this.get('store').createRecord('advice', {
        transport: transportCopy
      });
      transportCopy.set('advice', advice);
    },

    _copyAdvice(originalTransport, transportCopy) {
      if (!originalTransport.get('advice.id')) {
        return;
      }

      originalTransport.get('advice').then(advice => advice.copy(true, {
        ignoreAttributes: ['transport']
      }).then(adviceCopy => {
        adviceCopy.set('transport', transportCopy);
        transportCopy.set('advice', adviceCopy);
      }));
    },

    _handleAdviceCopy(originalTransport, transportCopy, transportType) {
      if (transportType.get('shouldCopyAdviceOnTransportCopy')) {
        this._copyAdvice(originalTransport, transportCopy, transportType);

        return;
      }

      if (originalTransport.get('transportType.adviceSavedWithTransport')) {
        this._createNewAdvice(originalTransport, transportCopy);
      }
    },

    copySteps(originalTransport, transportCopy) {
      const self = this;
      const defaultMinAdviceDate = moment().startOf('day');
      const defaultMaxAdviceDate = moment().startOf('day').add(1, 'day');
      const indexes = originalTransport.get('indexes');
      originalTransport.get('steps').forEach(s => {
        const stepCopyPromise = s.copy(true, {
          ignoreAttributes: ['address', 'transport', 'timeWindow', 'minAdviceDate', 'maxAdviceDate', 'arrivalDate', 'stepWarehouseLocations']
        });
        stepCopyPromise.then(async stepCopy => {
          stepCopy.setProperties({
            transport: transportCopy,
            ownedByPrincipal: stepCopy.get('isInWarehouse'),
            address: await s.get('address')
          });
          transportCopy.get('steps').addObject(stepCopy);

          if (stepCopy.get('isInWarehouse')) {
            if (s.get('minAdviceDate') || s.get('maxAdviceDate')) {
              const defaultMinHour = s.get('minAdviceDate') ? s.get('minAdviceDate').getHours() : 0;
              const defaultMaxHour = s.get('maxAdviceDate') ? s.get('maxAdviceDate').getHours() : 0;
              stepCopy.setProperties({
                minAdviceDate: defaultMinAdviceDate.add(defaultMinHour, 'hours').toDate(),
                maxAdviceDate: defaultMaxAdviceDate.add(defaultMaxHour, 'hours').toDate()
              });
            }
          } else {
            const tt = await stepCopy.get('transport.transportType');
            const arrivalDate = await this.get('addressUnloadTimeService').findArrivalDate(stepCopy.get('address'), tt);
            stepCopy.setProperties({
              arrivalDate
            });
          }

          const indexesForStep = indexes.filterBy('step.id', s.get('id'));
          self.copyIndexes(indexesForStep, transportCopy, stepCopy);
          const stepPackageTypes = s.get('stepPackageType');

          if (stepPackageTypes) {
            self.copyStepPackageType(stepPackageTypes, stepCopy);
          }
        });
        transportCopy.notifyPropertyChange('showContractorSection');
      });
    },

    copyIndexes(indexesForStep, transportCopy, stepCopy) {
      indexesForStep.forEach(index => {
        const indexCopyPromise = index.copy(true, {
          ignoreAttributes: ['transport', 'step', 'quantities']
        });
        indexCopyPromise.then(indexCopy => {
          indexCopy.setProperties({
            transport: transportCopy,
            step: stepCopy
          });
          index.get('quantities').forEach(iq => {
            const quantityCopyPromise = iq.copy(true, {
              ignoreAttributes: ['index']
            });
            quantityCopyPromise.then(quantityCopy => {
              quantityCopy.setProperties({
                index: indexCopy
              });
            });
          });
        });
      });
    },

    copyStepPackageType(stepPackageTypes, stepCopy) {
      stepPackageTypes.forEach(spt => {
        const sptPromise = spt.copy(true, {
          ignoreAttributes: ['step']
        });
        sptPromise.then(sptCopy => {
          sptCopy.setProperties({
            step: stepCopy
          });
        });
      });
    },

    copyCustomFields(originalTransport, transportCopy) {
      originalTransport.get('customFields').forEach(cf => {
        if (cf.get('definition.visibleDuringTransportCreation')) {
          cf.copy(true, {
            ignoreAttributes: ['id', 'transport']
          }).then(cfCopy => {
            cfCopy.setProperties({
              transport: transportCopy
            });
          });
        }
      });
    },

    copyTransportPackages(originalTransport, transportCopy) {
      originalTransport.get('transportPackages').filterBy('id').forEach(p => {
        p.copy(true, {
          ignoreAttributes: ['transport']
        }).then(np => {
          np.set('transport', transportCopy);
          transportCopy.get('transportPackages').pushObject(np);
        });
      });
    }

  });

  _exports.default = _default;
});