define("apollo/pods/components/custom-auto-complete/component", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let focusOutEvent;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    sessionAccount: Ember.inject.service('session-account'),
    highlightIndex: -1,
    escapedChars: Ember.A([40, 38, 13]),
    visibility: Ember.String.htmlSafe('display: none;'),
    inputClass: '',
    inputClazz: Ember.computed(function () {
      return 'typeahead form-control ' + this.get('inputClass');
    }),
    classNames: Ember.A(['custom-auto-complete']),
    layoutName: 'components/custom-auto-complete',
    allowEnteringNewValue: false,
    defaultMax: 10,
    KEYCODES: Object.freeze({
      ENTER: 13,
      TAB: 9,
      ARROW_UP: 38,
      ARROW_DOWN: 40
    }),
    max: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('AUTO_COMPLETE_MAX') || this.get('defaultMax');
    }),
    // eslint-disable-next-line ember/no-observers
    queryInputChanged: Ember.observer('inputVal', function () {
      Ember.run.debounce(this, this.getOptionsFromServer, 500);
    }),

    getOptionsFromServer() {
      const self = this;
      const inputVal = this.get('inputVal') || '';
      const data = {
        query: inputVal,
        field: this.field,
        type: this.type,
        autocomplete: true,
        companyIds: this.companyIds,
        companyId: this.companyId,
        max: this.get('max'),
        showPrincipalCompanies: this.showPrincipalCompanies,
        carrierId: this.carrierId
      };
      const dataOptions = this.getDataOptions ? this.getDataOptions() : null;

      if (dataOptions) {
        Object.assign(data, dataOptions);
      } // TODO :: apollo-api-service


      _jquery.default.ajax({
        type: 'GET',
        url: _environment.default.serverURL + this.urlAddress,
        data,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('sessionAccount').get('token'));
        }
      }).then(function (resp) {
        self.set('options', resp);
      });
    },

    dropDownStyle: Ember.computed('visibility', function () {
      return 'visibility: ' + this.get('visibility');
    }),
    click: function () {
      if (this.$().find('input').is(':disabled') || this.get('asPlainTemplate')) {
        console.debug('Element is disabled - not showing options.');
        return;
      }

      if (typeof this.get('selectedValue') === 'undefined') {
        this.set('inputVal', '');
      }

      Ember.run.debounce(this, this.getOptionsFromServer, 500);
      this.set('visibility', Ember.String.htmlSafe('display: block;'));
    },
    keyUp: function (event) {
      if (event.keyCode === 27) {
        this.set('visibility', Ember.String.htmlSafe('display: none;'));
      } else if (this.escapedChars.indexOf(event.keyCode) === -1) {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
        this.set('inputVal', (0, _jquery.default)(event.target).val());
      }
    },
    focusIn: function () {
      this.set('selectedFromList', false);

      if (this.get('visibility') === 'display:none;') {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
      }
    },
    focusOut: function () {
      clearTimeout(focusOutEvent);
      const self = this;

      const func = function () {
        if (self.isDestroyed) {
          return;
        }

        self.set('visibility', Ember.String.htmlSafe('display: none;'));

        if (self.get('selectedFromList')) {
          return;
        }

        const value = this.get('selectedValue');
        const optionsToMatch = this.get('optionsToMatch') || [];
        const prevValue = self.get('attrs.selectedValue');

        if (self.get('allowEnteringNewValue')) {
          console.debug('This field allows entering new values..');
          self.sendAction('newValueEntered', value);
        } else if (optionsToMatch.indexOf(value) === -1) {
          if (value && value !== prevValue) {
            console.log('Adding new option is disallowed. Clearing');
            self.sendAction('clearSelection');
            self.set('inputVal', null);
            self.set('selectedValue', null);
          } else if (!value) {
            console.debug('User has removed value - we\'re removing selection..');
            self.sendAction('clearSelection');
          }
        }
      };

      focusOutEvent = Ember.run.later(this, func, 200);
    },
    keyDown: function (event) {
      const {
        keyCode
      } = event;
      const KEYCODES = this.get('KEYCODES');

      const container = this._getSuggestionsContainer(event);

      if (keyCode === KEYCODES.ENTER) {
        event.preventDefault();
      }

      const ENTER_KEY_CODE = 13;

      if (event.keyCode === ENTER_KEY_CODE) {
        event.preventDefault();
      }

      if (!this._isVisible(container)) {
        this._showSuggestions();

        return;
      }

      switch (keyCode) {
        case KEYCODES.ARROW_DOWN:
          this.highlight('down', event);
          break;

        case KEYCODES.ARROW_UP:
          this.highlight('up', event);
          break;

        case KEYCODES.ENTER:
        case KEYCODES.TAB:
          this._handleSelection();

          break;
      }
    },

    _getSuggestionsContainer(event) {
      return event.target.closest('.typeahead-wrap').querySelector('.tt-suggestions');
    },

    _isVisible(element) {
      if (!element) return false;
      const style = window.getComputedStyle(element);
      return style.display !== 'none';
    },

    _showSuggestions() {
      this.set('visibility', Ember.String.htmlSafe('display: block;'));
    },

    _hideSuggestions() {
      this.set('visibility', Ember.String.htmlSafe('display: none;'));
    },

    _handleSelection() {
      const selectableSuggestion = this.get('selectableSuggestion');

      if (!Ember.isBlank(selectableSuggestion)) {
        this.send('selectItem', selectableSuggestion);

        this._hideSuggestions();

        return;
      }

      const value = this.get('selectedValue');
      const optionsToMatch = this.get('optionsToMatch');

      if (optionsToMatch.includes(value)) {
        this.set('selectedFromList', true);

        this._hideSuggestions();
      }
    },

    highlight: function (direction, event) {
      let newHighlightIndex = -1;

      if (direction === 'down') {
        newHighlightIndex = this.highlightIndex + 1;
      } else if (this.highlightIndex > 0) {
        newHighlightIndex = this.highlightIndex - 1;
      }

      if (newHighlightIndex < this.get('suggestions').length) {
        if (this.highlightIndex > -1) {
          const currentResult = this.get('suggestions').objectAt(this.highlightIndex);
          Ember.set(currentResult, 'highlight', false);
        }

        this.set('highlightIndex', newHighlightIndex);

        if (this.highlightIndex > -1) {
          const nextResult = this.get('suggestions').objectAt(this.highlightIndex);
          Ember.set(nextResult, 'highlight', true);
          this.set('selectableSuggestion', nextResult);
        }
      }

      if (newHighlightIndex > -1 && newHighlightIndex < this.get('suggestions.length')) {
        const $container = (0, _jquery.default)(event.target).parent('.typeahead-wrap').find('.tt-suggestions');
        const $elementToScrollTo = (0, _jquery.default)($container.find('.autocomplete-result')[newHighlightIndex]);
        $container.scrollTop($elementToScrollTo.offset().top - $container.offset().top + $container.scrollTop() - 5);
      }
    }
  });

  _exports.default = _default;
});