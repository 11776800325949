define("apollo/pods/components/table-column-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    transportService: Ember.inject.service('transport-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    tableColumns: Ember.computed('tableColumn.[]', function () {
      return this.get('store').peekAll('tableColumn');
    }),
    transportType: Ember.computed('transportType', function () {
      return this.get('transportType');
    }),
    customFields: Ember.computed('transportType.sortedFieldDefinitions.@each.canBeViewed', function () {
      return this.get('transportType.sortedFieldDefinitions').filterBy('canBeViewed');
    }),
    labelForTransportSupervisor: Ember.computed('tableColumn.[]', function () {
      const translationKey = this.get('transportType.transportSupervisorTranslationKey');

      if (translationKey) {
        return this.get('intl').t('transportSupervisorTranslationKeys.' + translationKey);
      }

      return this.get('intl').t('transportSupervisorTranslationKeys.CONTACT_PERSON');
    }),
    labelForWorkerPhoneNumber: Ember.computed('tableColumn.[]', 'transportType.id', function () {
      const translationKey = this.get('transportType.workerFieldTranslationKey');

      if (translationKey) {
        return this.get('intl').t('worker.phoneNumber.' + translationKey);
      }

      return this.get('intl').t('worker.phoneNumber.WORKERS');
    }),
    filteredColumnsAccessCommands: Ember.computed('columnsAccessCommands.[]', 'roleGroup.id', 'transportType.id', function () {
      return this.get('columnsAccessCommands').filter(transportTypeRoleColumn => {
        return transportTypeRoleColumn.get('transportType.id') === this.get('transportType.id') && transportTypeRoleColumn.get('roleGroup.id') === this.get('roleGroup.id');
      });
    }),
    availableColumns: Ember.computed('filteredColumnsAccessCommands.@each.tableColumn', function () {
      return this.get('filteredColumnsAccessCommands').map(columnsAccessCommands => columnsAccessCommands.get('tableColumn'));
    }),
    availableCustomFieldColumns: Ember.computed('filteredColumnsAccessCommands.@each.customFieldDefinition', function () {
      return this.get('filteredColumnsAccessCommands').map(columnsAccessCommands => columnsAccessCommands.get('customFieldDefinition'));
    }),
    availableColumnIds: Ember.computed('availableColumns.@each.id', function () {
      return this.get('availableColumns').map(c => c.get('id')).filter(val => val);
    }),
    availableCustomFieldsIds: Ember.computed('availableCustomFieldColumns.@each.id', function () {
      return this.get('availableCustomFieldColumns').map(cf => cf.get('id')).filter(val => val);
    }),
    _unloadTransports: function (transportType) {
      // Pola formularza widoczne w tabeli wysyłane są w mapie transportów z serwera.
      // Stąd po włączeniu widoczności musimy usunąć ze store wszystkie załadowane transporty -
      // tak, by zostały ponowne pobrane z serwera po powrocie do tabeli.
      //
      // Jeżeli użytkownik wyłącza widoczność, nie musimy czyścić transportów -
      // kolumna wybranego pola nie zostanie wyświetlona.
      if (this.get('avavailableCustomFieldsIds') === 0) {
        return;
      }

      console.debug(`Visibility in table has been turned on for one or more custom field definitions.
                        We're removing from the store all transports with the type ${transportType.get('name')}..`);
      this.get('transportService').unloadTransports(transportType);
    },
    actions: {
      toggleTableColumn: function (tc) {
        const tableColumn = this.get('availableColumns').find(ac => ac.get('id') === tc.get('id'));

        if (tableColumn !== undefined) {
          this.get('availableColumns').removeObject(tableColumn);
        } else {
          this.get('availableColumns').addObject(tc);
        }
      },
      toggleCFColumn: function (cf) {
        const customField = this.get('availableCustomFieldColumns').find(acf => acf.get('id') === cf.get('id'));

        if (customField !== undefined) {
          this.get('availableCustomFieldColumns').removeObject(customField);
        } else {
          this.get('availableCustomFieldColumns').addObject(cf);
        }
      },
      save: async function () {
        const self = this;
        const response = await this.get('apolloApiService').callApolloApi(this.get('apolloApiService').APOLLO_API.TABLE_COLUMNS.SAVE, null, {
          body: JSON.stringify({
            tableColumns: self.get('availableColumnIds'),
            customFields: self.get('availableCustomFieldsIds'),
            roleGroup: self.get('roleGroup.id'),
            transportType: self.get('transportType.id')
          })
        });

        if (response.ok) {
          self.set('successMessage', `${self.get('intl').t('common.saveSuccessMessage') + '. ' + self.get('intl').t('common.saveSuccessAdditionalMessage')}`);

          this._unloadTransports(this.get('transportType'));
        } else {
          const error = await response.json();
          self.set('successMessage', null);
          self.set('errors', error);
        }
      }
    }
  });

  _exports.default = _default;
});