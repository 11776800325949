define("apollo/pods/transport-type/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    attrs: Object.freeze({
      defaultAmountOfCover: {
        serialize: 'records',
        deserialize: 'id'
      },
      priceListSetting: {
        serialize: 'none',
        deserialize: 'records'
      }
    })
  });

  _exports.default = _default;
});