define("apollo/pods/periodic-transport/model", ["exports", "ember-data", "apollo/pods/base-recurring-transport/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.BaseRecurringTransportModel.extend({
    internalIndex: _emberData.default.attr('number'),
    dateOfAdvice: _emberData.default.attr('date'),
    validations: { ..._model.BaseRecurringTransportValidations,
      startDate: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            const dateOfAdvice = model.get('dateOfAdvice');
            const formattedStartDate = moment(value).format('YYYY-MM-DD');
            return dateOfAdvice === formattedStartDate;
          },
          message: function (key, value, model) {
            return model.get('intl').t('periodicTransport.errors.windowMustBeInFirstDayOfPeriodicTransport');
          }
        }]
      }
    }
  });

  _exports.default = _default;
});