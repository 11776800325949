define("apollo/pods/packaging/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BasePackagingModel = _exports.BasePackagingModelValidations = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const BasePackagingModelValidations = {
    packageType: {
      presence: {
        message: 'blankSelect'
      }
    },
    length: {
      custom: [{
        validation: function (key, value, model) {
          if (!value) {
            return false;
          }

          const formattedValue = Number(value.toString().replace(',', '.'));

          if (formattedValue) {
            value = formattedValue;
            model.set(key, value);
          }

          if (value < 1 || value > 9999999) {
            return false;
          }

          return /^[0-9]*[.|,]?[0-9]*$/.test(value);
        },
        message: 'positiveNumber'
      }]
    },
    width: {
      custom: [{
        validation: function (key, value, model) {
          if (!value) {
            return false;
          }

          const formattedValue = Number(value.toString().replace(',', '.'));

          if (formattedValue) {
            value = formattedValue;
            model.set(key, value);
          }

          if (value < 1 || value > 9999999) {
            return false;
          }

          return /^[0-9]*[.|,]?[0-9]*$/.test(value);
        },
        message: 'positiveNumber'
      }]
    },
    height: {
      custom: [{
        validation: function (key, value, model) {
          if (!value) {
            return false;
          }

          const formattedValue = Number(value.toString().replace(',', '.'));

          if (formattedValue) {
            value = formattedValue;
            model.set(key, value);
          }

          if (value < 1 || value > 9999999) {
            return false;
          }

          return /^[0-9]*[.|,]?[0-9]*$/.test(value);
        },
        message: 'positiveNumber'
      }]
    },
    weight: {
      custom: [{
        validation: function (key, value, model) {
          if (!value) {
            return false;
          }

          const formattedValue = Number(value.toString().replace(',', '.'));

          if (formattedValue) {
            value = formattedValue;
            model.set(key, value);
          }

          if (value < 0.001 || value > 999999999999) {
            return false;
          }

          return /^[0-9]*[.|,]?[0-9]*$/.test(value);
        },
        message: 'positiveNumber'
      }]
    },
    quantity: {
      custom: [{
        validation: function (key, value, model) {
          if (!value) {
            return false;
          }

          const formattedValue = Number(value.toString().replace(',', '.'));

          if (formattedValue) {
            value = formattedValue;
            model.set(key, value);
          }

          if (value < 1 || value > 9999999) {
            return false;
          }

          return /^\d+$/.test(value);
        },
        message: 'positiveInteger'
      }]
    }
  };
  _exports.BasePackagingModelValidations = BasePackagingModelValidations;
  const BasePackagingModel = Model.extend(_modelValidator.default, {
    quantity: attr('number'),
    packageType: belongsTo('packageType'),
    enabled: attr('boolean'),
    required: attr('boolean'),
    loadStep: belongsTo('step'),
    unloadStep: belongsTo('step'),
    length: attr('number'),
    width: attr('number'),
    height: attr('number'),
    weight: attr('number'),
    description: attr('string'),
    validations: { ...BasePackagingModelValidations
    }
  });
  _exports.BasePackagingModel = BasePackagingModel;
  var _default = {
    BasePackagingModel,
    BasePackagingModelValidations
  };
  _exports.default = _default;
});