define("apollo/pods/components/price-list/routes-table/route/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    _openModal: async function (routeOffer) {
      const route = await routeOffer.get('route');
      const truckTypes = await this.get('store').query('truck-type', {
        transportTypeId: route.get('transportType.id')
      });
      const model = {
        routeOffer,
        route,
        truckTypes
      };
      this.set('action', 'showModal');
      this.sendAction('action', 'price-list.modals.rate-form', model);
    },
    actions: {
      toggleOffers(route) {
        route.toggleProperty('hasVisibleOffers');
      },

      showRouteForm(route) {
        console.log(`Wyświetlamy formularz trasy ${route.get('id')}...`);
        this.set('action', 'showModal');
        this.sendAction('action', 'routes.modals.route-form.show', route);
      },

      async openAddNewRateModal(route) {
        const routeOffer = this.get('store').createRecord('route-offer', {
          route
        });
        await this._openModal(routeOffer);
      },

      async openEditRateModal(routeOffer) {
        await this._openModal(routeOffer);
      },

      deleteRoute(route) {
        route.deleteRecord();
        route.save();
      }

    }
  });

  _exports.default = _default;
});