define("apollo/pods/route/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    creator: belongsTo('user', {
      async: true
    }),
    currency: belongsTo('currency', {
      async: true
    }),
    principalCompany: belongsTo('company', {
      async: true
    }),
    transportType: belongsTo('transportType', {
      async: true
    }),
    routeAddresses: hasMany('routeAddress', {
      async: true
    }),
    routeCustomFields: hasMany('routeCustomField', {
      async: true
    }),
    routeOffers: hasMany('routeOffer', {
      async: true
    }),
    enabled: attr('boolean'),
    name: attr('string'),
    description: attr('string'),
    dateCreated: attr('isodate'),
    hasVisibleOffers: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    validations: {
      currency: {
        custom: [{
          validation: function (key, value) {
            return value && value.get('id');
          },
          message: 'blankSelect'
        }]
      },
      description: {
        length: {
          maximum: {
            value: 255,
            message: 'length'
          }
        }
      },
      name: {
        length: {
          maximum: {
            value: 255,
            message: 'length'
          }
        },
        presence: {
          message: 'blank'
        }
      },
      routeAddresses: {
        relations: ['hasMany']
      },
      routeCustomFields: {
        relations: ['hasMany']
      }
    },
    sortedRouteAddresses: Ember.computed('routeAddresses.@each.{idx,address}', function () {
      return this.get('routeAddresses').sortBy('idx');
    }),
    sortedAddresses: Ember.computed('sortedRouteAddresses.@each.{idx,address}', function () {
      return this.get('routeAddresses').getEach('address');
    }),
    loadAddresses: Ember.computed('routeAddresses.@each.{idx,address}', 'sortedRouteAddresses.@each{stepType,address}', function () {
      return this.get('routeAddresses').filter(ra => ra.get('stepType') === 'load').getEach('address');
    }),
    unloadAddresses: Ember.computed('routeAddresses.@each.{idx,address}', 'sortedRouteAddresses.@each{stepType,address}', function () {
      return this.get('routeAddresses').filter(ra => ra.get('stepType') === 'unload').getEach('address');
    }),
    customFieldPairs: Ember.computed('routeCustomFields.@each.definition', 'transportType.sortedFieldDefinitions.@each.includedInPriceList', function () {
      const definitions = this.get('transportType.sortedFieldDefinitions').filter(d => d.get('id') && d.get('enabled') && d.get('includedInPriceList'));
      const values = this.get('routeCustomFields');
      return definitions.map(definition => ({
        definition,
        value: values.find(value => value.get('definition.id') === definition.get('id'))
      }));
    }),
    // eslint-disable-next-line
    sortedOffersProperties: ['sortLevel:asc', 'price:asc', 'dateCreated:asc'],
    sortedOffers: Ember.computed.sort('routeOffers', 'sortedOffersProperties'),
    validOffers: Ember.computed('sortedOffers.@each.{enabled,isValidToday}', function () {
      return this.get('sortedOffers').filterBy('enabled').filterBy('isValidToday');
    }),
    bestOffer: Ember.computed('validOffers.{@each.isValidToday,length}', function () {
      return this.get('validOffers').get('firstObject');
    }),
    // Por. `stepCssClass`
    addressesCssClass: Ember.computed('routeAddresses.length', function () {
      const numOfAddresses = this.get('routeAddresses.length');
      const suffix = numOfAddresses < 4 ? numOfAddresses : 'more';
      return `step-columns-${suffix}`;
    }),
    canBeDeleted: Ember.computed('', function () {
      return this.get('transportType.content').findPositiveAuthorityRestrictionsByActionType('ROLE_CAN_DELETE_ROUTE', this.get('transportType.id')).length > 0;
    }),
    updateDisabled: Ember.computed('', function () {
      if (!this.get('id') && this.get('transportType.content').findPositiveAuthorityRestrictionsByActionType('ROLE_CAN_ADD_ROUTE', this.get('transportType.id')).length > 0) {
        return false;
      }

      if (this.get('id') && this.get('transportType.content').findPositiveAuthorityRestrictionsByActionType('ROLE_CAN_EDIT_ROUTE', this.get('transportType.id')).length > 0) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});