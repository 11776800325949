define("apollo/pods/truck-types/modals/form/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    truckTypesIndexController: Ember.inject.controller('truck-types.index'),

    async init() {
      this._super(...arguments);

      this.set('loading', true);
      await this.get('model.truckType.truckTypeMeta');
      await this.get('model.truckType.transportTypes');
      await this.get('model.transportTypes');
      this.set('loading', false);
    },

    modalTitle: Ember.computed('truckType.id', function () {
      return this.get('truckType.id') ? this.get('intl').t('truckType.form.title.edit') : this.get('intl').t('truckType.form.title.add');
    }),
    truckType: Ember.computed('model.truckType', function () {
      return this.get('model.truckType');
    }),
    transportTypes: Ember.computed('model.transportTypes.[]', function () {
      return this.get('model.transportTypes');
    }),
    truckTypeMeta: Ember.computed('truckType.truckTypeMeta.[]', function () {
      return this.get('truckType.truckTypeMeta');
    }),
    proxiedTransportTypes: Ember.computed('truckType.transportTypes', 'transportTypes', function () {
      const properTransportTypesList = this.get('truckType.id') ? this.get('truckType.transportTypes') : [];
      return this.get('transportTypes').map(tt => {
        return Ember.ObjectProxy.create({
          content: tt,
          checked: properTransportTypesList.getEach('id').includes(tt.get('id'))
        });
      });
    }),
    actions: {
      save() {
        const self = this;
        const truckType = this.get('truckType');
        const truckTypeMeta = this.get('truckType.truckTypeMeta');
        truckType.set('transportTypes', this.get('proxiedTransportTypes').filterBy('checked', true).map(tt => tt.content));
        const isTruckTypeValid = truckType.validate();
        const isTruckTypeMetaValid = truckTypeMeta ? truckTypeMeta.validate() : true;

        if (!isTruckTypeValid || !isTruckTypeMetaValid) {
          this.set('errors', []);
          return;
        }

        truckType.save().then(() => {
          self.set('errors', []);
          self.send('refreshRoute');
          self.send('hideModal');
        }).catch(response => {
          console.debug(response);
          response.errors ? this.set('errors', response.errors) : this.set('errors', [this.get('intl').t('common.defaultError')]);
        });
      },

      cancel() {
        this.get('truckType').rollbackAttributes();
        this.get('truckType.truckTypeMeta').rollbackAttributes();
        this.send('hideModal');
      },

      disable() {
        const self = this;
        const truckType = this.get('truckType');
        const msg = self.get('intl').t('truckType.form.disabledTruckType');
        truckType.set('enabled', false);
        truckType.save().then(() => {
          console.log(`Poprawanie wyłączyliśmy typ pojazdu ${truckType}`);
          self.get('truckTypesIndexController').set('successMessage', msg);
          self.set('errors', []);
          self.send('refreshRoute');
          self.send('hideModal');
        }).catch(response => {
          console.debug(response);
          response.errors ? this.set('errors', response.errors) : this.set('errors', [this.get('intl').t('common.defaultError')]);
        });
      }

    }
  });

  _exports.default = _default;
});