define("apollo/pods/components/auction/offers-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    operatorService: Ember.inject.service('operator-service'),
    portService: Ember.inject.service('port-service'),
    isSeaAuction: Ember.computed.reads('auction.transport.transportType.seaTransports'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    operatorLabel: Ember.computed('isSeaAuction', function () {
      return this.operatorService.getTranslation(this.isSeaAuction);
    }),
    loadingPortLabel: Ember.computed('isSeaAuction', function () {
      return this.portService.getTranslation(this.isSeaAuction, 'loading');
    }),
    unloadingPortLabel: Ember.computed('isSeaAuction', function () {
      return this.portService.getTranslation(this.isSeaAuction, 'unloading');
    }),
    showPorts: Ember.computed('auctionSetting.showPorts', function () {
      return this.get('auctionSetting.showPorts');
    }),
    showOperator: Ember.computed('auctionSetting.showOperator', function () {
      return this.get('auctionSetting.showOperator');
    }),
    auctionSetting: Ember.computed('auction.transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('auction.transport.transportType.id'));
    }),
    showTransitTime: Ember.computed('auctionSetting', function () {
      if (!this.get('auctionSetting')) {
        return false;
      }

      return this.get('auctionSetting.showTransitTime');
    }),
    showDescriptionSection: Ember.computed('auctionSetting', function () {
      if (!this.get('auctionSetting')) {
        return true;
      }

      return this.get('auctionSetting.showDescriptionSection');
    }),
    showPassToCarrierButton: Ember.computed('auctionSetting', 'auction.{transport.id,transport.carrierCompany.id,offers.@each.enabled,offers.length,transport.transaction.id}', function () {
      if (!this.get('auctionSetting')) {
        return true;
      }

      const showPassToCarrierButton = this.get('auctionSetting.showPassToCarrierButton');
      const transportHasNoCarrier = !this.get('auction.transport.carrierCompany.id');
      const passingActionCanBePerformed = this.get('auction.transport.content').actionCanBePerformed('PASS_TRANSPORT');
      const transportHasTransactionAlready = this.get('auction.transport.transaction.id');
      return transportHasNoCarrier && showPassToCarrierButton && passingActionCanBePerformed && !transportHasTransactionAlready;
    }),
    showPreferredOfferSection: Ember.computed('auctionSetting.requireSelectedOfferAcceptance,canMarkPreferredOffer', function () {
      return this.get('auctionSetting.requireSelectedOfferAcceptance') && this.get('sessionAccount').get('isPrincipal');
    }),
    canMarkPreferredOffer: Ember.computed(function () {
      return this.get('authorityCheckerService').actionCanBePerformed('ROLE_CAN_MARK_PREFERRED_OFFER', this.get('auction.transport'));
    }),
    canRevokeOffer: Ember.computed('showCancelOfferSection,auction.{transport,currentOfferForLoggedCompany.formattedPrice}', function () {
      const hasAuthorityToRevokeOffer = this.get('authorityCheckerService').actionCanBePerformed('ROLE_CAN_REVOKE_OFFER', this.get('auction.transport'));

      if (!hasAuthorityToRevokeOffer) {
        return false;
      }

      const currentPrice = this.get('auction.currentOfferForLoggedCompany.formattedPrice');
      return currentPrice && hasAuthorityToRevokeOffer;
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },

      cancelOffer(offer) {
        offer.setSoftDeleted().save().then(() => {
          this.set('errors', Ember.A([]));
        }).catch(error => {
          offer.unsetSoftDeleted();
          console.error(error);
          alert(this.get('intl').t('common.defaultError'));
        });
      },

      async markAsPreferred(offer) {
        try {
          const path = this.get('apolloApiService').APOLLO_API.OFFERS.MARK_AS_PREFERRED;
          const response = await this.get('apolloApiService').callApolloApi(path, {
            id: offer.get('id')
          });

          if (response.ok) {
            offer.set('isPreferred', true);
          }
        } catch (error) {
          console.error(error);
        }
      }

    }
  });

  _exports.default = _default;
});