define("apollo/services/port-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    getAirPorts: function () {
      return this.store.query('port', {
        portType: 'AIR'
      });
    },
    getSeaPorts: function () {
      return this.store.query('port', {
        portType: 'SEA'
      });
    },
    getTranslation: function (isSeaAuction, type) {
      if (isSeaAuction) {
        return this.get('intl').t('offer.' + type + 'SeaPort');
      }

      return this.get('intl').t('offer.' + type + 'AirPort');
    }
  });

  _exports.default = _default;
});