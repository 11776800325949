define("apollo/pods/workflow/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    tasks: hasMany('task', {
      async: true
    }),
    transportType: belongsTo('transportType', {
      async: true
    }),
    name: attr('string'),
    finalStageName: attr('string'),
    finalStageNameEn: attr('string'),
    finalColor: attr('string'),
    dateCreated: attr('date'),
    deliveryWorkflow: attr('boolean'),
    sortedTasks: function () {
      return this.get('tasks').sortBy('taskGroupIdentifier', 'idxInGroup').filterBy('enabled');
    }.property('tasks.@each.idxInGroup'),
    localisedFinalStageName: function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('finalStageName', 'finalStageNameEn');
      return this.get(propertyName);
    }.property('finalStageName', 'finalStageNameEn')
  });

  _exports.default = _default;
});