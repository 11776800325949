define("apollo/pods/components/delivery-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fLozhnXo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"expandable-panel\",null,[[\"title\",\"componentName\",\"objectId\"],[[23,[\"deliveryTitle\"]],\"transport-delivery-section\",[23,[\"delivery\",\"id\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"transport/delivery-section\",null,[[\"transport\",\"delivery\",\"disabled\",\"isInTransport\",\"transportType\",\"packageTypes\"],[[23,[\"transport\"]],[23,[\"delivery\"]],[23,[\"delivery\",\"isFromSap\"]],true,[23,[\"transport\",\"transportType\"]],[23,[\"packageTypes\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/delivery-panel/template.hbs"
    }
  });

  _exports.default = _default;
});