define("apollo/pods/components/show-warehouse-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleIsInWarehouse(step) {
        const isInWarehouse = step.get('isInWarehouse');
        step.set('isInWarehouse', !isInWarehouse);
      }

    }
  });

  _exports.default = _default;
});