define("apollo/pods/transport-order-setting/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    DEFAULT_ADDITIONAL_DETAILS_FONT_SIZE: Object.freeze(12),
    transportType: belongsTo('transportType'),
    freightSufix: attr('string'),
    showOnlyStartingDate: attr('boolean'),
    shouldGenerateTransportOrder: attr('boolean'),
    transportOrderCustomFields: attr('string'),
    additionalDetailsFontSize: attr('number', {
      defaultValue: function () {
        return 12;
      }
    }),
    validations: {
      additionalDetailsFontSize: {
        numericality: {
          lessThanOrEqualTo: 32,
          greaterThanOrEqualTo: 1
        }
      }
    }
  });

  _exports.default = _default;
});