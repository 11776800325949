define("apollo/pods/components/table-columns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y9HuGFT/",
    "block": "{\"symbols\":[\"tt\"],\"statements\":[[4,\"if\",[[23,[\"showTableColumnSection\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"transportTypes\"]]],null,{\"statements\":[[4,\"expandable-panel\",null,[[\"objectId\",\"componentName\",\"title\",\"titleClass\",\"onOpen\",\"onClose\",\"defaultOpen\"],[[22,1,[\"id\"]],[27,\"concat\",[[22,1,[\"name\"]],\"-panel\"],null],[22,1,[\"name\"]],\"bold\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"isOpened\"]]],null],true],null],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"isOpened\"]]],null],false],null],false]],{\"statements\":[[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isOpened\"]]],null,{\"statements\":[[0,\"                    \"],[1,[27,\"table-column-settings\",null,[[\"roleGroup\",\"transportType\",\"columnsAccessCommands\"],[[23,[\"roleGroup\"]],[22,1,[]],[23,[\"columnsAccessCommands\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/table-columns/template.hbs"
    }
  });

  _exports.default = _default;
});