define("apollo/pods/routes/modals/route-form/template/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    routeLabel: Ember.computed('route.transportType.label', function () {
      return (this.get('route.id') ? `${this.get('intl').t('route.editRoute')}: ${this.get('route.id')}` : this.get('intl').t('route.newRoute')) + ' - ' + this.get('route.transportType.label');
    }),
    canCreateTransportFromRoute: Ember.computed('', function () {
      return this.get('authorityCheckerService').findPositiveAuthorityRestrictionsByActionType('ROLE_CREATE_TRANSPORT_FROM_ROUTE', this.get('route.transportType.id'));
    }),
    actions: {
      createTransport() {
        this.createTransport();
      },

      saveRoute() {
        this.saveRoute();
      },

      editRoute() {
        this.editRoute();
      },

      close() {
        this.close();
      },

      cancel() {
        this.cancel();
      }

    }
  });

  _exports.default = _default;
});