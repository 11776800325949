define("apollo/pods/components/planning/modals/add-transport/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    store: service(),
    deliveryService: service(),
    intl: service(),
    basicTransport: Ember.computed('model.basicTransport', function () {
      return this.get('model.basicTransport');
    }),
    availableTransportTypes: Ember.computed('model.availableTransportTypes', function () {
      return this.get('model.availableTransportTypes');
    }),
    showRouteTemplate: Ember.computed('transportType.priceListSetting.showRouteTemplate', function () {
      return this.get('transportType.priceListSetting.showRouteTemplate');
    }),
    routes: Ember.computed('model.availableRoutes', function () {
      return this.get('model.availableRoutes').sortBy('name');
    }),
    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    }),
    customFields: Ember.computed('model.customFields', function () {
      const definitions = this.get('model.customFields');
      const pairs = [];

      for (let i = 0; i < definitions.get('length'); i++) {
        const definition = definitions.objectAt(i);
        const transport = this.get('basicTransport');
        const value = this.store.createRecord('customFieldValue', {
          definition,
          transport,
          option: definition.get('defaultOption')
        });
        transport.get('customFields').addObject(value);
        pairs[i] = {
          value,
          definition
        };
      }

      return pairs;
    }),
    unloadObsoleteObjectsAndCloseModal: async function () {
      this.get('customFields').forEach(cf => {
        if (cf.value) {
          cf.value.destroyRecord();
        }
      });
      this.send('hideModal');
    },
    actions: {
      onRouteTemplateSelect(route) {
        this.set('basicTransport.routeTemplate', route);
      },

      async save() {
        if (!this.get('basicTransport').validate({
          only: ['routeTemplate', 'customFields']
        })) {
          return;
        }

        await this.deliveryService.createNewBasicTransport(this.get('basicTransport'));
        this.send('refreshModel');
        this.set('errors', Ember.A([]));
        this.unloadObsoleteObjectsAndCloseModal();
      },

      cancel() {
        this.unloadObsoleteObjectsAndCloseModal();
      }

    }
  });

  _exports.default = _default;
});