define("apollo/pods/components/show-warehouse-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZJV9hWre",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"group group-space-between\"],[9],[0,\"\\n        \"],[7,\"span\"],[12,\"class\",[28,[\"center-vertically bold\\n                \",[27,\"if\",[[27,\"eq\",[[23,[\"step\",\"stepTypeName\"]],\"load\"],null],\"margin-left-big\"],null],\"\\n            \",[27,\"if\",[[27,\"eq\",[[23,[\"step\",\"stepTypeName\"]],\"unload\"],null],\"margin-right-big\"],null]]]],[12,\"data-field-name\",[28,[[23,[\"step\",\"stepTypeName\"]],\"-warehouse-checkbox-\",[23,[\"step\",\"orderInTransport\"]]]]],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"transport.warehouse\"],null],false],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"disabled\",\"change\"],[\"checkbox\",[23,[\"step\",\"isInWarehouse\"]],[23,[\"step\",\"id\"]],[27,\"action\",[[22,0,[]],\"toggleIsInWarehouse\",[23,[\"step\"]]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/show-warehouse-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});