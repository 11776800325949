define("apollo/services/polling/notification-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    websocketService: Ember.inject.service('websocket'),
    sessionAccount: Ember.inject.service('session-account'),
    sentNotificationService: Ember.inject.service(),
    store: Ember.inject.service(),

    useSockets(webSocketApi) {
      console.debug('Subscribing for notifications...');
      const userId = this.get('sessionAccount.currentUser.id');
      webSocketApi.subscribe('/topic/notify/' + userId, payload => {
        const responseBody = JSON.parse(payload.body);
        this.store.pushPayload({
          'sentNotification': responseBody
        });
        this.sentNotificationService.addToTotalItems(1);
      });
    }

  });

  _exports.default = _default;
});