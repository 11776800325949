define("apollo/pods/components/transports/transports-table/transport-filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    // Używane w hbs.
    cfColumnNames: Ember.computed('customFieldsVisibleInTable.@each.englishName', function () {
      return this.get('customFieldsVisibleInTable').map(cf => cf.get('englishName'));
    }),
    tagName: 'div',
    classNames: Ember.A(['transport-filters', 'row', 'flex-position-start']),
    canMultiArchive: Ember.computed('transport', function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS');
    }),
    actions: {}
  });

  _exports.default = _default;
});