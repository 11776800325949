define("apollo/pods/invitation/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //noinspection NpmUsedModulesInstalled
  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ramps: _emberData.default.hasMany('ramp'),
    queues: _emberData.default.hasMany('queues'),
    partnershipType: _emberData.default.belongsTo('partnershipType', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: null
    }),
    creator: _emberData.default.belongsTo('user', {
      async: true
    }),
    registeredAccount: _emberData.default.belongsTo('user', {
      async: true,
      inverse: 'invitation'
    }),
    personWhoAccepted: _emberData.default.belongsTo('user', {
      async: true
    }),
    personWhoRejected: _emberData.default.belongsTo('user', {
      async: true
    }),
    roleGroup: _emberData.default.belongsTo('roleGroup', {
      async: true
    }),
    userGroup: _emberData.default.belongsTo('userGroup', {
      async: true
    }),
    origin: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    taxIdNumber: _emberData.default.attr('string'),
    country: _emberData.default.belongsTo('country'),
    expirationDate: _emberData.default.attr('date'),
    dateAccepted: _emberData.default.attr('date'),
    dateRejected: _emberData.default.attr('date'),
    dateCreated: _emberData.default.attr('date'),
    lastUpdated: _emberData.default.attr('date'),
    customMessage: _emberData.default.attr('string'),
    availableLanguages: _emberData.default.attr(),
    status: Ember.computed('isAccepted', 'isRejected', 'hasExpired', function () {
      if (this.get('isAccepted')) {
        return this.get('acceptedStatus');
      } else if (this.get('isRejected')) {
        return this.get('rejectedStatus');
      } else if (this.get('hasExpired')) {
        return this.get('expiredStatus');
      } else {
        return this.get('waitingStatus');
      }
    }),
    waitingStatus: Ember.computed('', function () {
      return Ember.Object.create({
        name: this.get('intl').t('common.waiting'),
        idx: 1,
        icon: 'hourglass-start',
        class: ''
      });
    }).readOnly(),
    expiredStatus: Ember.computed('expirationDate', function () {
      return Ember.Object.create({
        name: this.get('intl').t('invitation.hasExpired'),
        idx: 2,
        icon: 'clock',
        class: 'errors'
      });
    }).readOnly(),
    acceptedStatus: Ember.computed('dateAccepted', function () {
      return Ember.Object.create({
        name: this.get('intl').t('common.accepted'),
        idx: 3,
        icon: 'check-circle',
        class: 'success'
      });
    }).readOnly(),
    rejectedStatus: Ember.computed('dateRejected', function () {
      return Ember.Object.create({
        name: this.get('intl').t('common.rejected'),
        idx: 4,
        icon: 'fa-frown',
        class: 'errors'
      });
    }).readOnly(),
    isAccepted: Ember.computed('dateAccepted', function () {
      return !!this.get('dateAccepted');
    }).readOnly(),
    isRejected: Ember.computed('dateRejected', function () {
      return !!this.get('dateRejected');
    }).readOnly(),
    hasExpired: Ember.computed('expirationDate', function () {
      return moment(this.get('expirationDate')) < moment();
    }).readOnly(),
    isHandled: Ember.computed('dateAccepted', 'dateRejected', function () {
      return !!this.get('dateAccepted') || !!this.get('dateRejected');
    }).readOnly(),
    isToJoinCompany: Ember.computed('partnershipType.id', function () {
      return !this.get('partnershipType.id');
    }).readOnly(),
    type: Ember.computed('partnershipType.name', function () {
      return this.get('isToJoinCompany') ? 'JOIN_COMPANY' : this.get('partnershipType').get('name');
    }).readOnly(),
    typeTranslationKey: Ember.computed('partnershipType.translationLabel', function () {
      return this.get('isToJoinCompany') ? 'JOIN_COMPANY' : this.get('partnershipType.translationLabel');
    }).readOnly(),
    validations: {
      email: {
        custom: [{
          validation: function (key, value) {
            return value;
          },
          message: 'blank'
        }, {
          validation: function (key, value, model) {
            const domain = value ? value.replace(/.*@/, "") : "";
            const allowedDomains = model.get('allowedDomains');
            return !allowedDomains || allowedDomains.length === 0 || allowedDomains.map(ad => ad.get('domain')).includes(domain);
          },
          message: function (key, value, model) {
            return model.get('intl').t('company.allowedDomains.emailNotMatch', {
              allowedDomains: model.get('allowedDomains').map(ad => ad.get('domain')).join(", ")
            });
          }
        }]
      },
      roleGroup: {
        custom: [{
          validation: function (key, value) {
            return value.content;
          },
          message: 'blankSelect'
        }]
      },
      taxIdNumber: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('taxIdShouldBeVisible')) {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      country: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('taxIdShouldBeVisible')) {
              return true;
            }

            return value && value.get('id');
          }
        }]
      }
    },
    properPartnershipTypeId: Ember.computed('partnershipType.id', 'currentUserPartnershipTypeId', function () {
      return this.get('partnershipType.id') === undefined ? this.get('currentUserPartnershipTypeId') : // zaproszenie do własnej firmy
      this.get('partnershipType.id');
    }),
    currentUserPartnershipTypeId: Ember.computed('sessionAccount.currentUser.company.partnershipWithPrincipal.partnershipType.id', function () {
      return this.get('sessionAccount.currentUser.company.partnershipWithPrincipal.partnershipType.id');
    }),
    taxIdShouldBeVisible: Ember.computed('isToJoinCompany', function () {
      return !this.get('isToJoinCompany');
    })
  });

  _exports.default = _default;
});