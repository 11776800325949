define("apollo/pods/transports/modals/create-transport/controller", ["exports", "apollo/config/environment", "apollo/mixins/attachment-mixin", "apollo/mixins/advice-utils-mixin", "apollo/mixins/form-utils-mixin", "apollo/mixins/transports-mixin", "apollo/mixins/transport-form-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/checkpoint-mixin", "jquery", "apollo/utils/parsers/numberParsers"], function (_exports, _environment, _attachmentMixin, _adviceUtilsMixin, _formUtilsMixin, _transportsMixin, _transportFormMixin, _persistenceMixin, _checkpointMixin, _jquery, _numberParsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_attachmentMixin.default, _adviceUtilsMixin.default, _checkpointMixin.default, _formUtilsMixin.default, _transportsMixin.default, _transportFormMixin.default, _persistenceMixin.default, {
    // eslint-disable-next-line ember/no-observers
    resetProperties: Ember.observer('model.modelOpenTime', function () {
      console.debug(`Resetujemy ustawienia transportu ${this.get('model.id') ? this.get('model.logString') : '(niezapisanego)'} po otwarciu/zamknięciu okienka z formularzem...`);
      const transport = this.get('model');

      if (transport && transport.set) {
        transport.set('hasCustomFieldsInitialized', false);
      }
    }),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    transportCopyService: Ember.inject.service('transport-copy-service'),
    dateUtils: Ember.inject.service('date-utils'),
    // TODO: Zastąpić przez apolloApiService
    ajaxService: Ember.inject.service('ajax-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    errorService: Ember.inject.service('error-service'),
    languageService: Ember.inject.service('language-service'),
    deliveryService: Ember.inject.service('delivery-service'),
    modalService: Ember.inject.service('modal-service'),
    transportTypeSettingService: Ember.inject.service('transport-type-settings-service'),
    store: Ember.inject.service(),
    addressUnloadTimeService: Ember.inject.service('address-unload-time-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    transportController: Ember.inject.controller('transports/index'),
    routeTransportMapperService: Ember.inject.service(),
    emberDataRecordManager: Ember.inject.service('ember-data.record-manager'),
    init: async function () {
      this._super(...arguments);

      if (this.get('transport.carrierCompanyChooser')) {
        await this.get('transport.carrierCompanyChooser.company');
      }

      Ember.run.schedule('afterRender', this, function () {
        this.send('resizeTextArea');
      });
    },
    errors: Ember.A(),
    excludedLogNames: Ember.A(['transportId', 'stepId']),
    attachments: Ember.A(),
    existingFilesToRemove: Ember.A(),
    showSearchSpotsButton: false,
    showRouteTemplateSelect: Ember.computed('transportType.priceListSetting.showRouteTemplate', function () {
      return this.get('transportType.priceListSetting.showRouteTemplate');
    }),
    truckTypes: Ember.computed('transport.{id,truckTypes.@each.[]}', function () {
      return this.get('transport.truckTypes');
    }),
    canDetachDeliveries: Ember.computed('transport.deliveries.length', function () {
      const canDetachDelivery = this.get('transport').actionCanBePerformed('ROLE_DETACH_DELIVERY');
      return (this.get('transport.deliveries.length') || 0) > 0 && canDetachDelivery;
    }),
    canAddDeliveryInTransport: Ember.computed('model.id', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ROLE_ADD_DELIVERY_IN_TRANSPORT') && this.get('model.id');
    }),
    packageTypes: Ember.computed(function () {
      return this.get('store').peekAll('packageType');
    }),
    showExpandedIndexSection: Ember.computed(function () {
      return this.get('asPlainTemplate') && this.get('transport.indexes.length') > 0;
    }),
    showExpandedCourierPackageSection: Ember.computed(function () {
      return this.get('asPlainTemplate') && this.get('transport.courier-packages.length') > 0;
    }),
    columnsInIndexSection: Ember.computed('showCarriersTable', function () {
      return ['index', 'shortName', 'globalNumber', 'localNumber', 'documentNumber', 'transportNumber', 'quantities', 'company', 'productName', 'indexName', 'notes', 'editionOrDeletion'];
    }),
    canCreateSimilar: Ember.computed('model.id', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ROLE_CREATE_SIMILAR') && this.get('model.id');
    }),
    canGenerateLOPReport: Ember.computed('transport.id', function () {
      return this.get('transport.id') && this.get('sessionAccount').hasRole('ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT');
    }),
    canRestoreOrderFromArchive: Ember.computed('transport.{archived,completed,deleted}', function () {
      return this.get('transport.archived') && !this.get('transport.completed') && !this.get('transport.deleted') && this.get('sessionAccount').hasRole('ROLE_RESTORE_ORDER_FROM_ARCHIVE') && !this.restoreFromArchiveTimePassed();
    }),
    restoreFromArchiveTimePassed: function () {
      const interval = this.get('sessionAccount').getSettingValue('TIME_TO_RESTORE_ORDER_FROM_ARCHIVE');

      if (!interval) {
        return false;
      }

      const archiveDate = this.get('transport.dateOfArchiving');
      const deadline = this.get('dateUtils').calculateDateFromInterval(new Date(archiveDate), interval);
      return moment().isAfter(moment(deadline));
    },
    principalCompanyId: Ember.computed('', function () {
      return localStorage.getItem('principalId');
    }),
    browserOld: Ember.computed('', function () {
      return (0, _jquery.default)('html').is('.old');
    }),
    dateOfAdvice: Ember.computed('transport.transportType.numberOfDaysAddedToFirstStep', function () {
      const numberOfDaysAddedToFirstStep = this.get('transport.transportType.numberOfDaysAddedToFirstStep');
      const numberOfDaysForFirstStep = numberOfDaysAddedToFirstStep || 0;
      return moment().startOf('day').add(numberOfDaysForFirstStep, 'day').format('YYYY-MM-DD');
    }),
    transport: Ember.computed('model', function () {
      return this.get('model');
    }),
    firstStepWithWarehouse: Ember.computed('transport.stepsWithWarehouse.firstObject', function () {
      return this.get('transport.stepsWithWarehouse.firstObject');
    }),
    warehouseLocationDropdownHidden: Ember.computed('firstStepWithWarehouse.warehouseLocations.length', function () {
      const role = this.get('sessionAccount').hasRole('ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION');

      if (role) {
        return false;
      }

      return this.get('firstStepWithWarehouse.warehouseLocations.length') >= 1;
    }),
    shouldSelectWindow: Ember.computed('transport.{id,steps.[]}', 'transportType.requiresTimeWindow', function () {
      if (!this.get('transport.id')) {
        const windowAlreadySelected = this.get('transport.stepsWithWindow.length') ? this.get('transport.stepsWithWindow').every(step => !!step.get('timeWindow')) : false;

        if (windowAlreadySelected) {
          return false;
        }

        return this.get('transportType.requiresTimeWindow');
      }

      const newStepsArr = this.get('transport.steps').filter(step => {
        return !step.get('id') && step.get('ownedByPrincipal');
      }); // Klawisz `Dalej` pokazujemy tylko gdy transport posiada już okno czasowe i dodamy nowy etap w magazynie

      return newStepsArr.get('length') > 0 && this.get('transport.advice');
    }),
    shouldChangeStepWindow: Ember.computed('transport.stepsWithWarehouse.[]', function () {
      return this.get('transport.stepsWithWarehouse').some(step => step.get('didWarehouseChange'));
    }),
    warehouse: Ember.computed('transport.warehouse', function () {
      return this.get('transport.warehouse');
    }),
    transportType: Ember.computed('transport.transportType', function () {
      return this.get('transport.transportType');
    }),
    transportOrMasterTransport: Ember.computed('transport.{advice,transport.masterTransports.firstObject.advice.[]}', function () {
      if (this.get('transport.masterTransports.firstObject.advice.id')) {
        return this.get('transport.masterTransports.firstObject');
      }

      return this.get('transport');
    }),
    showAdviceSectionAsPanel: Ember.computed('transport.advice.id', 'transportType.{adviceSavedWithTransport,requiresTimeWindow}', function () {
      if (this.get('transport.masterTransports.firstObject.advice.id')) {
        return true;
      }

      if (this.get('transportType.adviceSavedWithTransport')) {
        return false;
      }

      return this.get('transport.advice.id') || this.get('transportType.requiresTimeWindow') && !this.get('transportType.adviceSeparatedFromWindow');
    }),
    showWeightSection: Ember.computed('transport.{id,enabledWeights,transportType.authorityRestrictions.@each.authorityRange}', function () {
      return this.get('transport').actionCanBePerformed('ROLE_ACCESS_WEIGHT_SECTION') && this.get('transport.enabledWeights').length > 0;
    }),
    showDiscrepancyReportSection: Ember.computed('transportType.hasDiscrepancyReportEnabled', 'transport.id', function () {
      return this.get('transport.id') && this.get('transportType.hasDiscrepancyReportEnabled');
    }),
    showInspectionSection: Ember.computed('', function () {
      return this.get('transport').actionCanBePerformed('ROLE_ACCESS_TO_INSPECTION_PANEL');
    }),
    showWarehouseSection: Ember.computed('transportType.{hasVisibleSteps,requiresWarehouse,requiresTimeWindow}', function () {
      if (this.get('transportType.requiresWarehouse')) {
        return true;
      }

      return this.get('transportType.requiresTimeWindow') && !this.get('transportType.hasVisibleSteps');
    }),
    showCarrierSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ACCESS_CARRIER_COMPANY_FIELD');
    }),
    showCarrierChooserSection: Ember.computed('transport.carrierCompany.{id,company.id}', function () {
      if (!this.get('sessionAccount.currentCompany.isPrincipal')) {
        return false;
      }

      return this.get('transport.carrierCompany.id') && this.get('transport.carrierCompanyChooser.company.id') !== this.get('principalCompanyId');
    }),
    showServiceSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transportType.allowsService')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ACCESS_SERVICE_COMPANY_FIELD');
    }),
    showWorkerSection: Ember.computed('transport.serviceCompany', function () {
      const transport = this.get('transport');
      return !!this.get('transport').get('serviceCompany.id') && transport.actionCanBePerformed('ROLE_ACCESS_WORKER_FIELD');
    }),
    indexSetting: Ember.computed('transport.transportType.indexSetting', function () {
      return this.get('transport.transportType.indexSetting');
    }),
    shouldShowIndexesSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      const transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('ACCESS_INDEX');
    }),
    shouldShowTransportPackagesSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      const transport = this.get('transport');
      return transport.actionCanBePerformed('ACCESS_PACKAGES') && transport.get('transportType.transportPackagesEnabled');
    }),
    shouldShowCourierPackagesSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      const transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('ACCESS_PACKAGES') && transport.get('transportType.courierPackagesEnabled');
    }),
    shouldDisabledAdditionalDescription: Ember.computed('transport', function () {
      return this.get('transport') && !this.get('transport').actionCanBePerformed('ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION');
    }),
    shouldDisabledDescription: Ember.computed('transport.transportType', 'asPlainTemplate', function () {
      if (this.get('asPlainTemplate') || !this.get('transport')) {
        return true;
      }

      return !this.get('transport').actionCanBePerformed('EDIT_DESCRIPTION_FIELD');
    }),
    showDescriptionField: Ember.computed('transport.transportType', 'asPlainTemplate', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ACCESS_DESCRIPTION_FIELD');
    }),
    showAdditionalDescriptionField: Ember.computed('transport', function () {
      return this.get('transport') && this.get('transport').actionCanBePerformed('ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION');
    }),
    additionalDescriptionValue: Ember.computed('transport.{additionalDescription,transportType.defaultTextForTransportAdditionalDescription}', {
      get() {
        if (this.get('transport.additionalDescription')) {
          return this.get('transport.additionalDescription');
        }

        if (this.get('transport.transportType.defaultTextForTransportAdditionalDescription')) {
          return this.get('transport.transportType.defaultTextForTransportAdditionalDescription');
        }

        return '';
      },

      set(key, value) {
        this.set('transport.additionalDescription', value);
        return value;
      }

    }),

    /**
     * Sekcję kontrahenta trzeba pokazać, jeśli transport go wymaga, ale w formularzu nie są widoczne stepy,
     * albo są widoczne tylko stepy związane z magazynem.
     */
    showContractorSection: Ember.computed('transportType.{hasVisibleSteps,requiresContractor,requiresTimeWindow}', 'transport.contractorSteps.length', function () {
      if (!this.get('transportType.allowsContractor')) {
        return false;
      }

      const hasVisibleContractorSteps = this.get('transport.contractorSteps.length') > 0;
      const hasVisibleSteps = hasVisibleContractorSteps && this.get('transportType.hasVisibleSteps');

      if (this.get('transportType.requiresContractor') && !hasVisibleSteps) {
        return true;
      }

      if (this.get('transportType.requiresTimeWindow') && !hasVisibleSteps) {
        return true;
      }

      return !hasVisibleSteps;
    }),
    shouldShowCouriersSection: Ember.computed('transport.transportType', function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_ACCESS_COURIERS_SECTION');
    }),
    shouldShowCourierInvoiceSection: Ember.computed('transport.transportType', function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_ACCESS_COURIERS_SECTION');
    }),
    showErpIdentifier: Ember.computed('transportType.{requiresErpIdentifier,allowsErpIdentifier}', function () {
      return this.get('transportType.requiresErpIdentifier') || this.get('transportType.allowsErpIdentifier');
    }),
    showAltIdentifier: Ember.computed('transportType.{allowsAltIdentifier,requiresAltIdentifier}', function () {
      return this.get('transportType.allowsAltIdentifier') || this.get('transportType.requiresAltIdentifier');
    }),
    disabledAltIdentifier: Ember.computed('transport.canBeUpdated', 'transportType.altIdentifierIsCreatedAutomatically', function () {
      return !this.get('transport.canBeUpdated') || this.get('transportType.altIdentifierIsCreatedAutomatically');
    }),
    showPrintIcon: Ember.computed('transport.id', function () {
      return this.get('sessionAccount').getSettingValue('ORDER_FORM_PRINTING_ENABLED') === 'true' && this.get('transport.id');
    }),
    showDocumentSection: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM') === 'true';
    }),
    showAdditionalAddFileButton: Ember.computed('', function () {
      return this.get('showDocumentSection') && this.get('sessionAccount').getSettingValue('ADDITIONAL_ADD_FILE_BUTTON') === 'true';
    }),
    warehouseLabel: Ember.computed('', function () {
      const settingName = this.get('languageService').findProperLanguageVersion('WAREHOUSE_LABEL', 'WAREHOUSE_LABEL_EN');
      const setting = this.get('sessionAccount').getSettingValue(settingName);
      return setting || this.get('intl').t('transport.warehouse');
    }).readOnly(),
    assortmentGroupLabel: Ember.computed('', function () {
      this.get('languageService').findProperLanguageVersion('name', 'englishName');
      const key = this.get('languageService').findProperLanguageVersion('ASSORTMENT_GROUP_LABEL', 'ASSORTMENT_GROUP_LABEL_EN');
      return this.get('sessionAccount').getSettingValue(key);
    }),
    assortmentGroups: Ember.computed('transportType', 'transport.warehouse.id', function () {
      const self = this;
      return this.get('store').peekAll('assortmentGroup').filter(ag => {
        return ag.get('transportTypeIds').includes(self.get('transportType.id')) && ag.get('warehouse.id') === self.get('transport.warehouse.id') && ag.get('enabled') === true;
      });
    }),
    showAssortmentGroupSection: Ember.computed('assortmentGroups.[]', function () {
      return this.get('assortmentGroups.length') > 0 && this.get('transport.warehouse.id');
    }),
    hideSaveTransportButton: true,
    // eslint-disable-next-line ember/no-observers
    setHideSaveTransportButton: Ember.observer('model', function () {
      if (this.get('canNotEditBecauseOfOtherContractors')) {
        this.set('hideSaveTransportButton', true);
        return;
      }

      this.set('hideSaveTransportButton', false);
    }),
    // eslint-disable-next-line ember/no-observers
    setRequiresAcceptanceOfTermsOfCondition: Ember.observer('model.advice', function () {
      if (!this.get('model.advice.content')) {
        return;
      }

      const s = this.get('sessionAccount').getSettingValue('SHOW_TERMS_OF_CONDITIONS_ACCEPTED');
      this.get('model.advice').set('requiresAcceptanceOfTermsOfCondition', s === 'true');
    }),
    // eslint-disable-next-line ember/no-observers
    scheduleOpenPanelsAssociatedWithCustomFields: Ember.observer('model.customFields.@each.isAssociatedWithPanel', function () {
      Ember.run.once(this, 'openPanelsAssociatedWithCustomFields');
    }),

    openPanelsAssociatedWithCustomFields() {
      if (!this.get('model')) {
        return;
      }

      const self = this;
      this.get('model.customFields').filterBy('isAssociatedWithPanel').forEach(cf => {
        const transportPanelClass = cf.get('definition.TRANSPORT_PANEL_CLASS')[cf.get('option.transportPanel')];
        self.openPanel(transportPanelClass);
      });
    },

    canNotEditAfterProcessStarts: Ember.computed('model.{id,canBeUpdated}', 'hideSaveTransportButton', 'canNotEditBecauseOfOtherContractors', function () {
      return this.get('hideSaveTransportButton') || this.get('canNotEditBecauseOfOtherContractors') || this.get('model.id') && !this.get('model').get('canBeUpdated');
    }),
    canNotEditBecauseOfOtherContractors: Ember.computed('model.contractors.length', function () {
      const partnershipName = this.get('sessionAccount.currentUser.company.partnershipWithPrincipal.partnershipType.name');
      const isCurrentUserContractor = partnershipName === 'SUPPLIER' || partnershipName === 'OPERATOR';

      if (!isCurrentUserContractor) {
        return false;
      }

      return this.get('model.contractors.length') > 1;
    }),
    principalCompany: Ember.computed('', function () {
      return this.store.peekRecord('company', localStorage.getItem('principalId'));
    }),
    // eslint-disable-next-line ember/no-observers
    carrierChanged: Ember.observer('transport.carrierCompany.id', function () {
      const advice = this.get('transport.advice');

      if (advice === undefined || advice.get('content') === null || advice.get('id') || !this.get('transport.carrierCompany.id')) {
        return;
      }

      advice.setProperties({
        driverLanguage: null,
        driverName: null,
        driverMobilePhoneNumber: null,
        driverMobilePhonePrefix: '48',
        carRegistrationNumber: null,
        trailerRegistrationNumber: null,
        documentNumber: null,
        documentType: 'ID_CARD',
        termsOfConditionsAccepted: false
      });
    }),
    logsProxy: Ember.computed('transport.{notificationLogs.[],transportLogs.[]}', function () {
      const logs = Ember.A();
      this.get('transport.transportLogs').forEach(tL => logs.pushObject(tL));
      this.get('transport.notificationLogs').forEach(nL => logs.pushObject(nL));
      this.get('transport.weightLogs').forEach(wL => logs.pushObject(wL));
      return logs.filter(log => !this.get('excludedLogNames').includes(log.get('propertyName')));
    }),
    integrationLogsProxy: Ember.computed('transport.apiIntegrationLogs.[]', function () {
      const logs = Ember.A();
      this.get('transport.apiIntegrationLogs').forEach(log => logs.pushObject(log));
      return logs;
    }),
    artrLogsProxy: Ember.computed('transport.artrApiLogs.[]', function () {
      const logs = Ember.A();
      this.get('transport.artrApiLogs').forEach(log => {
        logs.pushObject(log);
      });
      return logs;
    }),
    saveAndCloseButtonLabel: Ember.computed('transport.transportType.weighingOnSave', function () {
      return this.get('transport.transportType.weighingOnSave') && !this.get('transport.id') ? this.get('intl').t('common.weightAndSave') : this.get('intl').t('common.saveAndClose');
    }),
    saveButtonLabel: Ember.computed('transport.transportType.weighingOnSave', function () {
      return this.get('transport.transportType.weighingOnSave') && !this.get('transport.id') ? this.get('intl').t('common.weightAndSave') : this.get('intl').t('common.save');
    }),
    showAdditionalInformation: Ember.computed('asPlainTemplate', 'additionalInformationInAppropriateLanguage', function () {
      return this.get('asPlainTemplate') && this.get('additionalInformationInAppropriateLanguage');
    }),
    additionalInformationInAppropriateLanguage: Ember.computed('', function () {
      const settingName = this.get('languageService').findProperLanguageVersion('ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW', 'ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN');
      return this.get('sessionAccount').getSettingValue(settingName);
    }),
    showTransactionPriceSection: Ember.computed('asPlainTemplate', 'transport.{id,transaction}', function () {
      if (this.get('transport.id') && this.get('transport').actionCanBePerformed('ROLE_ACCESS_FREIGHT_DATA') && this.get('transport.carrierCompany.id')) {
        return true;
      }

      return this.get('asPlainTemplate') && this.get('transport.transaction');
    }),
    transactionPriceLabel: Ember.computed('transport.transportType.{id,priceLabelTranslation}', function () {
      return this.get('transportType.priceLabelTranslation');
    }),
    showAmountOfCover: Ember.computed('transport.transportType.isAmountOfCoverVisible', function () {
      return this.get('transport.transportType.isAmountOfCoverVisible');
    }),

    async _updateSerialTransportDates(transport) {
      const serialTransport = await transport.get('serialTransport');

      if (!serialTransport) {
        return;
      }

      let startDate = await transport.get('firstStep.startDate');
      let endDate = await serialTransport.get('endDate');

      if (startDate && serialTransport.startDate !== startDate) {
        startDate = new Date(startDate);
        serialTransport.set('startDate', startDate);
      }

      if (startDate && endDate) {
        endDate = new Date(endDate);
        serialTransport.set('endDate', endDate.setHours(startDate.getHours()));
      }
    },

    _setGoodsValueFromCourierPackages(transport) {
      const enabledPackages = transport.get('courierPackages').filter(cp => {
        return cp.enabled;
      });

      if (enabledPackages && enabledPackages.length > 0) {
        let price = 0.0;
        enabledPackages.forEach(cp => {
          const totalPrice = Number(cp.get('quantity')) * Number(cp.get('packageValue'));
          price += totalPrice;
        });
        transport.set('goodsValue', price);
      }
    },

    _setDefaultAdditionalDescription(transport) {
      const defaultAdditionalDesc = transport.get('transportType.defaultTextForTransportAdditionalDescription');

      if (!defaultAdditionalDesc || transport.get('additionalDescription')) {
        return;
      }

      transport.set('additionalDescription', defaultAdditionalDesc);
    },

    _clearDuplicatedCustomFieldValues(transport) {
      if (transport.get('customFields.length') < 1) {
        return;
      }

      const clearedCustomFields = Ember.A([]);
      transport.get('customFields').sortBy('lastUpdated').forEach(cf => {
        if (clearedCustomFields.some(it => {
          return it.get('definition.id') === cf.get('definition.id');
        })) {
          return;
        }

        clearedCustomFields.pushObject(cf);
      });
      transport.set('customFields', clearedCustomFields);
    },

    _handleAddressesFromSteps(transport) {
      // Poniższe zostało dodane w ramach zadania A-8354.
      // Gdy step posiada atrybut newAddressAsDefault, po otwarciu modala transportu adres nie resetuje się.
      // Przy zapisie transportu, zmiany w jakichkolwiek polach adresu stepów, nie zapisywały się do bazy.
      // W ten sposób wymuszamy, aby przy jakiejkolwiek zmianie, tworzyć nowy rekord adresu z odpowiednimi danymi i podstawiać w miejsce starego.
      transport.get('steps').forEach(step => {
        if (step.get('address').get('hasDirtyAttributes') || step.get('address').get('isDirty')) {
          const oldAddress = step.get('address');
          const newAddress = this.get('store').createRecord('address', {
            country: oldAddress.get('country'),
            company: oldAddress.get('company'),
            recipient: oldAddress.get('recipient'),
            city: oldAddress.get('city'),
            postal: oldAddress.get('postal'),
            street: oldAddress.get('street'),
            province: oldAddress.get('province'),
            isHeadquarters: oldAddress.get('isHeadquarters'),
            enabled: oldAddress.get('enabled')
          });
          step.set('address', newAddress);
        }

        if (step.get('shouldAutoSaveCompanyGroup')) {
          step.set('companyGroup', step.get('company.nonDefaultUserGroups').get('firstObject'));
        }
      });
    },

    async prepareTransportForSaving(transport) {
      const self = this;
      const saveTransportButton = (0, _jquery.default)('.save-transport-button');
      saveTransportButton.button('loading');
      transport.set('principalCompany', self.get('principalCompany'));
      transport.set('advice', self.removeWhiteSpacesAndSetUpperCase(transport.get('advice')));
      transport.get('customFields').filterBy('definition.isNumberType').forEach(pair => {
        if (pair.get('value')) {
          pair.set('value', _numberParsers.default.getParsableNumber(pair.get('value')));
        }
      });

      self._handleAddressesFromSteps(transport);

      self._setGoodsValueFromCourierPackages(transport);

      await self._updateSerialTransportDates(transport);
      transport.validate();
      const transportIsValidate = await self.customValidate();

      self._clearDuplicatedCustomFieldValues(transport);

      self._setDefaultAdditionalDescription(transport);

      if (transport.get('hasErrors') || !transportIsValidate) {
        self.printErrors(transport);
        self.get('errorService').scrollToFirstError();
        saveTransportButton.button('reset');
        return false;
      }

      if (transport.get('id')) {
        console.debug(`Updating transport #${transport.get('id')}..`);
        this.set('newTransport', false);
      } else {
        console.debug('Saving new transport..');
        this.set('newTransport', true);
      }

      const warehouse = transport.get('warehouse');
      const warehouseStep = this.get('transport.warehouseStep');

      if (warehouseStep && warehouse && transport.get('transportType.typeGroup') !== transport.get('transportType.TYPE_GROUPS.TRANSPORT')) {
        warehouseStep.set('warehouse', warehouse);
      }

      if (transport.get('sureToSaveMessage')) {
        return confirm(this.get('intl').t('transport.sureToSaveMessage'));
      }

      return true;
    },

    handleSavedTransport(transport, self, saveAndCloseModal) {
      const transportId = transport.get('id');
      console.log(`We successfully saved transport ${transport.get('logString')}!`);

      if (self.get('newTransport') && transport.get('transportType.weighingOnSave')) {
        transport.get('checkpoints').then(checkpoints => {
          const weighingCheckpoints = checkpoints.filterBy('executionDate').filter(checkpoint => {
            return checkpoint.get('task.weighingIncluded');
          });

          if (weighingCheckpoints.get('length') === 0) {
            console.debug('Brak checkpointów do zważenia');
          } else {
            console.debug('Wykonujemy ważenie dla nowego transportu przy zapisie');
            self.get('transportController').send('weigh', weighingCheckpoints.get('firstObject'));
          }
        });
      }

      self.existingFilesToRemove.forEach(function (fileId) {
        console.log('self.existingFilesToRemove..');
        self.deleteFile(fileId);
      });
      self.existingFilesToRemove.clear();
      const token = self.get('sessionAccount').get('token');

      if (self.attachments) {
        self.uploadAttachment(transport.id, token);
        self.attachments.clear();
      }

      self.handleTransportReload(transport, self.get('scheduleController'));
      transport.get('customFields').then(customFields => {
        customFields.forEach(cf => cf.set('changeMessage', null));
        const customFieldsCopy = customFields.slice(0);
        customFieldsCopy.forEach(cs => {
          if (cs !== undefined && !cs.get('id') && !cs.get('isFilled') && cs.unloadRecord) {
            console.debug(`Unloading redundant custom field: ${cs.get('definition.name')} for newly saved transport ${transportId}..`);
            transport.get('customFields').removeObject(cs);
            cs.unloadRecord();
          }
        });
      });
      transport.get('timeWindows').then(timeWindows => {
        const timeWindowsCopy = timeWindows.slice(0);
        timeWindowsCopy.forEach(tw => {
          if (tw !== undefined && !tw.get('id') && tw.unloadRecord) {
            console.debug(`Unloading redundant time window for newly saved transport ${transportId}..`);
            transport.get('timeWindows').removeObject(tw);
            tw.unloadRecord();
          }
        });
      });
      self.send('handleUpdatedIndexes', transport);
      self.send('handleUpdatedCourierPackages', transport);

      if (transport.get('deliveries')) {
        transport.get('deliveries').forEach(d => {
          self.get('deliveryService').handleUpdatedDeliveryPackaging(d);
        });
      }

      self.get('transportType').set('forcedPollRequestTime', new Date().getTime());
      self.set('errors', []);

      if (saveAndCloseModal) {
        self.send('hideModal');
      } else if (transport.get('id')) {
        self.refreshTransport(transport);
      }

      self.get('scheduleController').setProperties({
        warehouseStepToHandle: null
      });
    },

    // eslint-disable-next-line ember/no-observers
    periodicTransportActiveObserver: Ember.observer('transport.periodicTransportActive', function () {
      // jeśli włączamy awizacje okresową, korygujemy datę żeby była spójna z planowaną datą awizacji
      if (this.get('transport.periodicTransportActive')) {
        this.set('transport.periodicTransport.startDate', this.get('dateOfAdvice'));
      }
    }),

    _generateLopReport() {
      const transportId = this.get('transport.id');
      const token = this.get('sessionAccount').get('token');
      const url = _environment.default.serverURL + '/reports/' + transportId + '/generateLopReport?token=' + token;
      return window.open(url, '_blank');
    },

    showWarehouseLocation: Ember.computed('firstStepWithWarehouse', 'transport.steps.[]', function () {
      const stepWithWarehouse = !!this.get('transport.steps.firstObject').get('warehouse.id');
      return this.get('transport.transportType.isWarehouseLocationVisible') && stepWithWarehouse;
    }),
    transportTypeHasVisibleSteps: Ember.computed('transport.transportType', function () {
      return this.get('transportType.hasVisibleSteps');
    }),
    warehouseLocationLabel: Ember.computed('transport', function () {
      return this.get('intl').t('warehouse.warehouseLocationLabel');
    }),
    canRemoveTransportRelation: Ember.computed('transport.{masterTransports.length,relatedToTransports.length}', function () {
      const canRemove = this.get('transport').actionCanBePerformed('ROLE_CAN_DELETE_RELATION');
      const isMaster = this.get('transport.relatedToTransports.length') > 0;
      const isRelatedTo = this.get('transport.masterTransports.length') > 0;
      return canRemove && (isRelatedTo || isMaster);
    }),
    warehouseLocationDisabled: Ember.computed('transport.transportType.authorityRestrictions.@each.authorityRange', function () {
      const transport = this.get('transport');

      if (transport) {
        return !transport.actionCanBePerformed('ROLE_EDIT_WAREHOUSE_LOCATION');
      } else return false;
    }),
    showLocationField: Ember.computed('transport', 'transport.transportType', 'showWarehouseSection', function () {
      const transport = this.get('transport');
      return transport.actionCanBePerformed('ROLE_ACCESS_TO_LOCATION');
    }),
    getWorkersList: Ember.computed('transport.serviceCompany', function () {
      return this.get('transport.serviceCompany').get('workers');
    }),
    editWorkerField: Ember.computed('transport.serviceCompany', function () {
      const transport = this.get('transport');
      return !transport.actionCanBePerformed('ROLE_EDIT_WORKER_FIELD');
    }),
    workerFieldLabel: Ember.computed('transport.serviceCompany', function () {
      const translationKey = this.get('transport').get('transportType.workerFieldTranslationKey');
      return translationKey || 'WORKERS';
    }),
    transportSupervisorLabel: Ember.computed('transport.transportType.transportSupervisorTranslationKey', function () {
      const translationKey = this.get('transport').get('transportType.transportSupervisorTranslationKey');
      return translationKey || 'transportSupervisor';
    }),
    transportSupervisorList: Ember.computed('principalCompany', function () {
      const currentPrincipalCompany = this.get('sessionAccount.principalCompany.id');
      return this.get('ajaxService').send(`/users/supervisors/${currentPrincipalCompany}`, 'GET').then(({
        transportSupervisors
      }) => {
        return transportSupervisors;
      }).catch(response => {
        console.error(response);
        return [];
      });
    }),
    addWorkerButtonLabel: Ember.computed('transport.serviceCompany', function () {
      const translationKey = this.get('transport').get('transportType.workerFieldTranslationKey');

      if (translationKey === 'GUESTS') {
        return 'addGuest';
      } else if (translationKey === 'SERVICEMEN') {
        return 'addServiceman';
      } else return 'addWorker';
    }),
    tasksArray: Ember.computed('model.transportType', function () {
      return Ember.A([...this.get('model.transportType.workflow.sortedTasks')]);
    }),
    refreshRelatedHasToWaitTransports: function (transport) {
      if (!transport.get('isMasterTransportInHasToWaitFor')) {
        return;
      }

      transport.get('relatedHasToWaitForTransports').forEach(t => {
        if (!t.get('id')) {
          return;
        }

        t.get('transportType').set('transportsTabVisited', false);
        t.reload();
      });
    },
    showTransportSupervisorField: Ember.computed('model.transportType', 'transport', function () {
      const transport = this.get('transport');
      return transport.actionCanBePerformed('ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR');
    }),
    canNotEditTransportSupervisor: Ember.computed('model.transportType', 'transport', 'showTransportSupervisorField', function () {
      const transport = this.get('transport');

      if (transport.get('disabled')) {
        return true;
      }

      return !transport.actionCanBePerformed('ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR');
    }),
    // Do refaktoru. Czemu kopiujemy w ten sposób awizacje?
    _setAdviceAndTwsFromMaster: async function (transport) {
      const master = transport.get('masterTransports.firstObject');
      const inHasToWaitForRelation = transport.get('hasAnyHasToWaitForRelation'); // Nie zmieniamy awizacji w relacji has to wait for, każdy transport ma swoją awizację

      if (!master || inHasToWaitForRelation) {
        return;
      }

      const masterAdvice = await master.get('advice');
      const advice = await masterAdvice.copy();
      transport.set('advice', advice);
      transport.get('advice').set('rodoAccepted', masterAdvice.get('rodoAccepted'));
      transport.set('timeWindows', []);
      transport.get('steps').forEach(s => {
        s.set('timeWindow', null);
      });
    },
    customValidate: async function () {
      let validates = true;

      if (!this.get('dateOfAdvice')) {
        this.get('transport').get('errors').add('dateOfAdvice', 'blank');
        validates = false;
      }

      if (this.get('transport.amountOfCover.amount')) {
        validates &= this.get('transport.amountOfCover.content').validate();
      }

      if (this.get('transport.weights')) {
        // eslint-disable-next-line no-return-assign
        this.get('transport.weights').forEach(w => validates &= w.validate());
      }

      this.get('transport.steps').forEach(s => {
        let errors = 0;
        s.validate();
        s.get('stepPackageTypes').forEach(spt => {
          if (!spt.validate()) errors++;
        });
        if (errors > 0) validates = false;
      });
      this.get('transport.timeWindows').forEach(tw => {
        if (tw.get('isDirty')) {
          tw.validate();
        }
      });
      this.get('transport.indexes').forEach(i => i.validate()); // eslint-disable-next-line no-return-assign

      this.get('transport.courierPackages').filterBy('enabled').forEach(cp => validates &= cp.validate());
      this.get('transport.customFields').forEach(f => f.validate());

      if (!this.get('transport.id') && this.get('transport.periodicTransportActive')) {
        const periodicTransport = this.get('transport.periodicTransport').validate ? this.get('transport.periodicTransport') : this.get('transport.periodicTransport.content');
        periodicTransport.set('dateOfAdvice', this.get('dateOfAdvice'));
        const periodicTransportValid = periodicTransport.validate();
        validates &= periodicTransportValid;
      }

      const attachments = this.get('attachments');

      if (attachments) {
        const maxCountString = this.get('sessionAccount').getSettingValue('MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT');
        const maxCount = maxCountString ? Number(maxCountString) : null;

        if (maxCount && maxCount < attachments.length) {
          alert(this.get('intl').t('document.maxCountExceeded', {
            maxCount
          }));
          validates &= false;
        }
      }

      if (!this.get('transport.id') && this.get('transport.serialTransportActive')) {
        const serialTransport = await this.get('transport.serialTransport');
        const valid = serialTransport.validate();
        validates &= valid;
      }

      return validates;
    },

    rollbackTransportChanges(transportModel) {
      transportModel.rollbackAttributes();
      transportModel.get('customFields').then(cFs => {
        if (cFs) {
          cFs.forEach(c => {
            if (c) {
              c.rollback();
            }
          });
        }
      });
      transportModel.get('advice').then(a => {
        if (a) {
          a.rollback();
        }
      });

      if (transportModel.get('indexes.length')) {
        transportModel.get('indexes').forEach(index => {
          if (index) {
            index.rollback();
            index.get('quantities').forEach(q => q.rollback());
          }
        });
      }

      if (transportModel.get('carrierCompany.name')) {
        transportModel.get('carrierCompany').then(c => {
          if (c.id) {
            c.rollbackAttributes();
          } else {
            c.unloadRecord();

            if (transportModel.get('oldCarrierCompany')) {
              transportModel.setProperties({
                company: transportModel.get('oldCarrierCompany'),
                oldCarrierCompany: null
              });
            }
          }
        });
      }

      transportModel.get('steps').then(steps => {
        steps.forEach(s => {
          if (s === undefined) {
            return true;
          }

          s.rollbackAttributes();

          if (s.get('company.name')) {
            s.get('company').then(comp => {
              if (comp.get('id')) {
                comp.rollbackAttributes();
              } else {
                comp.unloadRecord();

                if (s.get('oldCompany')) {
                  s.setProperties({
                    company: s.get('oldCompany'),
                    oldCompany: null
                  });
                }
              }
            });
          }

          if (s.get('timeWindow.id')) {
            s.get('timeWindow').then(timeWindow => {
              timeWindow.rollbackAttributes();
            });
          }

          if (s.get('stepPackageTypes')) {
            s.get('stepPackageTypes').then(packageTypes => {
              packageTypes.forEach(pt => {
                pt.rollbackAttributes();
              });
            });
          }
        });
      });
      this.get('attachments').clear();
    },

    refreshTransport(transport) {
      this.initializeDiscrepancyReport(transport);
      this.initializeCustomFields(transport);
    },

    deleteAllFuturePeriodicTransports: function (transport) {
      return transport.get('id') && transport.get('periodicTransport.id') && this.get('sessionAccount').hasRole('ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS') && confirm(this.get('intl').t('periodicTransport.deleteAllConfirmMsg'));
    },
    serialTransportAllowed: Ember.computed('transport', function () {
      return !this.get('transport.id') && this.get('transport').actionCanBePerformed('ROLE_CAN_CREATE_SERIAL_TRANSPORTS');
    }),
    handleSerialTransports: async function (transport, saveSerialTransports) {
      const serialTransport = await transport.get('serialTransport');

      if (!serialTransport) {
        return;
      }

      if (saveSerialTransports) {
        await this.saveSerialTransports(transport);
      }

      serialTransport.deleteRecord();
    },
    saveSerialTransports: async function (transport) {
      const serialTransport = await transport.get('serialTransport');

      if (!serialTransport) {
        return;
      }

      serialTransport.set('transportId', transport.get('id'));
      const response = await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.TRANSPORTS.SAVE_SERIAL_TRANSPORTS, null, {
        body: JSON.stringify(serialTransport)
      });

      if (!response.ok) {
        console.error('Błąd podczas tworzenia serii transportow dla transportu: ' + transport.id + ' :: transport zostanie usuniety.');
        const err = await response.json();
        this.actionProgressToast.showErrorToast(this.intl.t('serialTransport.errors.rollback', {
          id: transport.id
        }), err.errors);
      }
    },
    canNotEditMainData: Ember.computed('sessionAccount', function () {
      return !this.get('transport').actionCanBePerformed('UPDATE_MAIN_DATA_SECTION');
    }),
    actions: {
      onRouteTemplateSelect: function (route) {
        const transport = this.get('transport');
        transport.set('routeTemplate', route);
        const shouldMapRouteToTransport = this.get('transportType.priceListSetting.overrideStepAndCustomFieldData');

        if (shouldMapRouteToTransport) {
          this.routeTransportMapperService.mapRouteToTransport(route, transport);
        }
      },
      detachAllDeliveries: function (transport) {
        const model = {
          transport
        };
        this.get('modalService').showModal(this.get('modalService').MODALS.TRANSPORT.DETACH_ALL_DELIVERIES, model, 'sub-modal');
      },
      generateLOPReport: function () {
        console.log('Generujemy raport LOP');
        Ember.run.once(this, '_generateLopReport');
      },
      setTransportSupervisorOnSelect: async function (transportSupervisorDto) {
        const personId = transportSupervisorDto.id;
        const person = await this.get('store').findRecord('user', personId);
        this.set('transport.transportSupervisor', person);
      },
      resizeTextArea: function () {
        if (this.get('asPlainTemplate')) {
          const element = (0, _jquery.default)('.textarea-resize');
          const height = 5 + element.prop('scrollHeight') + 'px';
          element.css('height', height);
        }
      },

      /**
       * Metoda planująca zapisanie transportu.
       *
       * W przypadku dodawania opcji za pośrednictwem pola z podpowiadaniem, musimy trochę
       * poczekać na wykonanie wszystkich procesów.
       */
      save: function (saveAndCloseModal = true) {
        const saveTransportButton = (0, _jquery.default)('.save-transport-button');
        saveTransportButton.button('loading');
        const self = this;
        const transportModel = this.get('model');
        Ember.run.later(this, function () {
          if (transportModel.then !== undefined) {
            transportModel.then(transport => {
              const model = {
                transport,
                saveAndCloseModal
              };
              self.send('saveOrUpdateTransport', transport, saveAndCloseModal);
            });
          } else {
            const model = {
              transport: transportModel,
              saveAndCloseModal
            };
            self.send('saveOrUpdateTransport', transportModel, saveAndCloseModal);
          }
        }, 250);
      },
      createSimilar: function (transportId) {
        const self = this;
        const multiple = this.get('transport').actionCanBePerformed('ROLE_CREATE_SIMILAR_MULTIPLE');

        if (multiple) {
          const model = {
            transport: self.get('transport'),
            count: 1,
            saving: false
          };
          this.send('showModal', 'transports.modals.create-similar-multiple', model);
        } else {
          this.send('hideModal');
          const transportModel = this.get('store').peekRecord('transport', transportId);
          this.get('transportCopyService').copyTransport(transportModel).then(t => {
            setTimeout(function () {
              console.log('Pokazujemy modal po wybraniu opcji "Utwórz podobne"...');
              self.send('showModal', 'transports.modals.create-transport', t);
            }, 1000);
          });
        }
      },
      restoreFromArchive: function (transportId) {
        this.get('ajaxService').send(`/transports/${transportId}/restore`, 'PUT').then(() => {
          alert(this.get('intl').t('transport.restoredSuccessfully'));
          this.send('hideModal');
        }).catch(response => {
          (0, _jquery.default)('.transport-modal').scrollTop(0);
          this.set('errors', response.responseJSON.errors);
        });
      },
      saveOrUpdateTransport: async function (transport, saveAndCloseModal = true) {
        const self = this;
        await this._setAdviceAndTwsFromMaster(transport);
        const valid = await self.prepareTransportForSaving(transport);

        if (!valid) {
          (0, _jquery.default)('.save-transport-button').button('reset');
          return false;
        }

        transport.set('updateAllFuturePeriodicTransports', this.updateAllFuturePeriodicTransports(transport));
        const saveSerialTransports = !this.get('transport.id') && this.get('transport.serialTransportActive');
        transport.save().then(() => self.handleSavedTransport(transport, self, saveAndCloseModal)).then(() => {
          self.handleSerialTransports(transport, saveSerialTransports);
        }).catch(response => {
          const errors = response.errors;
          self.send('handleSpotSearchingException', errors, transport);
          window.scrollTo({
            top: 0
          });
          self.set('errors', errors);
        }).finally(() => {
          (0, _jquery.default)('.save-transport-button').button('reset');
          transport.reload();

          if (transport.get('masterTransports.length')) {
            transport.get('masterTransports.firstObject').reload();
            transport.get('masterTransports.firstObject.advice').reload();
          }

          transport.get('deliveries').forEach(d => {
            d.reload();
            d.get('packaging').reload();
          });
        });
      },

      handleSpotSearchingException(errors, transport) {
        const hasErrors = errors && errors.length >= 1;
        let exceptionType = null;

        if (hasErrors) {
          if (errors[0].meta) {
            exceptionType = errors[0].meta.exceptionType;
          }
        }

        if (this.EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING.includes(exceptionType)) {
          const responseMeta = errors.get('firstObject.meta');

          if (responseMeta) {
            const exceptionInformation = Ember.ObjectProxy.create({
              initialStart: responseMeta.initialStart,
              windowSizeInMinutes: responseMeta.windowSizeInMinutes,
              rampName: responseMeta.rampName,
              whId: responseMeta.warehouseId
            });
            transport.set('exceptionInformation', exceptionInformation);
          }

          this.set('showSearchSpotsButton', true);
        }
      },

      async handleFreeSubsequentSpotChoice(timeWindowCandidate) {
        const transport = this.get('transport');
        const loadStep = await transport.get('steps').filterBy('isLoad').get('firstObject');

        if (loadStep) {
          const timeWindow = await loadStep.get('timeWindow');

          if (!timeWindow) {
            this.send('selectWindow', timeWindowCandidate);
          }

          const ramp = this.store.peekRecord('ramp', timeWindowCandidate.ramp.id);
          timeWindow.set('start', timeWindowCandidate.start);
          timeWindow.set('stop', timeWindowCandidate.stop);
          timeWindow.set('ramp', ramp);
          timeWindow.set('initialStart', timeWindowCandidate.start);
          this.send('saveOrUpdateTransport', transport);
          return;
        }

        this.send('selectWindow', timeWindowCandidate);
      },

      handleClickWithSpot(timeWindowCandidate) {
        const self = this;
        self.set('errors', []);
        self.send('selectWindow', timeWindowCandidate);
      },

      handleUpdatedIndexes(transport) {
        if (!transport.get('indexes.length')) {
          return;
        }

        transport.get('indexes').then(indexes => {
          const indexesCopy = indexes.slice(0);
          indexesCopy.forEach(index => {
            if (index !== undefined && !index.get('id')) {
              console.debug(`Unloading redundant index for newly saved transport ${transport.get('id')}..`);
              transport.get('indexes').removeObject(index);
              index.unloadRecord();
            } else if (index !== undefined) {
              // W wyniku bugu (z 2013 roku) ember-data [https://github.com/emberjs/data/issues/1540],
              // określanie `hasDirtyAttributes` nie działa w przypadku numerów ponieważ zaktualizowane
              // pola są zapisywane w modelu jako `string`, a przychodzące z serwera jako `number`.
              //
              // Na razie nie używam zbyt często `hasDirtyAttributes`, dlatego nie próbuję pisać jakiegoś
              // mixina albo serwisu, ale w przyszłości można się nad tym zastanowić.
              index.get('quantities').forEach(q => {
                if (typeof q.get('quantity') === 'string') {
                  q.set('quantity', parseFloat(q.get('quantity')));
                }
              });
            }
          });
        });
      },

      handleUpdatedCourierPackages(transport) {
        if (!transport.get('courierPackages.length')) {
          return;
        }

        transport.get('courierPackages').then(cps => {
          const cpCopy = cps.slice(0);
          cpCopy.forEach(cp => {
            if (cp !== undefined && !cp.get('id')) {
              console.debug(`Unloading redundant courierPackages for newly saved transport ${transport.get('id')}..`);
              transport.get('courierPackages').removeObject(cp);
              cp.unloadRecord();
            }
          });
        });
      },

      /**
       * Metoda planująca przeniesienie użytkownika do harmonogramu.
       *
       * W przypadku dodawania opcji za pośrednictwem pola z podpowiadaniem, musimy trochę
       * poczekać na wykonanie wszystkich procesów.
       */
      planSelectWindow() {
        Ember.run.later(this, function () {
          this.send('selectWindow');
        }, 250);
      },

      async selectWindow(timeWindowCandidate) {
        const self = this;
        const transport = self.get('transport');
        transport.get('customFields').filterBy('definition.isNumberType').forEach(pair => {
          if (pair.get('value')) {
            pair.set('value', _numberParsers.default.getParsableNumber(pair.get('value')));
          }
        });
        transport.get('steps').forEach(step => {
          if (step.get('shouldAutoSaveCompanyGroup')) {
            step.set('companyGroup', step.get('company.nonDefaultUserGroups').get('firstObject'));
          }
        });
        const attachments = this.get('attachments'); // `taskGroupIdentifier === 0` to grupa tasków o nazwie "Prace przygotowawcze", pierwszym taskiem
        // z tej grupy zawsze jest utworzenie zlecenia dlatego `idxInGroup === 0` zawsze powinien być taskiem tworzącym zlecenie

        const orderCreationTask = Ember.A(this.get('tasksArray').filter(t => t.taskGroupIdentifier === 0 && t.idxInGroup === 0)).get('firstObject');

        if (orderCreationTask.canBeConfirmAfterAddingDocumentsForTransport && attachments.length === 0) {
          self.setProperties({
            errors: Ember.A([`Etap '${orderCreationTask.checkpointName}' wymaga dodania dokumentów do transportu przed jego utworzeniem`])
          });
          return;
        }

        transport.validate();
        const transportIsValidate = await self.customValidate();

        if (transport.get('hasErrors') || !transportIsValidate) {
          self.get('errorService').scrollToFirstError();
          self.printErrors(transport);
          return;
        }

        const transportType = transport.get('transportType');
        const warehouse = transport.get('warehouse');
        const warehouseStep = this.get('transport.warehouseStep');

        if (warehouseStep && warehouse && transport.get('transportType.typeGroup') !== transport.get('transportType.TYPE_GROUPS.TRANSPORT')) {
          warehouseStep.set('warehouse', warehouse);
        }

        if (transportType.get('typeGroup') === transportType.get('TYPE_GROUPS').SIMPLIFIED_DELIVERY) {
          transport.get('lastStep').setProperties({
            warehouse,
            company: transport.get('firstContractor')
          });
        }

        const warehouseStepToHandle = transport.get('firstWarehouseStepWithoutWindow') ? transport.get('firstWarehouseStepWithoutWindow') : transport.get('firstChangedWarehouseStep');

        if (transport.get('firstChangedWarehouseStep')) {
          warehouseStepToHandle.set('didWarehouseChange', false);
        }

        (0, _jquery.default)('#main-modal').data('skip-cancel-action', true);

        if (timeWindowCandidate) {
          console.debug(`Wybrana data awizacji: ${timeWindowCandidate.start}`);
          self.set('dateOfAdvice', timeWindowCandidate.start);
          self.send('hideModal');
          self.get('scheduleController').send('handleClickWithTransport', timeWindowCandidate.start, timeWindowCandidate.stop, timeWindowCandidate.ramp.id, timeWindowCandidate.start);
          self.send('makeTransitionToWarehouse', warehouseStepToHandle);
        } else {
          console.debug('Szukamy wolnego miejsca w harmonogramie..');
          this.getFirstPossibleWindowStart(warehouseStepToHandle, transport, this.get('dateOfAdvice')).then(response => {
            const expectedAdviceDate = this.get('dateOfAdvice') ? moment(this.get('dateOfAdvice')).format('YYYY-MM-DD') : null;
            const firstAvailable = response.firstPossibleStart ? moment(response.firstPossibleStart).format('YYYY-MM-DD') : null;

            if (!response.firstPossibleStart) {
              self.setProperties({
                errors: [response.error]
              });
              return;
            }

            const errorMessage = self.get('intl').t('timeWindow.errors.warehouseHasNoSpaceThisDay', {
              expectedAdviceDate
            });

            if (expectedAdviceDate && firstAvailable && expectedAdviceDate < firstAvailable) {
              self.setProperties({
                errors: [errorMessage]
              });
              return;
            }

            self.send('hideModal');
            self.send('makeTransitionToWarehouse', warehouseStepToHandle);
          });
        }
      },

      makeTransitionToWarehouse(warehouseStepToHandle) {
        const self = this;
        const transport = self.get('transport');
        const dateString = moment(self.get('dateOfAdvice')).format('YYYY-MM-DD');
        const formattedStart = moment(self.get('dateOfAdvice')).format('DD.MM.YYYY HH:mm');
        const controller = self.get('scheduleController');

        if (controller.get('warehouse.loadedDays') && controller.get('warehouse.loadedDays').includes(formattedStart)) {
          controller.get('warehouse.loadedDays').removeObject(formattedStart);
        }

        controller.setProperties({
          attachments: self.get('attachments'),
          errors: null,
          transportToSave: transport,
          warehouseStepToHandle
        });
        console.debug(`Przechodzimy do harmonogramu w magazynie: ${warehouseStepToHandle.get('warehouse.name')} w dniu: ${dateString}`);
        self.transitionToRoute('warehouses.schedule', warehouseStepToHandle.get('warehouse.id'), dateString, '1');
      },

      cancelCreateWindow() {
        this.set('selectedWarehouse', null);
        const model = this.get('transport');
        const transportTypeAlias = model.get('transportType.alias');

        if (model) {
          if (model.rollbackAttributes) {
            this.rollbackTransportChanges(model);
          } else {
            model.then(transportModel => {
              this.rollbackTransportChanges(transportModel);
            });
          }
        }

        this.setProperties({
          errors: [],
          model: null
        });

        if (this.get('scheduleController').get('warehouseStepToHandle')) {
          this.get('scheduleController').setProperties({
            transportToSave: null,
            warehouseStepToHandle: null,
            warningMessage: null
          });
          this.transitionToRoute('transports.index', transportTypeAlias);
        }
      },

      confirmCheckpoint: function (checkpoint) {
        this.get('transportController').send('confirm', checkpoint);
      },
      weigh: function (checkpoint) {
        this.get('transportController').send('weigh', checkpoint);
      },
      deleteTransport: function (transport) {
        const deleteAllFuturePeriodicTransports = this.deleteAllFuturePeriodicTransports(transport);
        const self = this;
        (0, _jquery.default)('.delete-progress').show();
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          type: 'PUT',
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/delete?deleteAllFuturePeriodicTransports=' + deleteAllFuturePeriodicTransports,
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.setProperties({
            model: null,
            errors: []
          });

          if (transport.get('timeWindows')) {
            console.debug(`Transport ${transport.id} został usunięty -- usuwamy okna z harmonogramu..`);
            self.get('scheduleController').get('windows').removeObjects(transport.get('timeWindows'));
          }

          transport.set('deleted', true);

          if (self.get('target.currentRouteName') === 'warehouses.schedule') {
            self.get('scheduleController').send('onPoll');
          }

          self.refreshRelatedHasToWaitTransports(transport);
          self.send('hideModal');
          transport.set('transportType.forcedPollRequestTime', new Date().getTime());
        });
      },
      getTransportLogs: function (transport) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getTransportLogs',
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.transportLogs.sortBy('dateCreated').reverse().forEach(function (transportLog) {
            const log = self.get('store').peekRecord('transportLog', transportLog.id);
            transport.get('transportLogs').pushObject(log);
          });
        });

        this.send('getNotificationLogs', transport);
        this.send('getWeightLogs', transport);
      },
      getIntegrationLogs: function (transport) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getIntegrationLogs',
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.apiIntegrationLogs.sortBy('dateCreated').reverse().forEach(function (apiIntegrationLog) {
            const log = self.get('store').peekRecord('apiIntegrationLog', apiIntegrationLog.id);
            transport.get('apiIntegrationLogs').pushObject(log);
          });
        });
      },
      getIntegrationRequestLog: function (log) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/getIntegrationLog/' + log.get('id'),
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(json => {
          const apiIntegrationLog = json.apiIntegrationLog;
          const log = self.get('store').peekRecord('apiIntegrationLog', apiIntegrationLog.id);
          log.set('request', apiIntegrationLog.request);
        });
      },
      getNotificationLogs: function (transport) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getNotificationLogs',
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.notificationLogs.sortBy('dateCreated').reverse().forEach(function (notificationLog) {
            const log = self.get('store').peekRecord('notificationLog', notificationLog.id);
            transport.get('notificationLogs').pushObject(log);
          });
        });
      },
      getWeightLogs: function (transport) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getWeightLogs',
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.weightLogs.sortBy('dateCreated').reverse().forEach(function (weightLog) {
            const log = self.get('store').peekRecord('weightLog', weightLog.id);
            transport.get('weightLogs').pushObject(log);
          });
        });
      },
      getArtrLogs: function (transport) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getArtrLogs',
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.artrApiLog.sortBy('dateCreated').reverse().forEach(function (artrApiLog) {
            const log = self.get('store').peekRecord('artrApiLog', artrApiLog.id);
            transport.get('artrApiLogs').pushObject(log);
          });
        });
      },

      setWarehouse(warehouse, object) {
        const stepForWarehouse = this.get('firstStepWithWarehouse') || this.get('transport.steps.firstObject');
        stepForWarehouse.set('warehouse', warehouse);
        console.debug(`Selected warehouse: ${warehouse.get('name')}`);
        this.clearBootstrapError((0, _jquery.default)('#' + object.uniqueId));
      },

      setAssortmentGroup(ag, object) {
        this.get('firstStepWithWarehouse').set('assortmentGroup', ag);
        this.clearBootstrapError((0, _jquery.default)('#' + object.uniqueId));
      },

      showSubModal(templateName, model) {
        this.send('showModal', templateName, model, 'sub-modal');
      },

      setDateOfAdvice(propertyName, date) {
        this.setDateOfPeriodicAdvice(date, propertyName);
        this.set('dateOfAdvice', moment(date[0]).local().format('YYYY-MM-DD'));
      },

      close: function () {
        this.send('hideModal');
      },
      openAddWorkerModal: function (transportModel, worker) {
        const self = this;
        const principalCompanyId = localStorage.getItem('principalId');
        const principalCompany = this.get('store').peekRecord('company', principalCompanyId);

        if (!worker) {
          worker = this.get('store').createRecord('worker', {
            company: self.get('model.serviceCompany'),
            enabled: true,
            principalCompany
          });
        }

        const model = {
          worker,
          transport: transportModel
        };
        this.send('showModal', 'companies.modals.add-worker', model);
      },
      openCreateDeliveryModal: async function () {
        const self = this;
        const transport = this.get('transport');
        const transportType = transport.get('transportType');
        const customFields = await this.store.query('customFieldDefinition', {
          planning: true,
          transportTypeId: transportType.get('id')
        });
        const delivery = this.store.createRecord('delivery', {
          deliverySetting: await this.transportTypeSettingService.getDeliverySettings(transportType),
          transportType,
          transport
        });
        await this.deliveryService.autoGenerateDeliveryNumber(delivery);
        const isLoadStepInWarehouse = transport.get('steps').filterBy('isLoad').get('firstObject').get('isInWarehouse');
        const isUnloadStepInWarehouse = transport.get('steps').filterBy('isUnload').get('firstObject').get('isInWarehouse');
        delivery.set('loadStep', self.get('store').createRecord('step', {
          ownedByPrincipal: isLoadStepInWarehouse,
          stepTypeName: 'load',
          orderInTransport: 0
        }));
        delivery.set('unloadStep', self.get('store').createRecord('step', {
          ownedByPrincipal: isUnloadStepInWarehouse,
          stepTypeName: 'unload',
          orderInTransport: 1
        }));
        const transportSteps = [];
        transport.get('steps').forEach(s => {
          const defaultMinAdviceDate = moment().startOf('day');
          const defaultMaxAdviceDate = moment().startOf('day').add(1, 'day');
          const stepCopyPromise = s.copy(true, {
            ignoreAttributes: ['address', 'transport', 'timeWindow', 'minAdviceDate', 'maxAdviceDate', 'arrivalDate', 'stepWarehouseLocations']
          });
          stepCopyPromise.then(async stepCopy => {
            stepCopy.setProperties({
              ownedByPrincipal: stepCopy.get('isInWarehouse'),
              address: await s.get('address')
            });

            if (stepCopy.get('isInWarehouse')) {
              if (s.get('minAdviceDate') || s.get('maxAdviceDate')) {
                const defaultMinHour = s.get('minAdviceDate') ? s.get('minAdviceDate').getHours() : 0;
                const defaultMaxHour = s.get('maxAdviceDate') ? s.get('maxAdviceDate').getHours() : 0;
                stepCopy.setProperties({
                  minAdviceDate: defaultMinAdviceDate.add(defaultMinHour, 'hours').toDate(),
                  maxAdviceDate: defaultMaxAdviceDate.add(defaultMaxHour, 'hours').toDate()
                });
              }
            } else {
              const arrivalDate = await this.get('addressUnloadTimeService').findArrivalDate(stepCopy.get('address'), transportType);
              stepCopy.setProperties({
                arrivalDate
              });
            }
          });
          transportSteps.push(s);
        });
        delivery.set('packaging', Ember.A([]));
        this.deliveryService.createSimplePackaging(delivery);
        const model = {
          delivery,
          transportSteps,
          transportType,
          packageTypes: this.get('packageTypes'),
          customFields
        };
        const modalPath = this.get('modalService').MODALS.DELIVERIES.CREATE_DELIVERY_FROM_TRANSPORT;
        this.send('showModal', modalPath, model);
      }
    }
  });

  _exports.default = _default;
});