define("apollo/services/websocket-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    subscriptions: null,
    client: null,
    isConnected: Ember.computed('client.connected', function () {
      return this.client ? this.client.connected : false;
    }),

    init() {
      this._super(...arguments);

      this.subscriptions = {};
      (true && !(!Ember.isNone(this.client)) && Ember.assert('Websocket client must be provided', !Ember.isNone(this.client)));
    },

    willDestroy() {
      this._super(...arguments);

      this._cleanupSubscriptions();
    },

    /**
     * Unsubscribe from a specific topic
     * @param {string} topic - The endpoint to unsubscribe from
     * @param {Object} [headers={}] - Optional headers for unsubscription @see https://www.npmjs.com/package/webstomp-client
     * @returns {boolean} - Success status of unsubscription
     */
    unsubscribe(topic, headers = {}) {
      try {
        const subscription = this.subscriptions[topic];

        if (!subscription) {
          return false;
        }

        subscription.unsubscribe(headers);
        delete this.subscriptions[topic];
        return true;
      } catch (error) {
        this._handleError('unsubscription', error, topic);

        return false;
      }
    },

    /**
     * Unsubscribe from multiple topics matching a pattern
     * @param {string|RegExp} pattern - Pattern to match topics against
     * @param {Object} [headers={}] - Optional headers for unsubscription @see https://www.npmjs.com/package/webstomp-client
     * @returns {Array} - Array of successfully unsubscribed topics
     */
    unsubscribeAny(pattern, headers = {}) {
      const regex = pattern instanceof RegExp ? pattern : new RegExp(pattern);
      const matchingTopics = Object.keys(this.subscriptions).filter(topic => regex.test(topic));
      return matchingTopics.filter(topic => this.unsubscribe(topic, headers));
    },

    /**
     * Subscribe to a WebSocket topic
     * @param {string} topic - The endpoint to subscribe to
     * @param {Function} callback - Callback function to handle incoming messages
     * @param {Object} [headers={}] - Optional headers for the subscription @see https://www.npmjs.com/package/webstomp-client
     * @returns {Object|null} - Subscription object if successful, null otherwise
     */
    subscribe(topic, callback, headers = {}) {
      try {
        if (!this._validateSubscription(topic, callback)) {
          return null;
        }

        const subscription = this.client.subscribe(topic, message => this._handleMessage(callback, message), headers);
        this.subscriptions[topic] = subscription;
        return subscription;
      } catch (error) {
        this._handleError('subscription', error, topic);

        return null;
      }
    },

    /**
     * Send a message to a specific destination
     * @param {string} destination - Target endpoint
     * @param {string|Object} message - Message to send
     * @param {Object} [headers={}] - Optional headers for the message @see https://www.npmjs.com/package/webstomp-client
     * @returns {boolean} - Success status of sending the message
     */
    send(destination, message, headers = {}) {
      try {
        if (!this._validateConnection('send message')) {
          return false;
        }

        const formattedMessage = this._formatMessage(message);

        this.client.send(destination, formattedMessage, headers);
        return true;
      } catch (error) {
        this._handleError('message sending', error, destination);

        return false;
      }
    },

    /**
     * Disconnect the WebSocket connection
     * @returns {boolean} - Success status of disconnection
     */
    disconnect() {
      try {
        if (!this._validateConnection('disconnect')) {
          return false;
        }

        this._cleanupSubscriptions();

        this.client.disconnect();
        return true;
      } catch (error) {
        this._handleError('disconnection', error);

        return false;
      }
    },

    _handleMessage(callback, message) {
      try {
        callback.call(this, message);
      } catch (error) {
        this._handleError('message handling', error);
      }
    },

    _validateConnection(operation) {
      if (!this.isConnected) {
        console.warn(`Cannot ${operation}: WebSocket connection is not open`);
        return false;
      }

      return true;
    },

    _validateSubscription(topic, callback) {
      if (!this._validateConnection('subscribe')) {
        return false;
      }

      if (this.subscriptions[topic]) {
        console.warn(`Topic ${topic} is already subscribed`);
        return false;
      }

      if (typeof callback !== 'function') {
        console.error('Callback must be a function');
        return false;
      }

      return true;
    },

    _formatMessage(message) {
      return typeof message === 'string' ? message : JSON.stringify(message);
    },

    _cleanupSubscriptions() {
      Object.keys(this.subscriptions).forEach(topic => {
        this.unsubscribe(topic);
      });
    },

    _handleError(operation, error, context = '') {
      const contextInfo = context ? ` for ${context}` : '';
      console.error(`WebSocket ${operation} failed${contextInfo}:`, error);
    }

  });

  _exports.default = _default;
});