define("apollo/pods/components/planning/planned-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    modalService: Ember.inject.service('modal-service'),
    intl: Ember.inject.service(),
    deliveryService: Ember.inject.service('delivery-service'),
    store: Ember.inject.service(),
    transportTypeSettingService: Ember.inject.service('transport-type-settings-service'),
    routeService: Ember.inject.service('route-service'),
    computedTransports: Ember.computed('deliveries.{length,[]}', 'transports.{length,@each.id,@each.internalIndex,[]}', function () {
      return this.get('transports').sortBy('internalIndex');
    }),
    validateDeliveryLoadCity: function (transport, delivery) {
      return transport.get('deliveries').every(d => {
        if (d.get('loadStep.isInWarehouse')) {
          return d.get('loadStep.warehouse.address.city') === delivery.get('loadStep.warehouse.address.city');
        } else {
          return d.get('loadStep.address.city') === delivery.get('loadStep.address.city');
        }
      });
    },
    actions: {
      async createBasicTransport() {
        const customFields = await this.get('customFields');
        const transportType = await this.get('transportType');
        const basicTransport = await this.get('store').createRecord('transport', {
          transportType
        });
        const showRoutes = await transportType.get('priceListSetting.showRouteTemplate');
        let availableRoutes;

        if (showRoutes) {
          availableRoutes = await this.routeService.getAvailableRoutes(transportType);
        }

        if (!showRoutes && customFields.length < 1) {
          await this.deliveryService.createNewBasicTransport(basicTransport);
          this.set('action', 'refreshModel');
          this.sendAction('action');
          return;
        }

        this.get('modalService').showModal(this.get('modalService').MODALS.DELIVERIES.ADD_TRANSPORT, {
          transportType,
          availableRoutes,
          customFields,
          basicTransport
        });
      },

      async addDelivery(transport, obj) {
        const transportType = transport.get('transportType');
        const deliverySettings = await this.transportTypeSettingService.getDeliverySettings(transportType);

        if (deliverySettings.checkCompatibilityOfLoadingCity && !this.validateDeliveryLoadCity(transport, obj)) {
          this.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.deliveryTransport.ADD_DELIVERY_TO_TRANSPORT.error'));
          console.error('Niezgodne miasta na załadunku !');
          return;
        }

        const response = await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.ADD_DELIVERY_TO_TRANSPORT, null, {
          body: JSON.stringify({
            transportId: transport.get('id'),
            deliveryId: obj.get('id')
          })
        });

        if (response.ok) {
          this.set('action', 'refreshModel');
          this.sendAction('action');
        }
      },

      refreshModel() {
        this.set('action', 'refreshModel');
        this.sendAction('action');
      }

    }
  });

  _exports.default = _default;
});