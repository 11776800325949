define("apollo/pods/auth/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    websocket: Ember.inject.service('websocket'),
    renderTemplate: function (controller, model) {
      this.render('auth/login', {
        into: 'application',
        outlet: 'login',
        controller,
        model
      });
    },
    setupController: function (controller) {
      controller.setProperties({
        'errorMessage': null,
        'logoutByConcurrentSession': localStorage.getItem('logoutByConcurrentSession'),
        'logoutByService': localStorage.getItem('logoutByService')
      });
    },
    actions: {
      willTransition() {
        this.get('controller').setProperties({
          'logoutByConcurrentSession': null,
          'logoutByService': null
        });
        localStorage.removeItem('logoutByConcurrentSession');
        localStorage.removeItem('logoutByService');
        return true;
      }

    }
  });

  _exports.default = _default;
});