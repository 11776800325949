define("apollo/pods/warehouses/schedule/route", ["exports", "apollo/mixins/menu-mixin", "apollo/mixins/persistence-mixin"], function (_exports, _menuMixin, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_menuMixin.default, _persistenceMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    appTabActiveService: Ember.inject.service('application-tab-active-service'),
    websocketService: Ember.inject.service('websocket'),
    transportCombiner: Ember.inject.service('transport-relations.relations-combiner'),
    access: Ember.A(['ROLE_WAREHOUSE_TAB']),
    beforeModel: function (transition) {
      const accessibleWarehouseIds = this.get('sessionAccount.currentUser.accessibleRamps').map(r => {
        return r.get('warehouse.id');
      });
      const warehouseId = transition.params['warehouses.schedule'].warehouse_id;
      const userHasAccessToWarehouse = accessibleWarehouseIds.includes(warehouseId);

      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access')) || !userHasAccessToWarehouse) {
        transition.abort();
        this.transitionTo('forbidden');
      }

      const principal = this.store.peekRecord('company', localStorage.getItem('principalId'));
      const warehouse = this.store.peekRecord('warehouse', warehouseId);
      warehouse.set('loadedDays', []);

      if (warehouse.get('company.id') !== principal.get('id')) {
        const warehouseOfCurrentPrincipal = this.get('sessionAccount.currentUser.warehouses.firstObject');
        const dateString = transition.params['warehouses.schedule'].date_string;
        const delta = transition.params['warehouses.schedule'].delta;

        if (!warehouseOfCurrentPrincipal) {
          this.transitionTo('forbidden');
        }

        console.debug(`Transitioning user to warehouse ${warehouseOfCurrentPrincipal.get('name')}..`);
        this.transitionTo('warehouses.schedule', warehouseOfCurrentPrincipal.get('id'), dateString, delta);
      }
    },

    model(params) {
      const self = this;
      const currentUser = self.get('sessionAccount').get('currentUser');
      const warehouseId = params.warehouse_id;
      const dateString = params.date_string;
      const warehouses = currentUser.get('accessibleWarehouses').filter(w => {
        return w.get('company.id') === localStorage.getItem('principalId');
      }).sortBy('idx');
      const ramps = currentUser.get('ramps').filterBy('warehouse.id', warehouseId).sortBy('idx');
      return Ember.RSVP.hash({
        warehouses,
        warehouse: self.store.peekRecord('warehouse', warehouseId),
        ramps,
        delta: Math.min(params.delta, 7),
        dateString,
        userGroups: this.get('store').query('userGroup', {
          principalCompanyId: localStorage.getItem('principalId')
        })
      });
    },

    setupController: function (controller, model) {
      // Wartość `new Date()` w `lastPoll` ustawiamy tylko przy pierwszym wejściu do harmonogramu;
      // w pozostałych przypadkach pierwsze odświeżenie (polling) użyje zapisanego `lastPoll` - tak by odświeżyć
      // zmiany, które zaszły w harmonogramie w czasie, w którym włączona była inna zakładka.
      controller.setProperties({
        windows: Ember.A([]),
        model,
        scheduleStartDate: moment(model.dateString),
        selectedDateString: model.dateString,
        lastPoll: controller.get('lastPoll') || new Date()
      });
    },
    activate: function () {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.get('appTabActiveService').setOnPollHandlingObject(this.controllerFor(this.routeName), 'WAREHOUSE');
      this.set('timer', this.schedule(this.get('onPoll')));
      this.activateCurrentTab('WAREHOUSE_TAB');
    },
    deactivate: function () {
      this.get('appTabActiveService').setOnPollHandlingObject(null, 'WAREHOUSE');
      Ember.run.cancel(this.get('timer'));
      const socketApi = this.websocketService.getSocketApi();

      if (socketApi) {
        socketApi.unsubscribeAny('topic/schedule/update');
      }
    },
    schedule: function (f) {
      const self = this;
      const socketApi = this.websocketService.getSocketApi();

      if (socketApi) {
        // Jeśli korzystamy z socketów to nie chcemy ustawiać timer'a do starego pollingu
        return;
      }

      return Ember.run.later(self, function () {
        if (Ember.getOwner(this).lookup('controller:application').currentPath === self.get('routeName')) {
          f.apply(self);
          self.set('timer', self.schedule(f));
        }
      }, self.get('pollInterval'));
    },
    onPoll: function () {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controller.send('onPoll');
    },
    lastPoll: Ember.computed(function () {
      const interval = this.get('pollInterval');
      return moment().subtract(interval, 'milliseconds');
    }).property(),
    pollInterval: Ember.computed(function () {
      const pollIntervalString = this.get('sessionAccount').getSettingValue('POLL_INTERVAL');
      return pollIntervalString ? parseInt(pollIntervalString) : 10000;
    }).property().readOnly(),
    actions: {
      willTransition(transition) {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        const controller = this.get('controller');
        const transport = controller.get('transportToHandle');
        const handleDedicatedWindow = controller.get('dedicatedTimeWindowToHandle');

        if (!transport && !handleDedicatedWindow) {
          console.debug('Usuwamy modal ze ścieżki schedule..');
          controller.send('hideModal');
        }

        controller.setProperties({
          errors: null,
          successMessage: null,
          warningMessage: null,
          inProgress: false,
          hdMode: false,
          scheduleClicked: false
        });

        if (transition.targetName !== 'warehouses.schedule') {
          if (transport) {
            // Moment, gdy podczas tworzenia awizacji przełączymy na zakładkę
            this.removeUnsavedTimeWindows(transport);
            this.transportCombiner.decombineAllRelatedTransports(transport);
          }

          controller.setProperties({
            transportToSave: null,
            warehouseStepToHandle: null,
            info: null,
            hasZoomBeenSetManually: false
          });
        }

        const socketApi = this.websocketService.getSocketApi();

        if (socketApi) {
          // Przy zmianie magazynu dezaktywujemy nasłuchiwanie na poprzedni magazyn
          socketApi.unsubscribeAny('topic/schedule/update');
        }

        return true;
      },

      refreshRoute: function () {
        this.refresh();
      },

      didTransition() {
        const socketApi = this.websocketService.getSocketApi();

        if (socketApi) {
          // eslint-disable-next-line ember/no-controller-access-in-routes
          this.controller.send('onPoll');
        }
      }

    }
  });

  _exports.default = _default;
});