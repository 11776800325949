define("apollo/pods/workflows/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settingsIndexController: Ember.inject.controller('settings/index'),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    taskGroupIndexes: Ember.A([0, 1, 2, 100]),
    tasks: Ember.computed('model.tasks', function () {
      return this.get('model.tasks');
    }),
    proxiedTaskGroups: Ember.A([]),
    errors: Ember.A([]),
    // eslint-disable-next-line ember/no-observers
    scheduleSetProxiedTaskGroups: Ember.observer('tasks.@each.idxInGroup', function () {
      Ember.run.once(this, 'setProxiedTaskGroups');
    }).on('init'),

    setProxiedTaskGroups() {
      const self = this;

      if (!this.get('tasks')) {
        return;
      }

      this.get('tasks').then(loadedTasks => {
        const proxiedTaskGroups = this.get('taskGroupIndexes').map(groupIdx => {
          const filteredTasks = loadedTasks.filter(task => {
            return task.get('taskGroupIdentifier') === groupIdx && !task.get('masterTask.id');
          });
          let references;

          if (groupIdx === 1) {
            references = ['stepStart', 'stepStop'];
          } else {
            references = ['transportCreated', 'earliestStepStart', 'latestStepStop'];

            if (self.get('model.transportType.requiresAdviceDateRestrictions')) {
              references.push('minAdviceDate');
            }
          }

          return Ember.ObjectProxy.create({
            content: groupIdx,
            tasks: filteredTasks.sortBy('idxInGroup'),
            references
          });
        });
        this.set('proxiedTaskGroups', proxiedTaskGroups);
      });
    },

    destroyOrRollbackTask: function (task) {
      if (!task) {
        return;
      }

      if (task.get('id')) {
        task.rollbackAttributes();
      } else {
        task.destroyRecord();
      }
    },
    destroyOrRollbackSubtasks: function (task) {
      const self = this;

      if (!task) {
        return;
      }

      if (task.get('subTasks.length') > 0) {
        task.get('subTasks').forEach(subTask => {
          self.destroyOrRollbackTask(subTask);
        });
      }
    },
    actions: {
      toggleTracked(task) {
        const tracked = task.get('tracked');
        task.set('tracked', !tracked);
      },

      toggleMarkIfWasDelayed(task) {
        const markIfWasDelayed = task.get('markIfWasDelayed');
        task.set('markIfWasDelayed', !markIfWasDelayed);
      },

      addTask(identifier, idx) {
        const workflow = this.get('model');
        this.get('store').createRecord('task', {
          workflow,
          taskGroupIdentifier: identifier,
          idxInGroup: idx,
          expectedDateInterval: '30m'
        });
      },

      dragStart(object) {
        console.log('Drag Start', object);
      },

      sortEndAction() {
        this.get('proxiedTaskGroups').forEach(tg => {
          tg.get('tasks').forEach((t, idx) => {
            t.set('idxInGroup', idx);
          });
        });
      },

      update() {
        const self = this;

        if (!self.get('model').validate()) {
          return;
        }

        self.get('model').save().then(w => {
          console.log('Workflow ' + w.id + ' saved.');
          self.setProperties({
            success: true,
            errors: []
          });
          this.set('successMessage', self.get('intl').t('workflows.saveSuccessMessage'));
          alert(self.get('intl').t('workflows.saveSuccessMessage'));
          const taskToReload = this.get('store').peekAll('task').filter(task => {
            return task.get('id') === null;
          });
          taskToReload.forEach(task => {
            task.deleteRecord();
          });
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      cancel() {
        const self = this;
        this.get('model').rollbackAttributes();
        this.get('tasks').forEach(task => {
          self.destroyOrRollbackSubtasks(task);
          self.destroyOrRollbackTask(task);
        });
        this.transitionToRoute('settings.index');
      }

    }
  });

  _exports.default = _default;
});