define("apollo/services/route-transport-mapper-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    recordManager: Ember.inject.service('ember-data.record-manager'),
    simpleModelMapper: Ember.inject.service('ember-data.simple-model-mapper'),

    /**
     * Maps a route to a transport, creating necessary step records and custom field values.
     * Transfers data from the route domain model to the transport domain model.
     *
     * @param {DS.Model} route - The source route
     * @param {DS.Model} transport - The target transport
     * @returns {DS.Model} The updated transport
     *
     * @example
     * // Map route data to transport
     * this.routeTransportMapper.mapRouteToTransport(route, transport);
     */
    mapRouteToTransport(route, transport) {
      if (!route || !transport) {
        return transport;
      }

      this.recordManager.clearRelationship(transport, 'steps');
      this.recordManager.clearRelationship(transport, 'customFields');
      this.recordManager.clearRelationship(transport, 'customFieldPairs'); // to nie jest do końca ok ale inaczej nie mogę zmusić tego do przeliczenia wartości

      if (route.routeAddresses && route.routeAddresses.length) {
        const steps = this._mapRouteAddressesToSteps(route.routeAddresses, transport);

        this.recordManager.addToRelationship(transport, 'steps', steps);
      }

      if (route.routeCustomFields && route.routeCustomFields.length) {
        const customFields = this._mapCustomFields(route.routeCustomFields, transport);

        this.recordManager.addToRelationship(transport, 'customFields', customFields);
      }
    },

    /**
     * Maps route addresses to step data objects.
     *
     * @private
     * @param {DS.RecordArray} routeAddresses - The route addresses to map
     * @param {DS.Model} transport - The transport to map the route addresses to
     * @returns {DS.RecordArray} Step model objects
     */
    _mapRouteAddressesToSteps(routeAddresses, transport) {
      const sortedAddressesByIdx = routeAddresses.sortBy('idx');
      return sortedAddressesByIdx.map(routeAddress => {
        const step = this.store.createRecord('step', {
          transport
        });
        this.simpleModelMapper.mapProperties(routeAddress, step, {
          'idx': 'idx',
          'orderInTransport': 'idx',
          'stepTypeIdx': 'stepTypeIdx',
          'address': 'address',
          'company': 'company',
          'warehouse': 'warehouse'
        });
        step.set('stepTypeName', this._mapStepType(routeAddress.stepType));

        if (transport.get('transportType.adviceRestrictionInEveryStep')) {
          step.setAdviceRestrictionDates();
        }

        if (transport.get('transportType.requiresArrivalAndDepartureDate')) {
          step.setArrivalDate();
        }

        return step;
      });
    },

    /**
     * @private
     * @param {String} routeStepType - The step type from route
     * @returns {String} The corresponding transport step type
     */
    _mapStepType(routeStepType) {
      const STEP_TYPES = {
        'load': 'load',
        'unload': 'unload'
      };
      return STEP_TYPES[routeStepType] || routeStepType;
    },

    /**
     * @private
     * @param {DS.RecordArray} routeCustomFields - The custom fields from a route
     * @param {DS.Model} transport - The transport to map the custom fields to
     * @returns {Array} The created custom field value records
     *
     * @example
     * // Map custom fields from route to transport
     * const customFieldValues = this.mapCustomFields(route.customFields, transport);
     */
    _mapCustomFields(routeCustomFields, transport) {
      return routeCustomFields.map(routeCustomField => {
        const customFieldValue = this.store.createRecord('customFieldValue', {
          transport
        });
        this.simpleModelMapper.mapProperties(routeCustomField, customFieldValue, {
          'value': 'value',
          'definition': 'definition',
          'option': 'option'
        });
        return customFieldValue;
      });
    }

  });

  _exports.default = _default;
});