define("apollo/pods/serial-transport/model", ["exports", "ember-data", "apollo/pods/base-recurring-transport/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const attr = _emberData.default.attr;

  var _default = _model.BaseRecurringTransportModel.extend({
    transportId: attr('number'),
    validations: { ..._model.BaseRecurringTransportValidations
    }
  });

  _exports.default = _default;
});