define("apollo/services/ember-data/record-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service for managing Ember Data records in the application.
   * Provides utilities for record lifecycle operations.
   */
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    /**
     * Removes newly created (unsaved) records of a specific model type from the store.
     * This is specifically for records with the isNew flag, which need to be destroyed
     * rather than just unloaded.
     *
     * @param {String} modelName - The name of the model to clean up (e.g., 'step', 'timeWindow')
     * @param {Function} [filterFn=()=>true] - Optional filter function to determine which new records to remove
     * @returns {Number} The number of records that were removed
     *
     * @example
     * // Remove all newly created step records
     * this.recordManager.removeNewRecords('step');
     *
     * @example
     * // Remove newly created steps associated with a specific transport
     * this.recordManager.removeNewRecords('step', step =>
     *   step.transport && step.transport.id === transportId
     * );
     */
    removeNewRecords(modelName, filterFn = () => true) {
      const records = this.store.peekAll(modelName).filter(record => record.isNew).filter(filterFn);
      const count = records.length;
      records.forEach(record => {
        record.deleteRecord();
      });
      return count;
    },

    /**
     * Clears a relationship on a record.
     * Works with both hasMany and belongsTo relationships.
     *
     * @param {DS.Model} record - The record with the relationship
     * @param {String} relationshipName - The name of the relationship to clear
     *
     * @example
     * // Clear the steps relationship on a transport
     * this.recordManager.clearRelationship(transport, 'steps');
     */
    clearRelationship(record, relationshipName) {
      if (record[relationshipName] && typeof record[relationshipName].clear === 'function') {
        record[relationshipName].clear();
      }
    },

    /**
     * Adds records to a relationship.
     * Handles both hasMany and belongsTo relationships.
     *
     * @param {DS.Model} parentRecord - The parent record
     * @param {String} relationshipName - The name of the relationship
     * @param {DS.Model|Array} records - Record or array of records to add
     *
     * @example
     * // Add multiple steps to a transport
     * this.recordManager.addToRelationship(transport, 'steps', stepRecords);
     *
     * @example
     * // Set a single timeWindow on a step
     * this.recordManager.addToRelationship(step, 'timeWindow', timeWindowRecord);
     */
    addToRelationship(parentRecord, relationshipName, records) {
      if (!records) return;

      if (Array.isArray(records)) {
        if (parentRecord[relationshipName] && typeof parentRecord[relationshipName].pushObjects === 'function') {
          parentRecord[relationshipName].pushObjects(records);
        }
      } else {
        if (parentRecord[relationshipName] && typeof parentRecord[relationshipName].pushObject === 'function') {
          parentRecord[relationshipName].pushObject(records);
        }
      }
    }

  });

  _exports.default = _default;
});