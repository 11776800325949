define("apollo/pods/components/transports/transports-table/transport-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8PG3ENu3",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"transports/save-filters-switch\"],false],[0,\"\\n\\n\"],[1,[27,\"transport/custom-fields-filters\",null,[[\"transportType\",\"searchParams\",\"customFieldsVisibleInTable\"],[[23,[\"transportType\"]],[23,[\"searchParams\"]],[23,[\"customFieldsVisibleInTable\"]]]]],false],[0,\"\\n\\n\\n\"],[1,[27,\"transports/transports-table/column-names\",null,[[\"archive\",\"transportType\",\"tableColumnNames\",\"customFieldsVisibleInTable\",\"canCombineTransports\",\"activeSortingKey\",\"changeSortingKey\"],[[23,[\"archive\"]],[23,[\"transportType\"]],[23,[\"tableColumnNames\"]],[23,[\"customFieldsVisibleInTable\"]],[23,[\"canCombineTransports\"]],[23,[\"activeSortingKey\"]],[23,[\"changeSortingKey\"]]]]],false],[0,\"\\n\\n\"],[1,[27,\"transports/transports-table/transport-filters\",null,[[\"archive\",\"filters\",\"transportType\",\"searchParams\",\"queryParams\",\"canCombineTransports\",\"customFieldsVisibleInTable\",\"tableColumnNames\"],[[23,[\"archive\"]],[23,[\"filters\"]],[23,[\"transportType\"]],[23,[\"searchParams\"]],[23,[\"queryParams\"]],[23,[\"canCombineTransports\"]],[23,[\"customFieldsVisibleInTable\"]],[23,[\"tableColumnNames\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/transport-header/template.hbs"
    }
  });

  _exports.default = _default;
});