define("apollo/pods/components/transports/transports-table/transport-row/transaction-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "85KnG2+Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"transactionMethod\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[12,\"class\",[28,[[21,\"transactionIcon\"]]]],[12,\"title\",[28,[[27,\"t\",[[27,\"concat\",[\"transaction.transactionMethod.\",[23,[\"transactionMethod\"]]],null]],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"     \\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/transport-row/transaction-icon/template.hbs"
    }
  });

  _exports.default = _default;
});