define("apollo/pods/components/transports/transports-table/transport-filters/filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    tagName: '',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    searchParams: {},
    // rzeczywista zmienna propagowana po całym systemie
    filterValue: Ember.computed('filterObj.value', {
      get() {
        if (this.get('filterObj') !== undefined) {
          // TODO: Mamy też `transport/custom-field-filters`, który korzysta z tego komponentu,
          //  a nie korzystamy tam z obiektu Filter, więc musimy mieć póki co takie obejście if'em.
          return this.get('filterObj.value');
        }

        return null;
      },

      set(key, value) {
        if (this.get('filterObj') !== undefined) {
          if (this.get('filterObj.type') === 'date' && value) {
            value = moment(value[0]).format('YYYY-MM-DD');
          }

          this.set('filterObj.value', value === '' ? null : value);
        }

        return value == null ? null : value;
      }

    }),
    // zmienna działająca tylko w ramach komponentu
    filterKeys: '',
    selectOptions: Ember.A(),
    selectedOption: Ember.computed('filterObj.value', {
      get() {
        if (this.get('filterValue')) {
          const foundOption = this.get('selectOptions').find(option => option.key === this.get('filterValue'));
          return foundOption ? foundOption.name : this.get('filterValue');
        }

        return '';
      },

      set(key, value) {
        return value;
      }

    }),
    searchField: Ember.computed('', function () {
      return '';
    }),
    findTransports: function () {
      return this.get('store').peekAll('transport').filter(t => t.get('transportType.id') === this.get('transportType.id'));
    },
    _setSelectOptions: function () {
      const self = this;
      const data = JSON.stringify(this.get('queryParams'));
      const path = this.get('apolloApiService').APOLLO_API.TRANSPORTS.FILTER_OPTIONS;
      this.set('selectOptions', this.get('apolloApiService').callApolloApi(path, null, {
        body: data
      }).then(response => response.json()).then(response => {
        const filterSelectOptions = response.filterSelectOptions;
        self.set('selectOptions', Ember.A());

        if (self.get('selectOptionsType') === 'driver') {
          filterSelectOptions.driverNames.forEach(driverName => {
            if (driverName !== undefined) {
              self.get('selectOptions').pushObject(driverName);
            }
          });
        } else if (self.get('selectOptionsType') === 'truck') {
          filterSelectOptions.truckRegistrationNumbers.forEach(truckRegistrationNumber => {
            if (truckRegistrationNumber !== undefined) {
              self.get('selectOptions').pushObject(truckRegistrationNumber);
            }
          });
        } else if (self.get('selectOptionsType') === 'checkpoint') {
          self.set('searchField', 'name');
          filterSelectOptions.checkpointNames.forEach(checkpointName => {
            if (checkpointName !== undefined) {
              const stageNameAndKey = {
                name: checkpointName.name,
                key: checkpointName.key
              };
              self.get('selectOptions').pushObject(stageNameAndKey);
            }
          });
        } else if (self.get('selectOptionsType') === 'personsGroup') {
          self.set('searchField', 'name'); // eslint-disable-next-line array-callback-return

          self.get('store').peekAll('userGroup').uniqBy('userGroupNameAndKey.name').sortBy('userGroupNameAndKey.name').filter(function (item) {
            if (item.get('userGroupNameAndKey') !== undefined) {
              self.get('selectOptions').pushObject(item.get('userGroupNameAndKey'));
            }
          });
        } else if (self.get('selectOptionsType') === 'transportSupervisor') {
          filterSelectOptions.transportSupervisorsNames.forEach(transportSupervisorName => {
            if (transportSupervisorName !== undefined) {
              self.get('selectOptions').pushObject(transportSupervisorName);
            }
          });
        } else if (self.get('selectOptionsType') === 'carrierCompany') {
          filterSelectOptions.carrierCompanies.forEach(carrierCompanyShortName => {
            if (carrierCompanyShortName !== undefined) {
              self.get('selectOptions').pushObject(carrierCompanyShortName);
            }
          });
        } else if (self.get('selectOptionsType') === 'invoice') {
          self.set('searchField', 'name');
          const withInvoice = this.get('intl').t('transaction.withInvoice').toString();
          const withoutInvoice = this.get('intl').t('transaction.withoutInvoice').toString();
          self.get('selectOptions').pushObject({
            name: withInvoice,
            key: 'WITH_INVOICE'
          });
          self.get('selectOptions').pushObject({
            name: withoutInvoice.toString(),
            key: 'WITHOUT_INVOICE'
          });
        } else if (self.get('selectOptionsType') === 'requiresInspection') {
          self.set('searchField', 'name');
          self.get('selectOptions').pushObject(this.get('intl').t('common.yes'));
          self.get('selectOptions').pushObject(this.get('intl').t('common.no'));
        }
      }));
    },
    actions: {
      setSelectOptions: function () {
        Ember.run.debounce(this, this._setSelectOptions, 1000, true);
      },
      setSelectedOptionAndFilterValue: function (selectValue) {
        const self = this;

        if (self.get('selectOptionsType') === 'checkpoint') {
          self.set('filterValue', selectValue ? selectValue.name : null);
        } else if (self.get('selectOptionsType') === 'personsGroup') {
          self.set('filterValue', selectValue.key);
        } else if (self.get('selectOptionsType') === 'requiresInspection') {
          if (selectValue === this.get('intl').t('common.yes')) {
            self.set('filterValue', 'true');
          }

          if (selectValue === this.get('intl').t('common.no')) {
            self.set('filterValue', 'false');
          }
        } else {
          self.set('filterValue', selectValue);
        }

        self.set('selectedOption', selectValue);
      },

      setDate(date) {
        if (!date[0]) {
          this.set('filterValue', null);
        }

        if (date.length === 2) {
          const date1 = moment(date[0]).format('YYYY-MM-DD');
          const date2 = moment(date[1]).format('YYYY-MM-DD');
          const dateFrom = date1 > date2 ? date2 : date1;
          const dateTo = date2 < date1 ? date1 : date2;
          this.set('filterValue', [dateFrom, dateTo]);
        }
      }

    }
  });

  _exports.default = _default;
});